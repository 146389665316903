import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Page } from '../../components/pages/Page/Page';
import { SourceContext } from '../../contexts';
import { useGetUserPreferences } from '../../hooks/useGetUserPreferences';
import { useRequester } from '../../hooks/useRequester';
import { ProviderProps } from '../../interfaces';
import { getSource } from '../../services';

export const SourceProvider: React.FC<ProviderProps> = ({ children }) => {
  const { formatMessage } = useIntl();

  const { get } = useRequester();
  const { selected } = useGetUserPreferences();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [source, setSource] = useState(null);

  const value = useMemo(() => source, [source]);

  const getData = async () => {
    setIsLoading(true);
    const response = await getSource({ api: { get } });

    if (!response?.success) {
      setSource(null);
      setIsLoading(false);

      return;
    }

    setSource(response.data);
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, [selected.country, selected.vendorId]);

  if (isLoading) {
    return <Page.Loading description={formatMessage({ id: 'loading.default' })} />;
  }

  if (!isLoading && !source) {
    return (
      <Page.Error
        type="custom"
        title={'errors.custom.something-went-wrong.title'}
        description={'errors.custom.something-went-wrong.description'}
        button={{ has: true, text: 'errors.custom.buttons.reload', onClick: () => getData() }}
      />
    );
  }

  return <SourceContext.Provider value={value}>{children}</SourceContext.Provider>;
};
