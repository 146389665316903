import { Error403, Error404, Error500, Error503 } from '@hexa-ui/components';
import { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { pathnameRegex } from '../../../../constants';
import { useHeader } from '../../../../hooks/useHeader';
import { useSelected } from '../../../../store/hooks/selectedHook';
import { CustomError } from './Custom/CustomError';
import { ErrorPageProps, ErrorProps } from './ErrorPage.types';
import { ErrorContainer } from './styles';

export const ErrorPage: React.FC<ErrorPageProps> = ({ type, ...props }) => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const { pathname } = useLocation();

  const { setPageHeader } = useHeader();
  const { setLastSelected } = useSelected();

  const custom = useMemo(() => {
    if (type === 'custom') {
      return props as ErrorProps;
    }

    return null;
  }, [type, props]);

  const onReload = () => navigate(0);
  const onBack = () => {
    if (!pathnameRegex.pocDetails.test(pathname)) {
      setLastSelected();
    }

    navigate('/bees-credit-management/credit');
  };

  useEffect(() => {
    setPageHeader({ page: 'empty' });
  }, []);

  return (
    <ErrorContainer data-testid="error-page-container">
      {(() => {
        switch (type) {
          case '403':
            return (
              <Error403
                header={formatMessage({ id: 'errors.403.title' })}
                description={formatMessage({ id: 'errors.403.description' })}
                hasButton={true}
                buttonText={formatMessage({ id: 'errors.403.button' })}
                onButtonClick={onBack}
              />
            );
          case '404':
            return (
              <Error404
                headerText={formatMessage({ id: 'errors.404.title' })}
                subHeaderText={formatMessage({ id: 'errors.404.description' })}
                buttonText={formatMessage({ id: 'errors.404.button' })}
                buttonClickHandler={onBack}
              />
            );
          case '500':
            return <Error500 headerText={formatMessage({ id: 'errors.500.title' })} />;
          case '503':
            return (
              <Error503
                headerText={formatMessage({ id: 'errors.503.title' })}
                subheaderText={formatMessage({ id: 'errors.503.description' })}
                infoText={''}
                buttonText={formatMessage({ id: 'errors.503.button' })}
                buttonClickHandler={onReload}
              />
            );
          case 'custom':
            return (
              <CustomError
                icon={custom.icon}
                title={custom.title}
                description={custom.description}
                button={custom.button}
                onBack={onBack}
                onReload={onReload}
              />
            );
        }
      })()}
    </ErrorContainer>
  );
};
