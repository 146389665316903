import {
  DownloadAssessmentsProps,
  GetAssessmentByIdProps,
  GetAssessmentProps,
  GetSummaryProps,
  ServicesResponse,
  UpdateRecommendationProps,
  UpdateStatusByFilterProps,
  UpdateStatusProps,
  UploadFileProps,
} from '../../interfaces';
import { createFormData } from '../../utils/createFormData';

const service = {
  getSummary: async ({ api, params }: GetSummaryProps): Promise<ServicesResponse | null> => {
    try {
      const { status } = params;
      const { pending, inprogress, active, denied } = status;

      const response = await api.get({
        path: `/v1/assessments/summary?pending=${pending}&inprogress=${inprogress}&active=${active}&denied=${denied}`,
      });

      if (!response?.data) return null;

      return { success: true, data: response.data };
    } catch (error) {
      return { success: false, data: error };
    }
  },

  downloadAssessments: async ({
    api,
    params,
  }: DownloadAssessmentsProps): Promise<ServicesResponse | null> => {
    try {
      const { status, filesConfig } = params;
      const { output } = filesConfig;

      const response = await api.get({
        path: `/v1/assessments/download?status=${status}&output=${output.toLowerCase()}`,
      });

      if (!response?.data) return null;

      return { success: true, data: response.data };
    } catch (error) {
      return { success: false, data: error };
    }
  },

  uploadFile: async ({ api, params }: UploadFileProps): Promise<ServicesResponse | null> => {
    try {
      const formData = createFormData({ params });

      const response = await api.post({
        path: '/v1/assessments/update',
        body: formData,
      });

      if (!response?.data) return { success: false, data: response };

      return { success: true, data: response.data };
    } catch (error) {
      return { success: false, data: error };
    }
  },

  getAssessmentById: async ({
    api,
    params,
  }: GetAssessmentByIdProps): Promise<ServicesResponse | null> => {
    try {
      const { id } = params;

      const response = await api.get({ path: `/v1/assessments/${id}` });

      if (!response?.data) return null;

      return { success: true, data: response.data };
    } catch (error) {
      return { success: false, data: error };
    }
  },

  getAssessments: async ({ api, params }: GetAssessmentProps): Promise<ServicesResponse | null> => {
    const mappedFields = {
      pocName: 'data.customer.name',
      accountId: 'data.customer.beesAccountId',
      creditLimit: 'limit.model',
      term: 'term.days',
      fee: 'term.fee',
      recommendation: 'informationTag',
      score: 'score',
      updatedAt: 'updatedAt',
      updatedBy: 'updatedBy.identifier',
      status: 'status',
    };
    try {
      const { status, search, filters, pagination, recommended, sort } = params;
      const { page, size } = pagination;
      const sortParams = sort
        .map(
          (sortParam) =>
            `sortField=${mappedFields[sortParam.id]}&sortDirection=${
              sortParam.desc ? 'desc' : 'asc'
            }`
        )
        .join('&');

      const queryParams: string[] = [
        ...(!filters.includes('status') ? [`status=${status}`] : []),
        ...(filters ? [filters] : []),
        ...(search ? [`pocOrId=${search}`] : []),
        ...(recommended ? [`recommended=${recommended}`] : []),
        ...(sortParams.length > 0 ? [sortParams] : []),
        `page=${page}`,
        `pageSize=${size}`,
      ];

      const response = await api.get({ path: `/v1/assessments?${queryParams.join('&')}` });

      if (!response?.data) return null;

      return { success: true, data: response.data };
    } catch (error) {
      return { success: false, data: error };
    }
  },

  updateStatus: async ({ api, params }: UpdateStatusProps): Promise<ServicesResponse | null> => {
    try {
      const response = await api.post({ path: '/v1/assessments', body: { data: params } });

      if (!response?.data) return { success: false, data: response };

      return { success: true, data: response.data };
    } catch (error) {
      return { success: false, data: error };
    }
  },

  updateStatusByFilter: async ({
    api,
    params,
  }: UpdateStatusByFilterProps): Promise<ServicesResponse | null> => {
    try {
      const response = await api.post({
        path: '/v1/assessments/update-by-filter',
        body: params,
      });

      if (!response?.data) return { success: false, data: response };

      return { success: true, data: response.data };
    } catch (error) {
      return { success: false, data: error };
    }
  },

  updateRecommendation: async ({
    api,
    params,
  }: UpdateRecommendationProps): Promise<ServicesResponse | null> => {
    try {
      const { assessmentId, attributes } = params;

      const response = await api.patch({
        path: `/v1/assessments/update-recommendation?id=${assessmentId}`,
        body: attributes,
      });

      if (!response?.data) return null;

      return { success: true, data: response.data };
    } catch (error) {
      return { success: false, data: error };
    }
  },
};

export const {
  getSummary,
  downloadAssessments,
  uploadFile,
  getAssessmentById,
  getAssessments,
  updateStatus,
  updateStatusByFilter,
  updateRecommendation,
} = service;
