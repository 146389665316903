import { Checkbox as HexaCheckbox } from '@hexa-ui/components';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { CheckboxAttributes } from './Checkbox.types';

export const CheckboxInput: React.FC<CheckboxAttributes> = ({
  id,
  size,
  name,
  value,
  checked,
  label = '',
  formatted = '',
  disabled = false,
  onChange,
  style,
}) => {
  const { formatMessage } = useIntl();

  const onValueChange = useCallback(
    (value: boolean) => {
      onChange({
        id,
        value,
        formatted: {
          simple: name,
          completed: formatted ? formatMessage({ id: formatted }) : name,
        },
      });
    },
    [id, name, formatted]
  );

  return (
    <HexaCheckbox
      id={id}
      size={size}
      name={name}
      label={label}
      value={value}
      disabled={disabled}
      checked={checked}
      onCheckedChange={onValueChange}
      onClick={(event) => event.stopPropagation()}
      style={style}
    />
  );
};
