import { Actions, Status, Text } from '../../../components/organisms/Tables/custom-columns';
import { ActionsTypes, ColumnProps, Tables } from '../../../interfaces';

export const columns: ColumnProps[] = [
  {
    accessor: 'fileName',
    sizing: { size: 20, minSize: 180 },
    sorting: { has: true },
    render: (value: string) => (
      <Text type="text" params={{ value, sizing: { size: 20 } }} hasEllipsis />
    ),
  },
  {
    accessor: 'fileDescription',
    sizing: { size: 15, minSize: 140 },
    sorting: { has: true },
    render: (value: string) => (
      <Text type="text" params={{ value, sizing: { size: 15 } }} hasEllipsis />
    ),
  },
  {
    accessor: 'total',
    sizing: { size: 13, minSize: 80 },
    sorting: { has: true },
    render: (value: string) => <Text type="number" params={{ value, sizing: { size: 14 } }} />,
  },
  {
    accessor: 'uploadedBy',
    sizing: { size: 14, minSize: 140 },
    sorting: { has: true },
    render: (value: string) => (
      <Text type="text" params={{ value, sizing: { size: 14 } }} hasEllipsis />
    ),
  },
  {
    accessor: 'uploadedAt',
    sizing: { size: 18, minSize: 220 },
    sorting: { has: true },
    render: ({ value, type }) => (
      <Text type="datetime" params={{ value, type, sizing: { size: 18 } }} />
    ),
  },
  {
    accessor: 'status',
    sizing: { size: 15, minSize: 160 },
    sorting: { has: true },
    render: (status: string) => <Status table={Tables.FILES} status={status} />,
  },
  {
    accessor: 'actions',
    sizing: { size: 8, minSize: 60 },
    sorting: { has: false },
    render: ({ fileId, fileStatus, filesConfig }) => (
      <Actions type={ActionsTypes.FILES} data={{ fileId, fileStatus, filesConfig }} />
    ),
  },
];

export const filters = [];
