import { Badge } from '@hexa-ui/components';
import styled from 'styled-components';

const { Status } = Badge;

export const Label = styled(Status)<{ $fontColor: string; $borderColor: string }>`
  border-radius: 512px;
  background-color: transparent;
  height: 24px;
  width: fit-content;
  padding: 4 12 4 12;
  border: ${({ $borderColor }) => `1px solid ${$borderColor}`};

  p {
    color: ${({ $fontColor }) => $fontColor};
    font-family: Work Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    display: flex;
    align-items: center;
    gap: 8px;
    white-space: nowrap;
  }

  p::before,
  p:before {
    content: '';
    display: block;
    background: ${({ $fontColor }) => $fontColor};
    width: 4px;
    height: 4px;
    border-radius: 50%;
  }
`;
