import { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useGetUserPreferences } from '../../../hooks/useGetUserPreferences';
import { Page } from '../Page/Page';
import { Buttons } from './Buttons';
import { UploadProvider } from './Context';
import { Progress } from './Progress';
import { ProgressUpload } from './ProgressUpload/ProgressUpload';
import { UploadPageContainer } from './styles';
import { Uploader } from './Uploader';

export const UploadPage: React.FC = () => {
  const { pathname } = useLocation();
  const { fileId, fileName } = useParams();
  const { permissions } = useGetUserPreferences();

  const isProgress = useMemo(
    () => pathname.includes('progress') && fileId && fileName,
    [pathname, fileId, fileName]
  );

  if (!permissions.has('actions.upload')) {
    return (
      <Page.Error
        type="custom"
        title={'errors.custom.something-went-wrong.title'}
        description={'errors.custom.something-went-wrong.description'}
        button={{ has: true, text: 'errors.custom.buttons.reload' }}
      />
    );
  }

  return (
    <UploadProvider fileId={fileId} fileName={fileName}>
      <UploadPageContainer data-testid="upload-coordinator-container">
        <ProgressUpload />
        {isProgress ? <Progress /> : <Uploader />}
        <Buttons />
      </UploadPageContainer>
    </UploadProvider>
  );
};
