import { IconButton, Tooltip } from '@hexa-ui/components';
import { Invoice } from '@hexa-ui/icons';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { ActionsTypes } from '../../../../../../interfaces';
import { OfferCredit } from '../../../../../molecules/OfferCredit/OfferCredit';
import { ActionsDataProps } from '../Actions.types';

export const PendingNotRecommended: React.FC<
  ActionsDataProps[ActionsTypes.PENDING_NOT_RECOMMENDED]
> = ({ selected, callbackFn }) => {
  const { formatMessage } = useIntl();

  const [open, setOpen] = useState<boolean>(false);

  const onClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();

    setOpen(true);
  };

  return (
    <OfferCredit
      open={{ value: open, setter: setOpen }}
      portal={{ has: true, container: document.body }}
      data={selected}
      callbackFn={callbackFn}
      trigger={
        <IconButton
          id="offer-credit-button"
          variant="tertiary"
          size="small"
          onClick={onClick}
          icon={() => (
            <Tooltip placement="bottom" text={formatMessage({ id: 'offerCredit.tooltip' })}>
              <Invoice size="large" style={{ display: 'flex' }} />
            </Tooltip>
          )}
        />
      }
    />
  );
};
