import { ColumnProps, FilesConfig, FilterProps, Tables, User, UserGroups, UserTypes } from '..';

/**
 * @namespace Default
 * @interface User
 */

export interface UserTypesMapping {
  [key: string]: { type: UserTypes | string; group: UserGroups | string; order: number };
}

/**
 * @namespace Default
 * @interface Permissions
 */

interface PermissionsProps {
  key: string;
  description: string;
}

export interface PermissionsObject {
  [key: string]: PermissionsProps;
}

/**
 * @namespace Default
 * @interface General
 */

export interface GeneralPagination {
  sizeOptions: number[];
}

export interface GeneralCustomColumns {
  [key: string]: string[];
}

export interface GeneralConfig {
  filesConfig: FilesConfig;
  pagination: GeneralPagination;
  customColumns: GeneralCustomColumns;
  [key: string]: any;
}

/**
 * @namespace Users
 * @interface Tables
 */

interface TableProps {
  columns: string[];
  filters: string[];
  status?: string[];
}

export type TableObject = {
  [key in Tables]: TableProps;
};

export interface GetColumnsProps {
  columns: ColumnProps[];
  value: string[];
}

export interface GetFiltersProps {
  filters: FilterProps[];
  value: string[];
}

interface UserTablesProps {
  columns: any[];
  filters: any[];
  status?: string[];
}

export interface MappedUserTables {
  pending: UserTablesProps;
  inprogress: UserTablesProps;
  completed: UserTablesProps;
  files: UserTablesProps;
}

/**
 * @namespace Users
 * @interface Sidebar
 */

export interface SideBarItem {
  icon: React.ForwardRefExoticComponent<any>;
  title: string;
  path: string;
  permission: string;
}

export interface SideBarItems {
  [key: string]: SideBarItem;
}

/**
 * @namespace Configs
 * @interface Configs
 */

interface Currency {
  decimalSeparator: string;
  thousandSeparator: string;
}

export interface MappedConfigs {
  user: User;
  permissions: string[];
  currency: Currency;
  configs: any;
}

export enum CountryConfigType {
  AR = 'ar',
  BO = 'bo',
  BR = 'br',
  CL = 'cl',
  CO = 'co',
  DO = 'do',
  EC = 'ec',
  ES = 'es',
  HN = 'hn',
  MX = 'mx',
  PA = 'pa',
  PE = 'pe',
  PY = 'py',
  SV = 'sv',
  US = 'us',
}
