import { Dialog } from '@hexa-ui/components';
import styled from 'styled-components';

export const Root = styled(Dialog.Root)`
  padding: 16px;
`;

export const Close = styled(Dialog.Close)``;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;

  width: 100%;
  column-gap: 8px;
`;

export const ModalContent = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  width: 100%;
  row-gap: 16px;
`;

export const Overlay = styled.div`
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background: rgba(255, 255, 255, 0.8);
  z-index: 10;
`;

export const TextContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  width: 100%;
  row-gap: 6px;
`;

export const CheckboxContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 14px;

  padding: 8px 0;
`;
