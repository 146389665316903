import { AxiosRequestConfig } from 'axios';
import { FilesConfig, Pagination } from '..';
import { OnUpdateByFilterParams } from '../../components/molecules/ApprovalButtons/ApprovalButtons.types';

type HttpMethod = 'get' | 'post' | 'patch' | 'delete';

interface DefaultParams {
  path: string;
  config?: AxiosRequestConfig;
  body?: any;
}

export type RequestParams<T extends HttpMethod> = T extends 'get' | 'delete'
  ? Omit<DefaultParams, 'body'>
  : DefaultParams;

type RequestApi<T extends HttpMethod> = (params: RequestParams<T>) => Promise<any>;

export type SortState = { id: string; desc: boolean }[];

interface ServiceApi {
  get?: RequestApi<'get'>;
  post?: RequestApi<'post'>;
  patch?: RequestApi<'patch'>;
  remove?: RequestApi<'delete'>;
  downloadFileFromBackend?: RequestApi<'get'>;
}

export interface ServicesProps {
  api: ServiceApi;
  config?: AxiosRequestConfig;
}

export interface ServicesResponse {
  success: boolean;
  data: any;
}

export enum Status {
  APPROVED = 'APPROVED',
  DENIED = 'DENIED',
  PENDING = 'PENDING',
}

/**
 * @namespace CreditAssessment
 */

interface SummaryStatus {
  pending: string[];
  inprogress: string[];
  active: string[];
  denied: string[];
}

interface GetSummaryServiceProps {
  status: SummaryStatus;
}

export interface GetSummaryProps extends ServicesProps {
  params: GetSummaryServiceProps;
}

interface DownloadAssessmentsServiceProps {
  status: string;
  filesConfig: FilesConfig;
}

export interface DownloadAssessmentsProps extends ServicesProps {
  params: DownloadAssessmentsServiceProps;
}

interface UploadFileServiceProps {
  userId: string;
  file: File;
  fileName: string;
  fileDescription: string;
}

export interface UploadFileProps extends ServicesProps {
  params: UploadFileServiceProps;
}
export interface GetAssessmentByIdProps extends ServicesProps {
  params: { id: string };
}

interface GetAssessmentServiceProps {
  status: string;
  search?: string;
  filters?: string;
  pagination?: Pagination;
  recommended?: string;
  sort?: SortState;
}

export interface GetAssessmentProps extends ServicesProps {
  params: GetAssessmentServiceProps;
}

interface UpdateStatusServicesAttributes {
  name: string;
  value: string;
}

export interface UpdateStatusServices {
  bees_account_id: string;
  status: Status;
  user_id: string;
  limit?: number;
  term?: number;
  fee?: number;
  attributes?: UpdateStatusServicesAttributes[];
}

export interface UpdateStatusProps extends ServicesProps {
  params: UpdateStatusServices[];
}
export interface UpdateStatusByFilterProps extends ServicesProps {
  params: OnUpdateByFilterParams;
}

interface UpdateRecommendationServicesAttributes {
  limit: number;
  term: number;
  fee: number;
}

interface UpdateRecommendationServices {
  assessmentId: string;
  attributes: UpdateRecommendationServicesAttributes;
}

export interface UpdateRecommendationProps extends ServicesProps {
  params: UpdateRecommendationServices;
}

/**
 * @namespace CreditAssessmentProcessess
 */

interface DownloadFileServiceProps {
  fileId: string;
  filesConfig: FilesConfig;
}

export interface DownloadFileProps extends ServicesProps {
  params: DownloadFileServiceProps;
}

interface GetProgressServiceProps {
  fileId: string;
}

export interface GetProgressProps extends ServicesProps {
  params: GetProgressServiceProps;
}

interface ConfirmUpdateServiceProps {
  fileId: string;
}

export interface ConfirmUpdateProps extends ServicesProps {
  params: ConfirmUpdateServiceProps;
}

/**
 * @namespace PocDetails
 */

interface GetPocDetailsServiceProps {
  assessmentId: string;
  beesAccountId: string;
  historyStatus: string;
}

export interface GetPocDetailsProps extends ServicesProps {
  params: GetPocDetailsServiceProps;
}

/**
 * @namespace CreditHistory
 */

interface GetCreditHistoryServiceProps {
  beesAccountId: string;
  status: string;
  pagination: { page: number };
}

export interface GetCreditHistoryProps extends ServicesProps {
  params: GetCreditHistoryServiceProps;
}

export type FilterValues = {
  limit?: string | null;
  terms?: string | null;
  fee?: string | null;
  recommendation?: string | null;
  update?: string | null;
  credit_score?: string | null;
};

export type GetFileProps = {
  api: ServiceApi;
  queryParams?: string;
};
