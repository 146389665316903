import React from 'react';
import { ManagementItem } from '../../../interfaces';
import { BlockCreditLineModal } from '../BlockCreditLineModal/BlockCreditLineModal';
import { CancelCreditLineModal } from '../CancelCreditLineModal/CancelCreditLineModal';
import { EditCreditLineModal } from '../EditCreditLineModal/EditCreditLineModal';

interface ActiveCreditLineModalsWrapperProps {
  selected: ManagementItem;
  isOpen: {
    cancelCreditLine: boolean;
    editCreditLine: boolean;
    blockCreditLine: boolean;
  };
  onClose: () => void;
}

export const ActiveCreditLineModalsWrapper: React.FC<ActiveCreditLineModalsWrapperProps> = ({
  selected,
  isOpen,
  onClose,
}) => {
  return (
    <React.Fragment>
      {isOpen.cancelCreditLine && (
        <CancelCreditLineModal mode="single" data={selected} onClose={onClose} />
      )}

      {isOpen.blockCreditLine && (
        <BlockCreditLineModal mode="single" data={selected} onClose={onClose} />
      )}

      {isOpen.editCreditLine && <EditCreditLineModal data={selected} onClose={onClose} />}
    </React.Fragment>
  );
};
