import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  FilterAction,
  PaginationAction,
  SearchAction,
  SelectedAction,
  Tables,
  TablesState,
} from '../../interfaces';

const initialState: TablesState = Object.values(Tables).reduce((acc, table) => {
  acc[table] = {
    pagination: { page: 1, size: 100, total: 0 },
    filters: {},
    search: '',
    selectable: {
      type: '',
      mode: '',
      selected: [],
      exclusions: [],
      rows: [],
    },
  };

  return acc;
}, {} as TablesState);

const tablesSlice = createSlice({
  name: 'tables',
  initialState,
  reducers: {
    setPagination: (state: TablesState, action: PayloadAction<PaginationAction>) => {
      const { table, page, size, total } = action.payload;

      state[table].pagination = { page, size, total };
    },
    setFilters: (state: TablesState, action: PayloadAction<FilterAction>) => {
      const { table, filters } = action.payload;

      state[table].filters = filters;
    },
    setSearch: (state: TablesState, action: PayloadAction<SearchAction>) => {
      const { table, search } = action.payload;

      state[table].search = search;
    },
    setSelectable: (state: TablesState, action: PayloadAction<SelectedAction>) => {
      const { table, selected, exclusions, rows, isExclusions, identifierKey, type, mode } =
        action.payload;

      const selectable = state[table].selectable;

      if (!selectable.type || !type || selectable.type !== type) selectable.type = type;
      if (!selectable.mode || !mode || selectable.mode !== mode) selectable.mode = mode;

      selectable.selected = selected;
      selectable.exclusions = exclusions;

      if ((selectable.type === 'async' && selectable.mode === 'multiple') || !rows.length) {
        selectable.rows = [];
        return;
      }

      if (selectable.mode === 'single' && !selected.length) {
        selectable.type = '';
        selectable.mode = '';
        selectable.rows = [];
        selectable.exclusions = [];
        selectable.selected = [];

        return;
      }

      if (isExclusions) {
        selectable.rows = selectable.rows.filter((row) => !exclusions.includes(row[identifierKey]));
        return;
      }

      selectable.rows = selectable.rows
        .concat(rows)
        .filter(
          (item, index, self) =>
            index === self.findIndex((t) => t[identifierKey] === item[identifierKey])
        );
    },
    resetTableStore: () => initialState,
    moveExclusionsToSelected: (state: TablesState, action: PayloadAction<{ table: string }>) => {
      const { table } = action.payload;
      const selectable = state[table].selectable;

      selectable.selected = selectable.selected.concat(selectable.exclusions);
      selectable.exclusions = [];
    },
  },
});

export const {
  setPagination,
  setFilters,
  setSearch,
  setSelectable,
  resetTableStore,
  moveExclusionsToSelected,
} = tablesSlice.actions;

export default tablesSlice.reducer;
