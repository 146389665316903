import styled from 'styled-components';

export const ExtraContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  width: 100% !important;
`;

export const TopContainer = styled.div<{
  $hassearch: boolean;
  $hasfilter: boolean;
  $hasextra: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ $hassearch, $hasfilter, $hasextra }) => {
    if (($hasfilter || $hassearch) && !$hasextra) return 'flex-start';

    if (($hasfilter || $hassearch) && $hasextra) return 'space-between';

    return 'flex-end';
  }};

  width: 100%;
  padding: 12px 16px;
`;

export const LeftContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  width: auto;
  column-gap: 12px;
`;

export const SelectableContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: row;

  width: auto;
`;
