import { ResponseType } from 'axios';
import download from 'downloadjs';
import {
  ConfirmUpdateProps,
  DownloadFileProps,
  GetFileProps,
  GetProgressProps,
  ServicesResponse,
} from '../../interfaces';
import { downloadFileName } from '../../utils/downloadFileName';

const service = {
  getFiles: async ({ api, queryParams = '' }: GetFileProps): Promise<ServicesResponse | null> => {
    try {
      const response = await api.get({
        path: `/v1/assessment-processes?origin=BU${queryParams}`,
      });

      if (!response?.data) return null;

      return { success: true, data: response.data };
    } catch (error) {
      return { success: false, data: error };
    }
  },

  downloadFile: async ({ api, params }: DownloadFileProps): Promise<ServicesResponse | null> => {
    try {
      const { fileId, filesConfig } = params;
      const { mimetype, responseType } = filesConfig;

      const response = await api.get({
        path: `/v1/assessment-processes/download/${fileId}?type=${responseType}`,
        config: { responseType: responseType as ResponseType },
      });

      if (!response?.data) return null;

      const blob = new Blob([response.data], { type: mimetype });
      download(blob, downloadFileName('POCs_list'));

      return { success: true, data: response.data };
    } catch (error) {
      return { success: false, data: error };
    }
  },

  downloadFailedFile: async ({
    api,
    params,
  }: DownloadFileProps): Promise<ServicesResponse | null> => {
    try {
      const { fileId, filesConfig } = params;
      const { mimetype, responseType } = filesConfig;

      const response = await api.get({
        path: `/v1/assessment-processes/download/failed/${fileId}?type=${responseType}`,
        config: { responseType: responseType as ResponseType },
      });

      if (!response?.data) return null;

      const blob = new Blob([response.data], { type: mimetype });
      download(blob, downloadFileName('FAILED_POCS'));

      return { success: true, data: response.data };
    } catch (error) {
      return { success: false, data: error };
    }
  },

  getProgress: async ({ api, params }: GetProgressProps): Promise<ServicesResponse | null> => {
    try {
      const { fileId } = params;

      const response = await api.get({
        path: `/v1/assessment-processes/upload-progress/${fileId}`,
      });

      if (!response?.data) return null;

      return { success: true, data: response.data };
    } catch (error) {
      return { success: false, data: error };
    }
  },

  applyUpdate: async ({ api, params }: ConfirmUpdateProps): Promise<ServicesResponse | null> => {
    try {
      const { fileId } = params;

      const response = await api.post({
        path: `/v1/assessment-processes/apply-update/${fileId}`,
      });

      if (!response?.data) return null;

      return { success: true, data: response.data };
    } catch (error) {
      return { success: false, data: error };
    }
  },

  downloadFileFromBackend: async ({
    api,
    params,
  }: DownloadFileProps): Promise<ServicesResponse | null> => {
    try {
      const { fileId, filesConfig } = params;
      const { mimetype, responseType } = filesConfig;

      const response = await api.downloadFileFromBackend({
        path: '',
        config: {
          params: { fileId },
          responseType: responseType as ResponseType,
        },
      });

      if (!response?.data) return null;

      const blob = new Blob([response.data], { type: mimetype });
      download(blob, downloadFileName('POCs_list'));

      return { success: true, data: response.data };
    } catch (error) {
      return { success: false, data: error };
    }
  },
};

export const {
  getFiles,
  downloadFile,
  downloadFailedFile,
  getProgress,
  applyUpdate,
  downloadFileFromBackend,
} = service;
