import React from 'react';

export const Analyzing = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(
  (props, ref) => (
    <svg
      ref={ref}
      {...props}
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="analyzing-icon"
    >
      <g clipPath="url(#clip0_6187_133725)">
        <path
          d="M28 52.5C41.531 52.5 52.5 41.531 52.5 28C52.5 14.469 41.531 3.5 28 3.5C14.469 3.5 3.5 14.469 3.5 28C3.5 41.531 14.469 52.5 28 52.5Z"
          fill="url(#paint0_linear_6187_133725)"
        />
        <g clipPath="url(#clip1_6187_133725)">
          <path
            opacity="0.16"
            d="M42.7175 51.3975L19.5387 47.5125C14.3238 46.6375 10.5 42.1225 10.5 36.8288V15.2425L10.5262 7.8225C10.5262 6.88625 11.2875 6.125 12.2325 6.125H42.9975C43.9425 6.125 44.7038 6.88625 44.7038 7.83125V49.7175C44.7038 50.7675 43.7588 51.5725 42.7175 51.3975Z"
            fill="black"
          />
          <path
            d="M42.7175 49.6475L19.5387 45.7625C14.3238 44.8875 10.5 40.3725 10.5 35.0788V13.4925L10.5262 6.0725C10.5262 5.13625 11.2875 4.375 12.2325 4.375H42.9975C43.9425 4.375 44.7038 5.13625 44.7038 6.08125V47.9675C44.7038 49.0175 43.7588 49.8225 42.7175 49.6475Z"
            fill="url(#paint1_linear_6187_133725)"
          />
          <path
            d="M15.0938 12.2062C15.0938 11.8912 15.3475 11.6375 15.6625 11.6375H39.6025C39.9175 11.6375 40.1713 11.8912 40.1713 12.2062C40.1713 12.5212 39.9175 12.775 39.6025 12.775H15.6625C15.3475 12.775 15.0938 12.5212 15.0938 12.2062ZM15.6625 17.08H39.6025C39.9175 17.08 40.1713 16.8262 40.1713 16.5112C40.1713 16.1962 39.9175 15.9425 39.6025 15.9425H15.6625C15.3475 15.9425 15.0938 16.1962 15.0938 16.5112C15.0938 16.8262 15.3475 17.08 15.6625 17.08ZM15.6625 21.385H39.6025C39.9175 21.385 40.1713 21.1312 40.1713 20.8162C40.1713 20.5012 39.9175 20.2474 39.6025 20.2474H15.6625C15.3475 20.2474 15.0938 20.5012 15.0938 20.8162C15.0938 21.1312 15.3475 21.385 15.6625 21.385ZM15.6625 25.69H39.6025C39.9175 25.69 40.1713 25.4362 40.1713 25.1212C40.1713 24.8062 39.9175 24.5524 39.6025 24.5524H15.6625C15.3475 24.5524 15.0938 24.8062 15.0938 25.1212C15.0938 25.4362 15.3475 25.69 15.6625 25.69ZM15.6625 29.9949H32.6025C32.9175 29.9949 33.1713 29.7412 33.1713 29.4262C33.1713 29.1112 32.9175 28.8575 32.6025 28.8575H15.6625C15.3475 28.8575 15.0938 29.1112 15.0938 29.4262C15.0938 29.7412 15.3475 29.9949 15.6625 29.9949Z"
            fill="#4D4D4D"
          />
          <path
            opacity="0.24"
            d="M43.4436 49.0962L25.6548 37.6687C24.7448 37.1175 23.5461 37.6337 23.3711 38.675C22.9598 41.0725 21.0698 44.9837 17.0273 44.9837L17.9723 45.3162C18.5761 45.5262 19.2061 45.6837 19.8448 45.7712L43.2511 49.6125C43.5573 49.665 43.7061 49.2537 43.4436 49.0962Z"
            fill="black"
          />
          <path
            d="M43.4436 49.0962L26.0486 38.5525C25.1299 38.0012 23.9399 38.5262 23.7561 39.585C23.3361 41.9825 22.2599 45.4387 18.2349 45.4387L19.6349 45.78C19.9149 45.85 20.1949 45.9025 20.4836 45.955L43.2599 49.63C43.5661 49.6825 43.7149 49.2712 43.4524 49.1137L43.4436 49.0962Z"
            fill="url(#paint2_linear_6187_133725)"
          />
        </g>
        <path
          opacity="0.16"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.25 46.375C19.25 51.208 15.335 55.125 10.5044 55.125C5.67377 55.125 1.75 51.2167 1.75 46.375C1.75 41.5333 5.66505 37.625 10.4956 37.625C15.3262 37.625 19.2413 41.542 19.2413 46.375H19.25Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.25 45.5C19.25 50.333 15.335 54.25 10.5044 54.25C5.67377 54.25 1.75 50.3417 1.75 45.5C1.75 40.6583 5.66505 36.75 10.4956 36.75C15.3262 36.75 19.2413 40.667 19.2413 45.5H19.25Z"
          fill="#3D83EC"
        />
        <path
          d="M7.05238 41.9213V42.7438H7.06988C7.06988 43.155 6.73738 43.4963 6.31738 43.4963C5.90613 43.4963 5.56488 43.1638 5.56488 42.7438V41.7113C5.56488 41.0025 6.14238 40.425 6.85113 40.425H13.0549C13.7636 40.425 14.3411 41.0025 14.3411 41.7113V42.7438C14.3411 43.155 14.0086 43.4963 13.5886 43.4963C13.1774 43.4963 12.8361 43.1638 12.8361 42.7438V41.9213H7.05238Z"
          fill="black"
          fillOpacity="0.16"
        />
        <path
          d="M5.54735 49.5862V48.5537H5.52985C5.52985 48.1425 5.86235 47.8012 6.28235 47.8012C6.6936 47.8012 7.03485 48.1337 7.03485 48.5537V49.3762H12.8186V48.5537C12.8186 48.1425 13.1511 47.8012 13.5711 47.8012C13.9823 47.8012 14.3236 48.1337 14.3236 48.5537V49.5862C14.3236 50.295 13.7461 50.8725 13.0373 50.8725H6.8336C6.12485 50.8725 5.54735 50.295 5.54735 49.5862Z"
          fill="black"
          fillOpacity="0.16"
        />
        <path
          d="M14.735 46.3926H5.1275C4.71625 46.3926 4.375 46.0601 4.375 45.6401C4.375 45.2289 4.7075 44.8876 5.1275 44.8876H14.735C15.1463 44.8876 15.4875 45.2201 15.4875 45.6401C15.4875 46.0514 15.155 46.3926 14.735 46.3926Z"
          fill="black"
          fillOpacity="0.16"
        />
        <path
          d="M7.69984 41.7462V42.5687H7.71734C7.71734 42.98 7.38484 43.3212 6.96484 43.3212C6.55359 43.3212 6.21234 42.9887 6.21234 42.5687V41.5362C6.21234 40.8275 6.78984 40.25 7.49859 40.25H13.7023C14.4111 40.25 14.9886 40.8275 14.9886 41.5362V42.5687C14.9886 42.98 14.6561 43.3212 14.2361 43.3212C13.8248 43.3212 13.4836 42.9887 13.4836 42.5687V41.7462H7.69984Z"
          fill="white"
        />
        <path
          d="M6.19481 49.4112V48.3787H6.17731C6.17731 47.9674 6.50981 47.6262 6.92981 47.6262C7.34106 47.6262 7.68231 47.9587 7.68231 48.3787V49.2012H13.4661V48.3787C13.4661 47.9674 13.7986 47.6262 14.2186 47.6262C14.6298 47.6262 14.9711 47.9587 14.9711 48.3787V49.4112C14.9711 50.1199 14.3936 50.6974 13.6848 50.6974H7.48106C6.77231 50.6974 6.19481 50.1199 6.19481 49.4112Z"
          fill="white"
        />
        <path
          d="M15.3825 46.2176H5.77496C5.36371 46.2176 5.02246 45.8851 5.02246 45.4651C5.02246 45.0538 5.35496 44.7126 5.77496 44.7126H15.3825C15.7937 44.7126 16.135 45.0451 16.135 45.4651C16.135 45.8763 15.8025 46.2176 15.3825 46.2176Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_6187_133725"
          x1="3.5"
          y1="28"
          x2="52.5"
          y2="28"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFE0E4" />
          <stop offset="1" stopColor="#EEEFF1" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_6187_133725"
          x1="10.5"
          y1="27.02"
          x2="44.7038"
          y2="27.02"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D3E1F6" />
          <stop offset="1" stopColor="#EFF4FC" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_6187_133725"
          x1="19.4686"
          y1="41.09"
          x2="43.2686"
          y2="49.0175"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D4E1FF" />
          <stop offset="1" stopColor="#4F93DB" />
        </linearGradient>
        <clipPath id="clip0_6187_133725">
          <rect width="56" height="56" fill="white" />
        </clipPath>
        <clipPath id="clip1_6187_133725">
          <rect width="34.2038" height="47.04" fill="white" transform="translate(10.5 4.375)" />
        </clipPath>
      </defs>
    </svg>
  )
);
