import { IconButton } from '@hexa-ui/components';
import { Download } from '@hexa-ui/icons';
import React, { useCallback, useMemo, useState } from 'react';
import { actions as ActionsMapping } from '../../../../../../constants';
import { useGetUserPreferences } from '../../../../../../hooks/useGetUserPreferences';
import { useRequester } from '../../../../../../hooks/useRequester';
import { useToast } from '../../../../../../hooks/useToast';
import { ActionsTypes, Tables } from '../../../../../../interfaces';
import { downloadFileFromBackend as serviceDownloadFileFromBackend } from '../../../../../../services';
import SegmentService from '../../../../../../services/segment';
import { ActionsDataProps } from '../Actions.types';

export const Files: React.FC<ActionsDataProps[ActionsTypes.FILES]> = ({
  fileId,
  fileStatus,
  filesConfig,
}) => {
  const { downloadFileFromBackend } = useRequester();
  const { toast } = useToast();

  const { selected } = useGetUserPreferences();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isDisabled: boolean = useMemo(() => {
    const { disabled } = Object.entries(ActionsMapping[Tables.FILES].progress).reduce(
      (acc, [_, value]) => {
        if (value.includes(fileStatus)) {
          return { disabled: true };
        }

        return acc;
      },
      { disabled: false }
    );

    return disabled;
  }, [fileStatus]);

  const onClick = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setIsLoading(true);

      SegmentService.paymentsButtonClicked(
        'Download Icon',
        'Download Uploaded File Button',
        selected.vendorId,
        'Credit Management Files Download from Table',
        'CREDIT_MANAGEMENT_FILE_PAGE'
      );

      const response = await serviceDownloadFileFromBackend({
        api: { downloadFileFromBackend },
        params: { fileId, filesConfig },
      });

      if (!response?.success) {
        toast.error({ message: 'errors.500.title' });
        setIsLoading(false);
        return;
      }

      setIsLoading(false);
    },
    [fileId]
  );

  return (
    <IconButton
      id="download-button"
      variant="tertiary"
      icon={Download}
      loading={isLoading}
      disabled={isDisabled}
      onClick={onClick}
      style={{ backgroundColor: '#14141429' }}
    />
  );
};
