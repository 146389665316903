import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { excludeAssessments } from '../../../constants';
import { useGetUserPreferences } from '../../../hooks/useGetUserPreferences';
import { ActiveTabs, Tables } from '../../../interfaces';
import SegmentService from '../../../services/segment';
import { AssessmentsTable } from '../../organisms/Tables';
import { Page } from '../Page/Page';
import { CreditProvider } from './Context/CreditContext';
import { Summary } from './Summary/Summary';
import { Content, CreditPageContainer, List, Root, Trigger } from './styles';

export const CreditPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const { permissions, selected } = useGetUserPreferences();

  const savedTab = sessionStorage.getItem(ActiveTabs.ACTIVE_TAB_KEY) as Tables | null;
  const defaultTab = savedTab ?? Tables.PENDING;
  const [tab, setTab] = useState<Tables>(defaultTab);

  const tablesMapping = useMemo(
    () =>
      Object.values(Tables).filter(
        (table) => !excludeAssessments.includes(table) && permissions.has(`tables.${table}`)
      ),
    [Tables, permissions]
  );

  useEffect(() => {
    sessionStorage.setItem(ActiveTabs.ACTIVE_TAB_KEY, tab);
  }, [tab]);

  useEffect(() => {
    SegmentService.paymentsPageViewed(
      selected?.vendorId,
      'Credit Management Enter Screen',
      'CREDIT_MANAGEMENT_ENTER_SCREEN'
    );
  }, []);

  if (!permissions.has('pages.credit')) {
    return (
      <Page.Error
        type="custom"
        title={'errors.custom.something-went-wrong.title'}
        description={'errors.custom.something-went-wrong.description'}
        button={{ has: true, text: 'errors.custom.buttons.reload' }}
      />
    );
  }

  return (
    <CreditProvider>
      <CreditPageContainer data-testid="credit-page">
        <Summary />

        <Root defaultValue={tab} value={tab}>
          <List>
            {tablesMapping.map((table) => (
              <Trigger key={`tabs-trigger-${table}`} value={table} onClick={() => setTab(table)}>
                {formatMessage({ id: `tables.${table}.tab.title` })}
              </Trigger>
            ))}
          </List>

          {tablesMapping.map((table) => (
            <Content key={`tabs-content-${table}`} value={table}>
              <AssessmentsTable type={table} />
            </Content>
          ))}
        </Root>
      </CreditPageContainer>
    </CreditProvider>
  );
};
