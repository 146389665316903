import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { actions as ActionsMapping } from '../../../../constants';
import { useGetTables } from '../../../../hooks/useGetTables';
import { useGetUserPreferences } from '../../../../hooks/useGetUserPreferences';
import { useRequester } from '../../../../hooks/useRequester';
import { GenericResponse, Tables, UploadStatus } from '../../../../interfaces';
import { getFiles } from '../../../../services';
import SegmentService from '../../../../services/segment';
import { EmptyMessageProps } from '../../../atoms/Table/Empty/EmptyMessage.types';
import { Table } from '../../../atoms/Table/Table';
import { EmptyMessage } from '../../../icons/Table/Empty/Empty.svg';
import { GenericMessage } from '../../../icons/Table/Generic/Generic.svg';
import { SearchMessage } from '../../../icons/Table/Search/Search.svg';
import { FileButtons } from '../../../molecules/FileButtons/FileButtons';

export const FilesTable: React.FC = () => {
  const { formatMessage } = useIntl();
  const { get } = useRequester();

  const navigate = useNavigate();

  const { columns } = useGetTables(Tables.FILES);
  const { configs, selected } = useGetUserPreferences();
  const { filesConfig } = configs;

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [files, setFiles] = useState({ value: [], filtered: [] });
  const [search, setSearch] = useState<string>('');
  const [empty, setEmpty] = useState<EmptyMessageProps>({
    message: formatMessage({ id: 'tables.files.empty.default' }),
    Icon: EmptyMessage,
  });

  const onSearch = async (value: string) => {
    setSearch(value);

    setFiles((prev) => ({
      ...prev,
      filtered: prev.value.filter((item) =>
        item.fileName.toLowerCase().includes(value.toLowerCase())
      ),
    }));

    if (!files.filtered.length) {
      setEmpty({
        message: formatMessage({ id: 'tables.files.empty.search' }),
        Icon: SearchMessage,
      });
    }
  };

  const onRow = (row: GenericResponse) => {
    if (!row?.status || ActionsMapping[Tables.FILES].finished.includes(row?.status)) return null;

    navigate(`/bees-credit-management/upload/progress/${row.fileId}/${row.fileName}`);
  };

  const getData = async () => {
    setIsLoading(true);

    const response = await getFiles({ api: { get } });

    if (!response?.success) {
      setFiles({ value: [], filtered: [] });
      setEmpty({
        message: formatMessage({ id: 'tables.files.empty.error' }),
        Icon: GenericMessage,
      });

      setIsLoading(false);
      return;
    }

    const fileHistory = response.data?.map((item: GenericResponse) => ({
      status: item.status,
      fileId: item.id,
      fileName: item.reference_name,
      fileDescription: item.description,
      total: String(item.total_count),
      processed: String(item.processed_count),
      uploadedBy: item.uploaded_by,
      uploadedAt: { value: item.updated_at, type: 'long-time' },
      actions: { fileId: item.id, fileStatus: item.status, filesConfig },
    }));

    const filteredFileHistory = fileHistory.filter(
      (item: { status: UploadStatus }) =>
        item.status !== UploadStatus.PENDING && item.status !== UploadStatus.FAILED
    );

    setFiles({ value: filteredFileHistory, filtered: filteredFileHistory });
    setIsLoading(false);
  };

  useEffect(() => {
    getData();

    SegmentService.paymentsPageViewed(
      selected?.vendorId,
      'Credit Management File Access',
      'CREDIT_MANAGEMENT_FILE_PAGE'
    );
  }, []);

  return (
    <Table
      table={Tables.FILES}
      loading={isLoading}
      columns={columns}
      data={files.filtered}
      toolbarExtra={FileButtons}
      onRow={onRow}
      search={{
        has: true,
        width: 320,
        placeholder: formatMessage({ id: 'tables.files.search' }),
        value: search,
        onChange: onSearch,
      }}
      messages={{ loading: formatMessage({ id: 'tables.loading' }), empty }}
    />
  );
};
