import { Filter as FilterIcon } from '@hexa-ui/icons';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useTableStore } from '../../../../../store/hooks/tablesHook';
import { ClearAllButton } from '../../../ClearAllButton/ClearAllButton';
import { CollapseButton } from '../../../CollapseButton/CollapseButton';
import { useFilter } from '../Context/FilterContext';
import { FilterCard } from './Card/FilterCard';
import { RootProps } from './FilterRoot.types';
import { RootContainer } from './styles';

export const FilterRoot: React.FC<RootProps> = ({
  filters,
  onFilterFn,
  onClearFn,
  selectableCounter,
}) => {
  const { formatMessage } = useIntl();

  const { table, open, counter, filtered, onApply } = useFilter();
  const { filters: storedFilters } = useTableStore(table);

  useEffect(() => {
    if (Object.keys(storedFilters.value ?? {}).length > 0) {
      for (const key in storedFilters.value) {
        if (storedFilters.value.hasOwnProperty(key)) {
          const { value, formatted } = storedFilters.value[key];

          filtered.setter(key, { value, formatted });
        }
      }

      onApply({ state: storedFilters.value, onFilterFn, onClearFn });
    }
  }, []);

  if (selectableCounter) return <></>;

  return (
    <RootContainer data-testid="filter-root-container">
      <CollapseButton
        open={open}
        disabled={!filters.length}
        label={formatMessage({ id: 'tables.filters.buttons.filter' }, { value: counter })}
        icon={FilterIcon}
      >
        <FilterCard filters={filters} onFilterFn={onFilterFn} onClearFn={onClearFn} />
      </CollapseButton>

      {counter > 0 && <ClearAllButton />}
    </RootContainer>
  );
};
