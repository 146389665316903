import React from 'react';

export const SearchMessage = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(
  (props, ref) => (
    <svg
      ref={ref}
      {...props}
      data-testid="search-message-icon"
      width="128"
      height="128"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.3">
        <mask
          id="mask0_6342_11137"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="66"
          y="20"
          width="57"
          height="57"
        >
          <path
            d="M94.1938 76.544C109.562 76.544 122.02 64.086 122.02 48.7182C122.02 33.3505 109.562 20.8924 94.1938 20.8924C78.8261 20.8924 66.368 33.3505 66.368 48.7182C66.368 64.086 78.8261 76.544 94.1938 76.544Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_6342_11137)">
          <path
            d="M94.1938 76.544C109.562 76.544 122.02 64.086 122.02 48.7182C122.02 33.3505 109.562 20.8924 94.1938 20.8924C78.8261 20.8924 66.368 33.3505 66.368 48.7182C66.368 64.086 78.8261 76.544 94.1938 76.544Z"
            fill="url(#paint0_linear_6342_11137)"
          />
        </g>
      </g>
      <g opacity="0.3">
        <mask
          id="mask1_6342_11137"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="59"
          width="48"
          height="48"
        >
          <path
            d="M23.616 106.944C36.6588 106.944 47.232 96.3708 47.232 83.328C47.232 70.2852 36.6588 59.712 23.616 59.712C10.5732 59.712 0 70.2852 0 83.328C0 96.3708 10.5732 106.944 23.616 106.944Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask1_6342_11137)">
          <path
            d="M23.616 106.944C36.6588 106.944 47.232 96.3708 47.232 83.328C47.232 70.2852 36.6588 59.712 23.616 59.712C10.5732 59.712 0 70.2852 0 83.328C0 96.3708 10.5732 106.944 23.616 106.944Z"
            fill="url(#paint1_linear_6342_11137)"
          />
        </g>
      </g>
      <mask
        id="mask2_6342_11137"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="1"
        y="3"
        width="124"
        height="104"
      >
        <path
          d="M103.104 65.6782C103.104 88.1493 84.8853 106.368 62.4142 106.368C39.9431 106.368 21.7244 88.1493 21.7244 65.6782L1.70667 65.9627L9.74222 3.55556H118.037L124.373 65.9698L103.104 65.6782Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask2_6342_11137)">
        <mask
          id="mask3_6342_11137"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="21"
          y="24"
          width="83"
          height="83"
        >
          <path
            d="M62.4284 106.332C84.9008 106.332 103.118 88.115 103.118 65.6427C103.118 43.1703 84.9008 24.9529 62.4284 24.9529C39.9561 24.9529 21.7386 43.1703 21.7386 65.6427C21.7386 88.115 39.9561 106.332 62.4284 106.332Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask3_6342_11137)">
          <path
            d="M62.4284 106.332C84.9008 106.332 103.118 88.115 103.118 65.6427C103.118 43.1703 84.9008 24.9529 62.4284 24.9529C39.9561 24.9529 21.7386 43.1703 21.7386 65.6427C21.7386 88.115 39.9561 106.332 62.4284 106.332Z"
            fill="url(#paint2_linear_6342_11137)"
          />
        </g>
      </g>
      <mask
        id="mask4_6342_11137"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="1"
        y="3"
        width="124"
        height="104"
      >
        <path
          d="M103.104 65.6782C103.104 88.1493 84.8853 106.368 62.4142 106.368C39.9431 106.368 21.7244 88.1493 21.7244 65.6782L1.70667 65.9627L9.74222 3.55556H118.037L124.373 65.9698L103.104 65.6782Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask4_6342_11137)">
        <path
          d="M85.3049 48.2418L95.4383 40.4978C96.0285 40.0427 96.8747 40.1351 97.3583 40.704L106.688 51.6195C107.221 52.2453 107.108 53.1911 106.439 53.6675L95.168 61.7387L85.3049 48.2418Z"
          fill="#E5D770"
        />
        <path
          d="M40.9316 48.2418L30.7983 40.4978C30.208 40.0427 29.3618 40.1351 28.8783 40.704L19.5485 51.6195C19.0151 52.2453 19.1289 53.1911 19.7974 53.6675L31.0756 61.7387L40.9316 48.2418Z"
          fill="#E5D770"
        />
        <path
          d="M95.0756 61.7387H31.0756L40.9316 48.2418H85.2196L95.0756 61.7387Z"
          fill="#8C7C1B"
        />
        <path
          d="M95.0827 61.7387H31.0756V125.746H95.0827V61.7387Z"
          fill="url(#paint3_linear_6342_11137)"
        />
        <path
          d="M31.161 61.7387H95.1183L98.5814 73.9911C99.1219 75.8898 97.6641 77.7742 95.6588 77.7742H30.6205C28.6152 77.7742 27.1574 75.8969 27.6979 73.9911L31.161 61.7387Z"
          fill="#E5D770"
        />
        <path opacity="0.16" d="M95.0685 77.7671H31.1112V81.1307H95.0685V77.7671Z" fill="black" />
      </g>
      <g opacity="0.5">
        <mask
          id="mask5_6342_11137"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="12"
          y="35"
          width="15"
          height="15"
        >
          <path
            d="M19.2498 49.3653C23.1222 49.3653 26.2614 46.2262 26.2614 42.3538C26.2614 38.4814 23.1222 35.3422 19.2498 35.3422C15.3775 35.3422 12.2383 38.4814 12.2383 42.3538C12.2383 46.2262 15.3775 49.3653 19.2498 49.3653Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask5_6342_11137)">
          <path
            d="M19.2498 49.3653C23.1222 49.3653 26.2614 46.2262 26.2614 42.3538C26.2614 38.4814 23.1222 35.3422 19.2498 35.3422C15.3775 35.3422 12.2383 38.4814 12.2383 42.3538C12.2383 46.2262 15.3775 49.3653 19.2498 49.3653Z"
            fill="url(#paint4_linear_6342_11137)"
          />
        </g>
      </g>
      <g opacity="0.5">
        <mask
          id="mask6_6342_11137"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="100"
          y="94"
          width="15"
          height="15"
        >
          <path
            d="M107.207 108.601C111.079 108.601 114.219 105.462 114.219 101.589C114.219 97.717 111.079 94.5778 107.207 94.5778C103.335 94.5778 100.196 97.717 100.196 101.589C100.196 105.462 103.335 108.601 107.207 108.601Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask6_6342_11137)">
          <path
            d="M107.207 108.601C111.079 108.601 114.219 105.462 114.219 101.589C114.219 97.717 111.079 94.5778 107.207 94.5778C103.335 94.5778 100.196 97.717 100.196 101.589C100.196 105.462 103.335 108.601 107.207 108.601Z"
            fill="url(#paint5_linear_6342_11137)"
          />
        </g>
      </g>
      <path
        d="M89.2374 69.8667L114.525 94.2222C116.075 95.5662 118.059 96.2347 120.121 96.0925C122.176 95.9502 124.053 95.0187 125.405 93.4685C126.749 91.9182 127.417 89.9342 127.275 87.872C127.133 85.8169 126.201 83.9396 124.651 82.5884L96.2703 61.4258L89.2374 69.8667Z"
        fill="url(#paint6_linear_6342_11137)"
      />
      <path
        opacity="0.16"
        d="M95.8081 76.0462L103.481 66.8089L107.179 69.5751C107.093 69.7742 107.044 70.0089 106.901 70.1796L99.2214 79.0116C99.1503 79.0969 99.065 79.1751 98.9868 79.2462L95.8081 76.0462Z"
        fill="black"
      />
      <path
        d="M106.055 67.7831L96.7539 78.4782C96.3841 78.9049 95.7299 78.9547 95.3032 78.5778L93.4472 76.9636C93.0205 76.5938 92.9708 75.9396 93.3477 75.5129L102.649 64.8178C103.019 64.3911 103.673 64.3413 104.1 64.7182L105.956 66.3325C106.382 66.7022 106.425 67.3565 106.055 67.7831Z"
        fill="url(#paint7_linear_6342_11137)"
      />
      <path
        opacity="0.16"
        d="M92.8214 73.3084C94.3432 72.1636 95.7654 70.8409 97.0667 69.3476C98.3823 67.8329 99.5058 66.2187 100.437 64.5262L96.2703 61.4187L89.2445 69.8596L92.8214 73.3084Z"
        fill="black"
      />
      <path
        opacity="0.4"
        d="M55.6872 32.8462C47.0401 42.7875 48.0996 57.92 58.0552 66.5813C68.0107 75.2427 83.1432 74.1902 91.7903 64.2489C100.437 54.3076 99.3779 39.1751 89.4223 30.5138C79.4667 21.8524 64.3343 22.9049 55.6872 32.8462Z"
        fill="url(#paint8_linear_6342_11137)"
      />
      <path
        opacity="0.16"
        d="M65.0169 70.3644C64.4622 69.9591 63.9147 69.5253 63.3813 69.0702C53.6462 60.608 52.6578 45.76 61.1627 35.9751C67.8613 28.2738 78.4782 25.9698 87.4453 29.4613C77.7458 22.4356 64.0427 23.9004 55.9929 33.152C47.4809 42.9369 48.4764 57.7849 58.2116 66.2471C60.288 68.0462 62.592 69.4187 65.0169 70.3644Z"
        fill="black"
      />
      <path
        d="M73.7565 25.0596C79.4241 25.0596 84.8996 27.1076 89.1663 30.8196C93.8952 34.9298 96.7396 40.6542 97.1805 46.9262C97.6214 53.1982 95.5947 59.2569 91.4774 63.9858C87.0187 69.1129 80.5476 72.0569 73.7209 72.0569C68.0534 72.0569 62.5778 70.0089 58.3112 66.2969C48.5263 57.792 47.4952 42.9084 56.0001 33.1307C60.4587 28.0036 66.9298 25.0596 73.7565 25.0596ZM73.7565 20.7929C65.9912 20.7929 58.2614 24.0213 52.7787 30.3289C42.7307 41.8844 43.9538 59.4631 55.5094 69.5182C60.7574 74.0836 67.2498 76.3236 73.7209 76.3236C81.4863 76.3236 89.2161 73.0951 94.6987 66.7876C104.747 55.232 103.524 37.6533 91.9681 27.5982C86.7129 23.0329 80.2205 20.7929 73.7565 20.7929Z"
        fill="url(#paint9_linear_6342_11137)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_6342_11137"
          x1="66.3642"
          y1="48.7203"
          x2="122.017"
          y2="48.7203"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFE0E4" />
          <stop offset="1" stopColor="#EEEFF1" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_6342_11137"
          x1="0"
          y1="83.3303"
          x2="47.2363"
          y2="83.3303"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFE0E4" />
          <stop offset="1" stopColor="#EEEFF1" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_6342_11137"
          x1="21.7422"
          y1="65.6435"
          x2="103.116"
          y2="65.6435"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFE0E4" />
          <stop offset="1" stopColor="#EEEFF1" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_6342_11137"
          x1="63.0748"
          y1="125.741"
          x2="63.0748"
          y2="61.7355"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D8AF32" />
          <stop offset="1" stopColor="#D8BC32" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_6342_11137"
          x1="12.2357"
          y1="42.3506"
          x2="26.2654"
          y2="42.3506"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFE0E4" />
          <stop offset="1" stopColor="#EEEFF1" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_6342_11137"
          x1="100.19"
          y1="101.589"
          x2="114.22"
          y2="101.589"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFE0E4" />
          <stop offset="1" stopColor="#EEEFF1" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_6342_11137"
          x1="89.8558"
          y1="79.8911"
          x2="126.634"
          y2="77.6212"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#898989" />
          <stop offset="1" stopColor="#B9B9B9" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_6342_11137"
          x1="92.8349"
          y1="72.074"
          x2="106.561"
          y2="71.2269"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF250" />
          <stop offset="1" stopColor="#DDC703" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_6342_11137"
          x1="49.8814"
          y1="50.012"
          x2="97.5897"
          y2="47.0428"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#D9EDFE" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_6342_11137"
          x1="55.511"
          y1="69.5153"
          x2="91.9649"
          y2="27.5967"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#898989" />
          <stop offset="1" stopColor="#B9B9B9" />
        </linearGradient>
      </defs>
    </svg>
  )
);
