import React from 'react';

export const GenericMessage = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(
  (props, ref) => (
    <svg
      ref={ref}
      {...props}
      data-testid="generic-message-icon"
      width="128"
      height="128"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6524_10986)">
        <g opacity="0.3">
          <mask
            id="mask0_6524_10986"
            style={{ maskType: 'luminance' }}
            maskUnits="userSpaceOnUse"
            x="67"
            y="21"
            width="58"
            height="58"
          >
            <path
              d="M95.6587 78.8907C111.474 78.8907 124.295 66.0697 124.295 50.2542C124.295 34.4388 111.474 21.6178 95.6587 21.6178C79.8432 21.6178 67.0222 34.4388 67.0222 50.2542C67.0222 66.0697 79.8432 78.8907 95.6587 78.8907Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask0_6524_10986)">
            <path
              d="M95.6587 78.8907C111.474 78.8907 124.295 66.0697 124.295 50.2542C124.295 34.4388 111.474 21.6178 95.6587 21.6178C79.8432 21.6178 67.0222 34.4388 67.0222 50.2542C67.0222 66.0697 79.8432 78.8907 95.6587 78.8907Z"
              fill="url(#paint0_linear_6524_10986)"
            />
          </g>
        </g>
        <g opacity="0.3">
          <mask
            id="mask1_6524_10986"
            style={{ maskType: 'luminance' }}
            maskUnits="userSpaceOnUse"
            x="-2"
            y="61"
            width="50"
            height="50"
          >
            <path
              d="M23.0258 110.18C36.4495 110.18 47.3316 99.2975 47.3316 85.8738C47.3316 72.4501 36.4495 61.568 23.0258 61.568C9.60207 61.568 -1.28 72.4501 -1.28 85.8738C-1.28 99.2975 9.60207 110.18 23.0258 110.18Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask1_6524_10986)">
            <path
              d="M23.0258 110.18C36.4495 110.18 47.3316 99.2975 47.3316 85.8738C47.3316 72.4501 36.4495 61.568 23.0258 61.568C9.60207 61.568 -1.28 72.4501 -1.28 85.8738C-1.28 99.2975 9.60207 110.18 23.0258 110.18Z"
              fill="url(#paint1_linear_6524_10986)"
            />
          </g>
        </g>
        <mask
          id="mask2_6524_10986"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="3"
          width="127"
          height="107"
        >
          <path
            d="M104.832 67.7049C104.832 90.8302 86.0871 109.582 62.9547 109.582C39.8222 109.582 21.0773 90.8373 21.0773 67.7049L0.483551 68.0036L8.74666 3.76889H120.199L126.72 68.0036L104.832 67.7049Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask2_6524_10986)">
          <mask
            id="mask3_6524_10986"
            style={{ maskType: 'luminance' }}
            maskUnits="userSpaceOnUse"
            x="21"
            y="25"
            width="84"
            height="85"
          >
            <path
              d="M62.9689 109.547C86.0971 109.547 104.846 90.7976 104.846 67.6693C104.846 44.5411 86.0971 25.792 62.9689 25.792C39.8407 25.792 21.0916 44.5411 21.0916 67.6693C21.0916 90.7976 39.8407 109.547 62.9689 109.547Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask3_6524_10986)">
            <path
              d="M62.9689 109.547C86.0971 109.547 104.846 90.7976 104.846 67.6693C104.846 44.5411 86.0971 25.792 62.9689 25.792C39.8407 25.792 21.0916 44.5411 21.0916 67.6693C21.0916 90.7976 39.8407 109.547 62.9689 109.547Z"
              fill="url(#paint2_linear_6524_10986)"
            />
          </g>
        </g>
        <mask
          id="mask4_6524_10986"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="3"
          width="127"
          height="107"
        >
          <path
            d="M104.832 67.7049C104.832 90.8302 86.0871 109.582 62.9547 109.582C39.8222 109.582 21.0773 90.8373 21.0773 67.7049L0.483551 68.0036L8.74666 3.76889H120.199L126.72 68.0036L104.832 67.7049Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask4_6524_10986)">
          <path
            d="M86.5138 49.7564L96.9387 41.7849C97.5502 41.3227 98.4107 41.408 98.9084 41.9911L108.508 53.2267C109.056 53.8667 108.942 54.8409 108.252 55.3387L96.6613 63.6444L86.5138 49.7564Z"
            fill="#E5D770"
          />
          <path
            d="M40.8462 49.7564L30.4142 41.7849C29.8027 41.3227 28.9422 41.408 28.4444 41.9911L18.8444 53.2267C18.2969 53.8667 18.4107 54.8409 19.1004 55.3387L30.6987 63.6444L40.8462 49.7564Z"
            fill="#E5D770"
          />
          <path
            d="M96.5689 63.6444H30.6987L40.8462 49.7564H86.4284L96.5689 63.6444Z"
            fill="#8C7C1B"
          />
          <path
            d="M96.5689 63.6444H30.6987V129.515H96.5689V63.6444Z"
            fill="url(#paint3_linear_6524_10986)"
          />
          <path
            opacity="0.16"
            d="M72.6969 19.3564H54.5778C53.3831 19.3564 52.2453 19.8329 51.3991 20.672L37.888 34.1831C37.0418 35.0293 36.5724 36.1671 36.5724 37.3618V55.4809C36.5724 56.6756 37.0489 57.8133 37.888 58.6596L51.3991 72.1707C52.2453 73.0169 53.3831 73.4862 54.5778 73.4862H72.6969C73.8916 73.4862 75.0293 73.0098 75.8756 72.1707L89.3867 58.6596C90.2329 57.8133 90.7022 56.6756 90.7022 55.4809V37.3618C90.7022 36.1671 90.2258 35.0293 89.3867 34.1831L75.8756 20.672C75.0293 19.8329 73.8844 19.3564 72.6969 19.3564Z"
            fill="black"
          />
          <path
            d="M73.1804 15.9858H54.0942C52.8356 15.9858 51.6338 16.4836 50.7449 17.3724L36.5013 31.6089C35.6124 32.4978 35.1147 33.6996 35.1147 34.9582V54.0516C35.1147 55.3102 35.6124 56.512 36.5013 57.4009L50.7378 71.6373C51.6267 72.5262 52.8284 73.024 54.0871 73.024H73.1804C74.4391 73.024 75.6409 72.5262 76.5298 71.6373L90.7662 57.4009C91.6551 56.512 92.1529 55.3102 92.1529 54.0516V34.9582C92.1529 33.6996 91.6551 32.4978 90.7662 31.6089L76.5298 17.3724C75.6409 16.4836 74.4391 15.9858 73.1804 15.9858Z"
            fill="#D85951"
          />
          <path
            d="M72.3769 18.3893H54.8978C53.7458 18.3893 52.6436 18.8444 51.8329 19.6622L38.7911 32.6969C37.9804 33.5076 37.5182 34.6169 37.5182 35.7618V53.2409C37.5182 54.3929 37.9733 55.4951 38.7911 56.3058L51.8329 69.3476C52.6436 70.1582 53.7529 70.6204 54.8978 70.6204H72.3769C73.5289 70.6204 74.6311 70.1653 75.4418 69.3476L88.4836 56.3058C89.2942 55.4951 89.7565 54.3858 89.7565 53.2409V35.7618C89.7565 34.6098 89.3013 33.5076 88.4836 32.6969L75.4418 19.6551C74.6311 18.8444 73.5289 18.3893 72.3769 18.3893Z"
            fill="#D85951"
          />
          <path
            d="M61.4756 58.8729C60.9067 58.2898 60.6222 57.5502 60.6222 56.6471C60.6222 55.744 60.9067 55.0116 61.4756 54.4427C62.0444 53.8738 62.7769 53.5893 63.68 53.5893C64.5831 53.5893 65.3227 53.8738 65.9058 54.4427C66.4889 55.0116 66.7804 55.744 66.7804 56.6471C66.7804 57.5502 66.4889 58.2898 65.9058 58.8729C65.3227 59.456 64.5831 59.7476 63.68 59.7476C62.7769 59.7476 62.0444 59.456 61.4756 58.8729ZM62.0444 49.3724C61.9449 49.2729 61.8951 49.1449 61.8951 49.0027L61.4116 29.7813C61.4116 29.6391 61.4613 29.5111 61.568 29.4116C61.6676 29.312 61.7956 29.2622 61.9378 29.2622H65.9556C66.0978 29.2622 66.2258 29.312 66.3253 29.4116C66.4249 29.5111 66.4747 29.6391 66.4747 29.7813L65.9982 49.0027C65.9982 49.152 65.9484 49.2729 65.8418 49.3724C65.7422 49.472 65.6142 49.5289 65.472 49.5289H62.4142C62.272 49.5289 62.144 49.4791 62.0444 49.3724Z"
            fill="white"
          />
          <path
            d="M72.3769 20.2809C73.0311 20.2809 73.6427 20.5369 74.1049 20.992L87.1467 34.0338C87.6089 34.496 87.8649 35.1076 87.8649 35.7618V53.2409C87.8649 53.8951 87.6089 54.5067 87.1538 54.9689L74.112 68.0107C73.6498 68.4729 73.0382 68.7218 72.384 68.7218H54.8978C54.2436 68.7218 53.632 68.4658 53.1698 68.0107L40.128 54.9689C39.6658 54.5067 39.4098 53.8951 39.4098 53.2409V35.7618C39.4098 35.1076 39.6658 34.496 40.1209 34.0338L53.1627 20.992C53.6249 20.5298 54.2364 20.2809 54.8907 20.2809H72.3769ZM72.3769 18.3893H54.8978C53.7458 18.3893 52.6436 18.8444 51.8329 19.6622L38.7911 32.6969C37.9804 33.5076 37.5182 34.6169 37.5182 35.7618V53.2409C37.5182 54.3929 37.9733 55.4951 38.7911 56.3058L51.8329 69.3476C52.6436 70.1582 53.7529 70.6204 54.8978 70.6204H72.3769C73.5289 70.6204 74.6311 70.1653 75.4418 69.3476L88.4836 56.3058C89.2942 55.4951 89.7565 54.3858 89.7565 53.2409V35.7618C89.7565 34.6098 89.3013 33.5076 88.4836 32.6969L75.4418 19.6551C74.6311 18.8444 73.5289 18.3893 72.3769 18.3893Z"
            fill="white"
          />
          <path
            d="M30.7911 63.6444H96.6187L100.188 76.2524C100.743 78.208 99.2427 80.1422 97.1804 80.1422H30.2364C28.1742 80.1422 26.6738 78.208 27.2284 76.2524L30.7911 63.6444Z"
            fill="#E5D770"
          />
          <path opacity="0.16" d="M96.5618 80.1493H30.7342V83.6124H96.5618V80.1493Z" fill="black" />
        </g>
        <path
          opacity="0.16"
          d="M84.7289 61.568H42.5956L44.6009 63.6444H82.6027L84.7289 61.568Z"
          fill="black"
        />
        <g opacity="0.5">
          <mask
            id="mask5_6524_10986"
            style={{ maskType: 'luminance' }}
            maskUnits="userSpaceOnUse"
            x="11"
            y="36"
            width="15"
            height="15"
          >
            <path
              d="M18.5316 50.9156C22.5178 50.9156 25.7493 47.684 25.7493 43.6978C25.7493 39.7115 22.5178 36.48 18.5316 36.48C14.5453 36.48 11.3138 39.7115 11.3138 43.6978C11.3138 47.684 14.5453 50.9156 18.5316 50.9156Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask5_6524_10986)">
            <path
              d="M18.5316 50.9156C22.5178 50.9156 25.7493 47.684 25.7493 43.6978C25.7493 39.7115 22.5178 36.48 18.5316 36.48C14.5453 36.48 11.3138 39.7115 11.3138 43.6978C11.3138 47.684 14.5453 50.9156 18.5316 50.9156Z"
              fill="url(#paint4_linear_6524_10986)"
            />
          </g>
        </g>
        <g opacity="0.5">
          <mask
            id="mask6_6524_10986"
            style={{ maskType: 'luminance' }}
            maskUnits="userSpaceOnUse"
            x="101"
            y="97"
            width="16"
            height="15"
          >
            <path
              d="M109.056 111.879C113.042 111.879 116.274 108.648 116.274 104.661C116.274 100.675 113.042 97.4436 109.056 97.4436C105.07 97.4436 101.838 100.675 101.838 104.661C101.838 108.648 105.07 111.879 109.056 111.879Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask6_6524_10986)">
            <path
              d="M109.056 111.879C113.042 111.879 116.274 108.648 116.274 104.661C116.274 100.675 113.042 97.4436 109.056 97.4436C105.07 97.4436 101.838 100.675 101.838 104.661C101.838 108.648 105.07 111.879 109.056 111.879Z"
              fill="url(#paint5_linear_6524_10986)"
            />
          </g>
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_6524_10986"
          x1="67.0212"
          y1="50.2518"
          x2="124.299"
          y2="50.2518"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFE0E4" />
          <stop offset="1" stopColor="#EEEFF1" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_6524_10986"
          x1="-1.28"
          y1="85.872"
          x2="47.335"
          y2="85.872"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFE0E4" />
          <stop offset="1" stopColor="#EEEFF1" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_6524_10986"
          x1="21.0968"
          y1="67.669"
          x2="104.846"
          y2="67.669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFE0E4" />
          <stop offset="1" stopColor="#EEEFF1" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_6524_10986"
          x1="63.6358"
          y1="129.52"
          x2="63.6358"
          y2="63.6469"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D8AF32" />
          <stop offset="1" stopColor="#D8BC32" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_6524_10986"
          x1="11.3127"
          y1="43.6963"
          x2="25.752"
          y2="43.6963"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFE0E4" />
          <stop offset="1" stopColor="#EEEFF1" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_6524_10986"
          x1="101.834"
          y1="104.664"
          x2="116.274"
          y2="104.664"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFE0E4" />
          <stop offset="1" stopColor="#EEEFF1" />
        </linearGradient>
        <clipPath id="clip0_6524_10986">
          <rect width="128" height="128" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
);
