/**
 * @namespace Mapper
 * @interface Assessments
 */

import { IntlShape } from 'react-intl';
import { Datetime, DateType, StatusType } from '..';

export interface AssessmentsAttributes {
  name: string;
  value: string;
}

interface AssessmentsResponseItem {
  id: string;
  limit: { model: number; source: number };
  term: { days: number; fee: number };
  updated_at: string;
  updated_by: { identifier: string; term_fee: { term: number } };
  status: { name: string };
  source_id: { vendor: string };
  information_tag: any;
  data: {
    customer: {
      attributes: AssessmentsAttributes[];
      account_id: string;
      bees_account_id: string;
      sales_agency: string;
      sales_area: string;
      category: string;
    };
  };
  score: number;
}

export interface AssessmentsResponse {
  data: AssessmentsResponseItem[];
  pageable: { total: number };
}

export enum CustomerAttributes {
  POC_NAME = 'POC_NAME',
  POC_DOCUMENT = 'POC_DOCUMENT',
}

export interface AssessmentsItem {
  assessmentId: string;
  beesAccountId: string;
  accountId: string;
  vendorId: string;
  status: string;
  pocName: string;
  pocDocument: string;
  creditLimit: string;
  term: string;
  fee: string;
  score: string;
  salesAgency: string;
  salesArea: string;
  customerType: string;
  updatedAt: { value: string; type: string };
  updatedBy: string;
  recommendation: string;
  actions?: { tag: string; selected: AssessmentsItem; callbackFn: () => Promise<void> };
  original: { limit: string; term: string };
}

export interface MappedAssessments {
  data: AssessmentsItem[];
  pageable: { total: number };
}

/**
 * @namespace Format
 * @interface FormatInformation
 */
interface FormatBase {
  config: { regex: string; format: string };
}

export type FormatType = 'document-number' | 'phone-number' | 'address' | 'zip-code' | 'default';

interface FormatDefault extends FormatBase {
  type: Exclude<FormatType, 'address'>;
  value: string;
}

interface FormatAddress extends FormatBase {
  type: 'address';
  value: { city: string; state: string; street: string; zipCode: string };
}

export type Format = FormatDefault | FormatAddress;

/**
 * @namespace Formatting
 * @interface Format
 */

interface FormatFnBase {
  value: string;
  config?: { datetimeType: DateType };
}

export interface GeneralFn extends FormatFnBase {
  format: IntlShape['formatMessage'];
}

export interface CurrencyFn extends FormatFnBase {
  format: Intl.NumberFormat['format'];
}

export interface DatetimeFn extends FormatFnBase {
  format: Datetime['format'];
}

export interface NumberFn extends FormatFnBase {
  locale: string;
}

export interface TermFeeFn extends Omit<FormatFnBase, 'value'> {
  value: { term: string; fee: string };
  format: IntlShape['formatMessage'];
}

export interface StatusFn extends FormatFnBase {
  type: StatusType;
  format: IntlShape['formatMessage'];
}
