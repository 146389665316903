import { Status, Text } from '../../../components/organisms/Tables/custom-columns';
import { ColumnProps, FilterProps, Tables } from '../../../interfaces';

export const columns: ColumnProps[] = [
  {
    accessor: 'pocName',
    sizing: { size: 15, minSize: 180 },
    sorting: { has: true },
    render: (value: string) => (
      <Text type="text" params={{ value, sizing: { size: 15 } }} hasEllipsis />
    ),
  },
  {
    accessor: 'accountId',
    sizing: { size: 15, minSize: 180 },
    sorting: { has: true },
    render: (value: string) => (
      <Text type="text" params={{ value, sizing: { size: 15 } }} hasEllipsis />
    ),
  },
  {
    accessor: 'creditLimit',
    sizing: { size: 14, minSize: 180 },
    sorting: { has: true },
    render: (value: string) => (
      <Text type="currency" params={{ value, sizing: { size: 14 } }} hasEllipsis />
    ),
  },
  {
    accessor: 'term',
    sizing: { size: 8, minSize: 60 },
    sorting: { has: true },
    render: (value: string) => <Text type="days" params={{ value, sizing: { size: 8 } }} />,
  },
  {
    accessor: 'fee',
    sizing: { size: 8, minSize: 60 },
    sorting: { has: true },
    render: (value: string) => <Text type="fee" params={{ value, sizing: { size: 8 } }} />,
  },
  {
    accessor: 'updatedBy',
    sizing: { size: 15, minSize: 140 },
    sorting: { has: true },
    render: (value: string) => (
      <Text type="text" params={{ value, sizing: { size: 15 } }} hasEllipsis />
    ),
  },
  {
    accessor: 'updatedAt',
    sizing: { size: 15, minSize: 200 },
    sorting: { has: true },
    render: ({ value, type }) => (
      <Text type="datetime" params={{ value, type, sizing: { size: 15 } }} />
    ),
  },
  {
    accessor: 'status',
    sizing: { size: 10, minSize: 120 },
    sorting: { has: true },
    render: (status: string) => <Status table={Tables.INPROGRESS} status={status} />,
  },
];

export const filters: FilterProps[] = [
  {
    type: 'range-value',
    identifier: 'creditLimit',
    name: 'tables.filters.inputs.creditLimit.name',
    title: '',
    input: [
      {
        type: 'text',
        format: 'currency',
        identifier: 'min',
        label: 'tables.filters.inputs.creditLimit.min.label',
        placeholder: 'tables.filters.inputs.creditLimit.min.placeholder',
        affix: {
          type: 'currency',
          prefix: true,
          sufix: false,
        },
      },
      {
        type: 'text',
        format: 'currency',
        identifier: 'max',
        label: 'tables.filters.inputs.creditLimit.max.label',
        placeholder: 'tables.filters.inputs.creditLimit.max.placeholder',
        affix: {
          type: 'currency',
          prefix: true,
          sufix: false,
        },
      },
    ],
  },
  {
    type: 'range-value',
    identifier: 'term',
    name: 'tables.filters.inputs.term.name',
    title: '',
    input: [
      {
        type: 'text',
        format: 'days',
        identifier: 'min',
        label: 'tables.filters.inputs.term.min.label',
        placeholder: 'tables.filters.inputs.term.min.placeholder',
        affix: {
          type: 'text',
          prefix: false,
          sufix: true,
          label: 'tables.filters.inputs.term.min.sufix',
        },
      },
      {
        type: 'text',
        format: 'days',
        identifier: 'max',
        label: 'tables.filters.inputs.term.max.label',
        placeholder: 'tables.filters.inputs.term.max.placeholder',
        affix: {
          type: 'text',
          prefix: false,
          sufix: true,
          label: 'tables.filters.inputs.term.max.sufix',
        },
      },
    ],
  },
  {
    type: 'range-value',
    identifier: 'fee',
    name: 'tables.filters.inputs.fee.name',
    title: '',
    input: [
      {
        type: 'text',
        format: 'percentage',
        identifier: 'min',
        label: 'tables.filters.inputs.fee.min.label',
        placeholder: 'tables.filters.inputs.fee.min.placeholder',
        affix: {
          type: 'percentage',
          prefix: false,
          sufix: true,
        },
      },
      {
        type: 'text',
        format: 'percentage',
        identifier: 'max',
        label: 'tables.filters.inputs.fee.max.label',
        placeholder: 'tables.filters.inputs.fee.max.placeholder',
        affix: {
          type: 'percentage',
          prefix: false,
          sufix: true,
        },
      },
    ],
  },
  {
    type: 'range-date',
    identifier: 'updatedAt',
    name: 'tables.filters.inputs.updatedAt.name',
    title: '',
    input: [
      {
        type: 'text',
        format: 'text',
        identifier: 'startDate',
        label: 'tables.filters.inputs.updatedAt.min.label',
        placeholder: 'tables.filters.inputs.updatedAt.min.placeholder',
      },
      {
        type: 'text',
        format: 'text',
        identifier: 'endDate',
        label: 'tables.filters.inputs.updatedAt.max.label',
        placeholder: 'tables.filters.inputs.updatedAt.max.placeholder',
      },
    ],
  },
  {
    type: 'checkbox',
    identifier: 'status',
    name: 'tables.filters.inputs.status.name',
    title: 'tables.filters.inputs.status.title',
    input: [
      {
        type: 'checkbox',
        format: 'text',
        identifier: 'processing',
        label: 'tables.filters.inputs.status.labels.processing',
        value: 'WAITING_FOR_ACTIVATION,APPROVED_BY_BU',
      },
      {
        type: 'checkbox',
        format: 'text',
        identifier: 'integration',
        label: 'tables.filters.inputs.status.labels.integration',
        value: 'WAITING_ONBOARDING',
      },
    ],
  },
];
