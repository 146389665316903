import {
  AlertTriangle,
  Check,
  DocumentSigned,
  DollarSign,
  Lock,
  Unlock,
  X,
  Zap,
} from '@hexa-ui/icons';
import { useIntl } from 'react-intl';
import { CreditHistoryStatus } from '../interfaces';
import { useGetUserPreferences } from './useGetUserPreferences';

export const useCreditHistory = (historyStatus: string) => {
  const { formatMessage } = useIntl();

  const { configs } = useGetUserPreferences();
  const { features } = configs;
  const { history } = features['poc-details'];

  const { status, view } = Object.entries(history.credit).reduce(
    (acc, [key, value]: any) => {
      if (value.status.includes(historyStatus)) {
        acc.status = key;
        acc.view = value.view;
      }

      return acc;
    },
    { status: '', view: {} }
  );

  const creditHistoryMapping = {
    [CreditHistoryStatus.NEW_CREDIT_RECOMMENDATION]: {
      backgroundColor: '#E9F1FF',
      icon: <DollarSign size="medium" style={{ color: '#0363C4' }} />,
    },
    [CreditHistoryStatus.CREDIT_RECOMMENDATION_APPROVED]: {
      backgroundColor: '#E6F2EB',
      icon: <Check size="medium" style={{ color: '#176D38' }} />,
      method: formatMessage({ id: 'pages.poc-details.history.credit.labels.method.approval' }),
    },
    [CreditHistoryStatus.CREDIT_RECOMMENDATION_DENIED]: {
      backgroundColor: '#FCECEB',
      icon: <X size="medium" style={{ color: '#E53C32' }} />,
      method: formatMessage({ id: 'pages.poc-details.history.credit.labels.method.denial' }),
    },
    [CreditHistoryStatus.WAITING_ONBOARDING_BYPASSED]: {
      backgroundColor: '#FFEBD0',
      icon: <AlertTriangle size="medium" style={{ color: '#FFAA33' }} />,
    },
    [CreditHistoryStatus.WAITING_ONBOARDING_COMPLETED]: {
      backgroundColor: '#E6F2EB',
      icon: <DocumentSigned size="medium" style={{ color: '#176D38' }} />,
      identifier: formatMessage({ id: 'pages.poc-details.history.credit.identifiers.poc' }),
    },
    [CreditHistoryStatus.CREDIT_LINE_ACTIVATED]: {
      backgroundColor: '#F6F2FF',
      icon: <Zap size="medium" style={{ color: '#A080FF' }} />,
    },
    [CreditHistoryStatus.CREDIT_LINE_BLOCKED]: {
      backgroundColor: '#FCECEB',
      icon: <Lock size="medium" style={{ color: '#E53C32' }} />,
    },
    [CreditHistoryStatus.CREDIT_LINE_UNBLOCKED]: {
      backgroundColor: '#FFF7EB',
      icon: <Unlock size="medium" style={{ color: '#FFAA33' }} />,
    },
  };

  return { status, view, ...creditHistoryMapping[status] };
};
