import { GenericObject, UserTypesMapping } from '../../interfaces';

interface UserData {
  userMapping: UserTypesMapping;
  groups: string[];
}

export const getUserType = (userData: UserData, userConfig: GenericObject) => {
  const { userMapping, groups } = userData;

  const [, user] =
    Object.entries(userMapping)
      .sort(([, a], [, b]) => a.order - b.order)
      .find(([key]) => groups.includes(key)) || [];

  return {
    type: user?.type ?? '',
    group: user?.group ?? '',
    userConfig: userConfig[`${user?.type}_${user?.group}`] ?? {},
  };
};
