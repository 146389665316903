import { Button, Heading, Paragraph } from '@hexa-ui/components';
import { useIntl } from 'react-intl';
import { GenericError } from '../../../../icons/Error/Generic/Generic.svg';
import { CustomErrorProps } from './CustomError.types';
import { ButtonsContainer, Content, CustomErrorContainer } from './styles';

export const CustomError: React.FC<CustomErrorProps> = ({
  title,
  description,
  button = { has: false },
  icon: Icon = GenericError,
  onBack,
  onReload,
}) => {
  const { formatMessage } = useIntl();

  const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (button.has && button.onClick) {
      button.onClick(event);

      return;
    }

    onReload();
  };

  return (
    <CustomErrorContainer data-testid="custom-error-container">
      <Icon />

      <Content>
        <Heading size="H3">{formatMessage({ id: title })}</Heading>

        <Paragraph
          size="basis"
          css={{ textAlign: 'center', wordWrap: 'break-word', color: '#1414148F' }}
        >
          {formatMessage({ id: description })}
        </Paragraph>
      </Content>

      <ButtonsContainer>
        <Button id="on-back-button" variant={button.has ? 'secondary' : 'primary'} onClick={onBack}>
          {formatMessage({ id: 'errors.custom.buttons.back' })}
        </Button>

        {button.has && (
          <Button id="on-reload-button" variant="primary" onClick={onClick}>
            {formatMessage({ id: button.text })}
          </Button>
        )}
      </ButtonsContainer>
    </CustomErrorContainer>
  );
};
