import { WithOptimizelyProps, withOptimizely } from '@optimizely/react-sdk';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Page } from '../../../../components/pages/Page/Page';
import { UserContext } from '../../../../contexts';
import { useApplication } from '../../../../hooks/useApplication';
import { useConfig } from '../../../../hooks/useConfig';
import { useHeader } from '../../../../hooks/useHeader';
import { ProviderProps, UserPreferences, Variables } from '../../../../interfaces';
import { useSelected } from '../../../../store/hooks/selectedHook';
import { formatCurrency, getCurrencySymbol } from '../../../../utils/currency';
import { custom, format } from '../../../../utils/datetime';
import { has } from '../../../../utils/permissions';

interface UserProviderProps extends ProviderProps {
  variables: Variables;
}

const Provider: React.FC<UserProviderProps & WithOptimizelyProps> = ({
  children,
  variables,
  optimizely,
}) => {
  const { formatMessage } = useIntl();

  const { getConfigs } = useConfig({ variables });
  const { selected, setUserName } = useSelected();

  const { data, name, getLanguageByCountry } = useApplication();
  const { setPageHeader } = useHeader();

  const [hasPermission, setHasPermission] = useState<boolean>(true);
  const [userPreferences, setUserPreferences] = useState<UserPreferences | null>(null);

  const value = useMemo(() => ({ userPreferences }), [userPreferences]);

  const toggles = useMemo(() => {
    const features = optimizely.getEnabledFeatures();

    return features.filter((feature) => feature.startsWith('cm_'));
  }, [optimizely]);

  const getUserPreferences = useCallback(() => {
    setUserName(name.full);

    const country = selected.country;
    const vendorId = selected.vendorId;
    const locale = getLanguageByCountry(country);

    const { preferredLanguage, timezone, preferredTimeFormat } = data.profile;

    const allConfigs = getConfigs();

    if (!allConfigs) {
      setUserPreferences(null);
      setHasPermission(false);

      setPageHeader({ page: 'empty' });

      return;
    } else {
      setHasPermission(true);
    }

    const { user, permissions, currency, configs } = allConfigs;
    const { thousandSeparator, decimalSeparator } = currency;

    setUserPreferences({
      user: user,
      language: { locale: preferredLanguage, custom: custom(preferredLanguage) },
      selected: { country, vendorId },
      datetime: {
        format: format({ locale: preferredLanguage, timezone, preferredTimeFormat, formatMessage }),
      },
      currency: {
        config: {
          symbol: getCurrencySymbol(locale),
          thousandSeparator: thousandSeparator,
          decimalSeparator: decimalSeparator,
        },
        format: formatCurrency(locale),
      },
      configs: configs,
      toggles: { has: has(toggles) },
      permissions: { has: has(permissions) },
    });
  }, [selected.country, selected.vendorId, data, toggles]);

  useEffect(() => {
    if (selected.country && selected.vendorId) getUserPreferences();
  }, [selected.country, selected.vendorId]);

  if (!userPreferences && hasPermission) {
    return <Page.Loading description={formatMessage({ id: 'loading.optimizely' })} />;
  }

  if (!userPreferences && !hasPermission) {
    return (
      <Page.Error
        type="custom"
        title={'errors.custom.something-went-wrong.title'}
        description={'errors.custom.something-went-wrong.description'}
        button={{ has: true, text: 'errors.custom.buttons.reload' }}
      />
    );
  }

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const UserProvider = withOptimizely(Provider);
