import { Divider } from '@hexa-ui/components';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useGetUserPreferences } from '../../../../../hooks/useGetUserPreferences';
import SegmentService from '../../../../../services/segment';
import { processFilters } from '../../../../../utils/filter/processFilters';
import { Slider } from '../../../Slider/Slider';
import { useFilter } from '../Context/FilterContext';
import { ChipsProps } from '../Context/FilterContext.types';
import { ChipTooltip } from './Tooltip/ChipTooltip';
import { ChipsContainer } from './styles';

export const FilterChips: React.FC = () => {
  const { formatMessage } = useIntl();

  const { table, filtered, chips, onDelete } = useFilter();
  const { selected } = useGetUserPreferences();

  const hasChips = useMemo(() => {
    return Object.keys(chips).length;
  }, [chips]);

  const formatted = ({ filtername, label, value }: ChipsProps) => {
    if (typeof value === 'string') {
      return `${formatMessage({ id: filtername })}: ${value}`;
    }

    const { min, max } = Object.entries(value).reduce(
      (acc, [key, item]) => {
        if (['min', 'startDate'].includes(key)) {
          acc.min = item;

          return acc;
        }

        if (['max', 'endDate'].includes(key)) {
          acc.max = item;

          return acc;
        }

        return acc;
      },
      { min: '', max: '' }
    );

    return `${formatMessage({ id: filtername })}: ${formatMessage({ id: label }, { min, max })}`;
  };

  const handleDelete = (key: string) => {
    onDelete(key);

    const filterValues = processFilters(filtered.value);

    SegmentService.paymentsButtonClicked(
      'Remove filter',
      'Remove filter',
      selected?.vendorId,
      'Credit Management Filter Removed',
      `CREDIT_MANAGEMENT_${table.toUpperCase()}_TAB`,
      filterValues
    );
  };

  if (!hasChips) {
    return null;
  }

  return (
    <React.Fragment>
      <Divider orientation="horizontal" css={{ width: '100%' }} />

      <ChipsContainer data-testid="chips-container">
        <Slider>
          {Object.entries(chips).map(([key, attributes]) => (
            <ChipTooltip
              key={key}
              description={formatted(attributes)}
              onDelete={() => handleDelete(key)}
            />
          ))}
        </Slider>
      </ChipsContainer>
    </React.Fragment>
  );
};
