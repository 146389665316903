export const handleFileStatus = (
  file,
  cb,
  idKey = 'latestFileId',
  statusKey = 'latestFileStatus'
) => {
  const latestFileStatus = localStorage.getItem(statusKey);
  const latestFileId = localStorage.getItem(idKey);

  if (!latestFileStatus || (file.status !== latestFileStatus && latestFileId === file.id)) {
    cb({ ...file, previousStatus: latestFileStatus });
    localStorage.setItem(statusKey, file.status);
  }
  localStorage.setItem(idKey, file.id);
};
