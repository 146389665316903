import React from 'react';

export const Failed = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(
  (props, ref) => (
    <svg
      ref={ref}
      {...props}
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="failed-icon"
    >
      <g clipPath="url(#clip0_6853_2764)">
        <path
          d="M28 52.5C41.531 52.5 52.5 41.531 52.5 28C52.5 14.469 41.531 3.5 28 3.5C14.469 3.5 3.5 14.469 3.5 28C3.5 41.531 14.469 52.5 28 52.5Z"
          fill="url(#paint0_linear_6853_2764)"
        />
        <g clipPath="url(#clip1_6853_2764)">
          <path
            opacity="0.16"
            d="M42.7175 51.3975L19.5387 47.5125C14.3238 46.6375 10.5 42.1225 10.5 36.8288V15.2425L10.5262 7.8225C10.5262 6.88625 11.2875 6.125 12.2325 6.125H42.9975C43.9425 6.125 44.7038 6.88625 44.7038 7.83125V49.7175C44.7038 50.7675 43.7588 51.5725 42.7175 51.3975Z"
            fill="black"
          />
          <path
            d="M42.7175 49.6475L19.5387 45.7625C14.3238 44.8875 10.5 40.3725 10.5 35.0788V13.4925L10.5262 6.0725C10.5262 5.13625 11.2875 4.375 12.2325 4.375H42.9975C43.9425 4.375 44.7038 5.13625 44.7038 6.08125V47.9675C44.7038 49.0175 43.7588 49.8225 42.7175 49.6475Z"
            fill="url(#paint1_linear_6853_2764)"
          />
          <path
            d="M15.0938 12.2064C15.0938 11.8914 15.3475 11.6377 15.6625 11.6377H39.6025C39.9175 11.6377 40.1713 11.8914 40.1713 12.2064C40.1713 12.5214 39.9175 12.7752 39.6025 12.7752H15.6625C15.3475 12.7752 15.0938 12.5214 15.0938 12.2064ZM15.6625 17.0802H39.6025C39.9175 17.0802 40.1713 16.8264 40.1713 16.5114C40.1713 16.1964 39.9175 15.9427 39.6025 15.9427H15.6625C15.3475 15.9427 15.0938 16.1964 15.0938 16.5114C15.0938 16.8264 15.3475 17.0802 15.6625 17.0802ZM15.6625 21.3852H39.6025C39.9175 21.3852 40.1713 21.1314 40.1713 20.8164C40.1713 20.5014 39.9175 20.2477 39.6025 20.2477H15.6625C15.3475 20.2477 15.0938 20.5014 15.0938 20.8164C15.0938 21.1314 15.3475 21.3852 15.6625 21.3852ZM15.6625 25.6902H39.6025C39.9175 25.6902 40.1713 25.4364 40.1713 25.1214C40.1713 24.8064 39.9175 24.5527 39.6025 24.5527H15.6625C15.3475 24.5527 15.0938 24.8064 15.0938 25.1214C15.0938 25.4364 15.3475 25.6902 15.6625 25.6902ZM15.6625 29.9952H32.6025C32.9175 29.9952 33.1713 29.7414 33.1713 29.4264C33.1713 29.1114 32.9175 28.8577 32.6025 28.8577H15.6625C15.3475 28.8577 15.0938 29.1114 15.0938 29.4264C15.0938 29.7414 15.3475 29.9952 15.6625 29.9952Z"
            fill="#4D4D4D"
          />
          <path
            opacity="0.24"
            d="M43.4438 49.0962L25.6551 37.6687C24.7451 37.1175 23.5463 37.6337 23.3713 38.675C22.9601 41.0725 21.0701 44.9837 17.0276 44.9837L17.9726 45.3162C18.5763 45.5262 19.2063 45.6837 19.8451 45.7712L43.2513 49.6125C43.5576 49.665 43.7063 49.2537 43.4438 49.0962Z"
            fill="black"
          />
          <path
            d="M43.4439 49.0962L26.0489 38.5525C25.1301 38.0012 23.9401 38.5262 23.7564 39.585C23.3364 41.9825 22.2601 45.4387 18.2351 45.4387L19.6351 45.78C19.9151 45.85 20.1951 45.9025 20.4839 45.955L43.2601 49.63C43.5664 49.6825 43.7151 49.2712 43.4526 49.1137L43.4439 49.0962Z"
            fill="url(#paint2_linear_6853_2764)"
          />
        </g>
        <path
          opacity="0.16"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.25 46.375C19.25 51.208 15.335 55.125 10.5044 55.125C5.67377 55.125 1.75 51.2167 1.75 46.375C1.75 41.5333 5.66505 37.625 10.4956 37.625C15.3262 37.625 19.2413 41.542 19.2413 46.375H19.25Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.25 45.5C19.25 50.333 15.335 54.25 10.5044 54.25C5.67377 54.25 1.75 50.3417 1.75 45.5C1.75 40.6583 5.66505 36.75 10.4956 36.75C15.3262 36.75 19.2413 40.667 19.2413 45.5H19.25Z"
          fill="#D85951"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.4769 48.4247L12.4272 46.375L14.4769 44.3253C15.0077 43.7945 15.0077 42.9289 14.4769 42.3981C13.9461 41.8673 13.0805 41.8673 12.5497 42.3981L10.5 44.4478L8.4503 42.3981C7.91951 41.8673 7.0539 41.8673 6.5231 42.3981C5.9923 42.9289 5.9923 43.7945 6.5231 44.3253L8.57279 46.375L6.5231 48.4247C5.9923 48.9555 5.9923 49.8211 6.5231 50.3519C7.0539 50.8827 7.91951 50.8827 8.4503 50.3519L10.5 48.3022L12.5497 50.3519C13.0805 50.8827 13.9461 50.8827 14.4769 50.3519C15.0077 49.8211 15.0077 48.9555 14.4769 48.4247Z"
          fill="black"
          fillOpacity="0.16"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.4769 47.5497L12.4272 45.5L14.4769 43.4503C15.0077 42.9195 15.0077 42.0539 14.4769 41.5231C13.9461 40.9923 13.0805 40.9923 12.5497 41.5231L10.5 43.5728L8.4503 41.5231C7.91951 40.9923 7.0539 40.9923 6.5231 41.5231C5.9923 42.0539 5.9923 42.9195 6.5231 43.4503L8.57279 45.5L6.5231 47.5497C5.9923 48.0805 5.9923 48.9461 6.5231 49.4769C7.0539 50.0077 7.91951 50.0077 8.4503 49.4769L10.5 47.4272L12.5497 49.4769C13.0805 50.0077 13.9461 50.0077 14.4769 49.4769C15.0077 48.9461 15.0077 48.0805 14.4769 47.5497Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_6853_2764"
          x1="3.5"
          y1="28"
          x2="52.5"
          y2="28"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFE0E4" />
          <stop offset="1" stopColor="#EEEFF1" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_6853_2764"
          x1="10.5"
          y1="27.02"
          x2="44.7038"
          y2="27.02"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D3E1F6" />
          <stop offset="1" stopColor="#EFF4FC" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_6853_2764"
          x1="19.4689"
          y1="41.09"
          x2="43.2689"
          y2="49.0175"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D4E1FF" />
          <stop offset="1" stopColor="#4F93DB" />
        </linearGradient>
        <clipPath id="clip0_6853_2764">
          <rect width="56" height="56" fill="white" />
        </clipPath>
        <clipPath id="clip1_6853_2764">
          <rect width="34.2038" height="47.04" fill="white" transform="translate(10.5 4.375)" />
        </clipPath>
      </defs>
    </svg>
  )
);
