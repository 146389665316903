import { Alert, Divider, Heading, Paragraph, TextLink, Tooltip } from '@hexa-ui/components';
import { Download, Info } from '@hexa-ui/icons';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useGetUserPreferences } from '../../../../hooks/useGetUserPreferences';
import { useNotification } from '../../../../hooks/useNotification';
import { useRequester } from '../../../../hooks/useRequester';
import { useToast } from '../../../../hooks/useToast';
import { UploadStatus } from '../../../../interfaces';
import { downloadFailedFile, getProgress } from '../../../../services';
import SegmentService from '../../../../services/segment';
import ProgressBar from '../../../molecules/ProgressBar/ProgressBar';
import { RulesAndConditions } from '../../../molecules/RulesAndConditions/RulesAndConditions';
import { handleFileStatus } from '../../../organisms/SSENotification/utils';
import { useUpload } from '../Context';
import {
  DescriptionToModal,
  ProgressCard,
  ProgressCardAnalyzed,
  TextContainer,
  TooltipContainer,
  TotalContainer,
  TotalTextContent,
} from './styles';

export const Progress: React.FC = () => {
  const { formatMessage } = useIntl();

  const { uploaded, progress, uploadError, processingStarted, analysisFailed, files } = useUpload();
  const { fileId } = uploaded.value;
  const {
    referenceName,
    analysisPercentage,
    processedPercentage,
    status,
    total,
    totalAnalysis,
    validated,
    failed,
    processed,
  } = progress.value;
  const [isSlowUpload, setIsSlowUpload] = useState(false);
  const { get } = useRequester();
  const { toast } = useToast();
  const { notify } = useNotification();

  const { configs, selected } = useGetUserPreferences();
  const { filesConfig } = configs;

  const interval = useRef<NodeJS.Timeout | null>(null);

  const checkProgressRetries = useRef(0);
  const shouldRetryOnErrors = useRef(true);

  const hasAnalyzed = useMemo(() => {
    return status === UploadStatus.ANALYZED;
  }, [status]);

  const hasFailed = useMemo(() => {
    return failed > 0;
  }, [failed]);

  const isProcessing = useMemo(() => {
    return status === UploadStatus.PROCESSING || status === UploadStatus.DONE;
  }, [status]);

  const progressValue = useMemo(() => {
    return isProcessing ? processed : totalAnalysis;
  }, [isProcessing, processed, totalAnalysis]);

  const progressMaxValue = useMemo(() => {
    return isProcessing ? validated : total;
  }, [isProcessing, validated, total]);

  const progressLabel = useMemo(() => {
    if (progressValue === 0 && progressMaxValue !== 0) {
      return formatMessage({ id: 'pages.upload.progress.meter.starting' });
    }

    if (isSlowUpload === true && progressValue > 0 && progressValue < progressMaxValue) {
      if (status === 'ANALYZING') {
        return `${formatMessage(
          { id: 'pages.upload.progress.meter.progress' },
          { progressValue, progressMaxValue }
        )}\n${formatMessage({ id: 'pages.upload.progress.meter.slow' })}`;
      }

      if (status === 'PROCESSING') {
        return `${formatMessage(
          { id: 'pages.upload.progress.meter.progressUpload' },
          { progressValue, progressMaxValue }
        )}\n${formatMessage({ id: 'pages.upload.progress.meter.slow' })}`;
      }
    }

    if (status === 'PROCESSING') {
      return formatMessage(
        { id: 'pages.upload.progress.meter.progressUpload' },
        { progressValue, progressMaxValue }
      );
    }

    return formatMessage(
      { id: 'pages.upload.progress.meter.progress' },
      { progressValue, progressMaxValue }
    );
  }, [progressValue, progressMaxValue, analysisFailed, isSlowUpload]);

  const returnFileSize = (size: number | null) => {
    const kb = size / 1024;
    if (kb < 1024) return `${kb.toFixed(2)} KB`;

    const mb = kb / 1024;
    if (mb < 1024) return `${mb.toFixed(2)} MB`;
  };

  const onDownload = useCallback(async () => {
    SegmentService.paymentsButtonClicked(
      'Download error list',
      'Download Failed Files',
      selected?.vendorId,
      'Credit Management Files Download Errors',
      'CREDIT_MANAGEMENT_FILE_PAGE'
    );
    toast.info({ message: 'files.modal.toasts.info' });

    const response = await downloadFailedFile({
      api: { get },
      params: { fileId, filesConfig },
    });

    if (!response?.success) {
      toast.error({ message: 'errors.generic' });
    }
  }, [fileId]);

  const getData = async () => {
    shouldRetryOnErrors.current = true;

    const slowUpdateId = setTimeout(() => {
      setIsSlowUpload(true);
    }, 60000);

    const shouldRetryTimeout = setTimeout(() => {
      shouldRetryOnErrors.current = false;
    }, 60000);

    const intervalId = setInterval(async () => {
      const response = await getProgress({
        api: { get },
        params: { fileId },
      });

      if (!response?.success && shouldRetryOnErrors.current) return;

      handleFileStatus(response?.data, notify);

      clearTimeout(shouldRetryTimeout);

      if (!response?.success || response?.data?.status === UploadStatus.FAILED) {
        if (response?.data?.status === UploadStatus.FAILED) {
          progress.setter(response?.data);
        }

        clearInterval(intervalId);
        clearTimeout(slowUpdateId);
        interval.current = intervalId;

        analysisFailed.setter(true);

        setIsSlowUpload(false);
        return;
      }

      if (
        response?.data?.status === UploadStatus.ANALYZED ||
        response?.data?.status === UploadStatus.DONE
      ) {
        clearInterval(intervalId);
        clearTimeout(slowUpdateId);

        analysisFailed.setter(false);
        setIsSlowUpload(false);
      }

      analysisFailed.setter(false);
      checkProgressRetries.current = 0;
      progress.setter(response?.data);
      interval.current = intervalId;
    }, 1000);
  };

  useEffect(() => {
    if (fileId) {
      getData();

      return () => {
        if (interval.current) clearTimeout(interval.current);
      };
    }
  }, [fileId, processingStarted.value]);

  if (!fileId && !uploadError.value) {
    return <></>;
  }

  return (
    <ProgressCard border="medium" elevated="small">
      <Heading size="H4" css={{ marginBottom: '8px' }}>
        {isProcessing
          ? formatMessage({ id: 'pages.upload.progress.processingTitle' })
          : formatMessage({ id: 'pages.upload.progress.title' })}
      </Heading>

      <TextContainer>
        {isProcessing ? (
          <Paragraph css={{ fontSize: '16px' }}>
            {formatMessage({ id: 'pages.upload.progress.processingDescription' })}
          </Paragraph>
        ) : (
          <DescriptionToModal colortype="primary">
            <FormattedMessage
              id={'pages.upload.uploader.rulesAndConditions.progress.description'}
              values={{
                TextLink: (chunks: any) => (
                  <RulesAndConditions
                    data-testid="rules-and-conditions-modal"
                    trigger={<TextLink css={{ fontSize: '16px' }}>{chunks}</TextLink>}
                  />
                ),
              }}
            />
          </DescriptionToModal>
        )}
      </TextContainer>

      {hasAnalyzed && (
        <Heading size="H5" css={{ marginBottom: '16px' }}>
          {formatMessage({ id: 'pages.upload.progress.finished.title' })}
        </Heading>
      )}

      <ProgressBar
        status={status}
        fileName={referenceName}
        filesSize={files.value.length ? returnFileSize(files.value[0].size) : '-'}
        percentage={isProcessing ? processedPercentage : analysisPercentage}
        progressLabel={progressLabel}
        hasError={analysisFailed.value}
        errorMessage={
          isProcessing
            ? formatMessage({ id: 'pages.upload.progress.error.processing' })
            : formatMessage({ id: 'pages.upload.progress.error.analysis' })
        }
      />

      {hasAnalyzed && (
        <ProgressCardAnalyzed border="medium" elevated="small">
          <React.Fragment>
            {hasFailed && (
              <Alert
                type="warning"
                message={formatMessage({ id: 'pages.upload.progress.finished.alert' })}
                css={{ width: '100%' }}
              />
            )}

            <TotalContainer data-testid="total-container">
              <TotalTextContent>
                <Paragraph size="small">
                  {formatMessage({ id: 'pages.upload.progress.finished.pocs.total' })}
                </Paragraph>

                <Heading size="H4">{total}</Heading>
              </TotalTextContent>
              <Divider orientation="vertical" css={{ height: '100%' }} />
              <TotalTextContent>
                <Paragraph size="small">
                  {formatMessage({ id: 'pages.upload.progress.finished.pocs.success' })}
                </Paragraph>

                <Heading size="H4">{validated}</Heading>
              </TotalTextContent>

              <Divider orientation="vertical" css={{ height: '100%' }} />

              <TotalTextContent>
                <TooltipContainer>
                  <Paragraph size="small">
                    {formatMessage({ id: 'pages.upload.progress.finished.pocs.error' })}
                  </Paragraph>

                  <Tooltip
                    placement="bottom"
                    text={formatMessage({ id: 'pages.upload.progress.finished.pocs.tooltip' })}
                  >
                    <Info size="tiny" />
                  </Tooltip>
                </TooltipContainer>

                <Heading size="H4" style={{ color: '#C9201D' }}>
                  {failed}
                </Heading>
              </TotalTextContent>
            </TotalContainer>

            {hasFailed && (
              <TextLink
                id="um"
                hasUnderline={false}
                color="primary"
                size="small"
                css={{
                  display: 'flex',
                  alignItems: 'center',
                  columnGap: '8px',
                  justifyContent: 'flex-end',
                  marginTop: '24px',
                  width: '100%',
                }}
                onClick={onDownload}
              >
                <Download size="medium" style={{ color: '#047AF1' }} />

                {formatMessage({ id: 'pages.upload.progress.finished.download' })}
              </TextLink>
            )}
          </React.Fragment>
        </ProgressCardAnalyzed>
      )}
    </ProgressCard>
  );
};
