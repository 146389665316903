import styled from 'styled-components';

export const ModalContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  row-gap: 16px;
  max-width: 100%;
  padding: 4px;

  overflow: hidden !important;
`;

export const TextContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

export const RadioContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;

  width: 100%;
  column-gap: 32px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;

  width: 100%;
  column-gap: 12px;
`;
