import { createInstance, OptimizelyProvider as Provider } from '@optimizely/react-sdk';
import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Page } from '../../components/pages/Page/Page';
import { useApplication } from '../../hooks/useApplication';
import { ProviderProps } from '../../interfaces';
import { useSelected } from '../../store/hooks/selectedHook';
import { OptimizelyFeature } from './Feature/OptimizelyFeature';

export const OptimizelyProvider: React.FC<ProviderProps> = ({ children }) => {
  const { formatMessage } = useIntl();

  const { data } = useApplication();
  const { selected } = useSelected();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hasError, setHasError] = useState<boolean>(false);

  const optimizely = createInstance({
    sdkKey: process.env.OPTIMIZELY_KEY,
    datafileOptions: { urlTemplate: 'https://cdn.optimizely.com/datafiles/%s.json' },
    logLevel: 4,
  });

  const setOptimizely = useCallback(async () => {
    optimizely.setUser({ id: data.userId, attributes: { country: selected.country } });

    await optimizely
      .onReady()
      .then(() => setHasError(false))
      .catch(() => setHasError(true))
      .finally(() => setIsLoading(false));
  }, [data, optimizely, selected.country]);

  useEffect(() => {
    if (optimizely && selected.country) setOptimizely();
  }, [optimizely, selected.country]);

  if (isLoading) {
    return <Page.Loading description={formatMessage({ id: 'loading.optimizely' })} />;
  }

  if (!isLoading && hasError) {
    return (
      <Page.Error
        type="custom"
        title={'errors.custom.something-went-wrong.title'}
        description={'errors.custom.something-went-wrong.description'}
        button={{ has: true, text: 'errors.custom.buttons.reload' }}
      />
    );
  }

  return (
    <Provider optimizely={optimizely} timeout={3000}>
      <OptimizelyFeature>{children}</OptimizelyFeature>
    </Provider>
  );
};
