import { Card } from '@hexa-ui/components';
import styled, { css } from 'styled-components';

export const Container = styled(Card)`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
`;

export const Toolbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  width: 100%;
  min-height: 72px;
  border-radius: 16px;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  padding: 8px 16px;

  width: 100%;
  min-height: 62px;

  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  row-gap: 8px;

  width: 100% !important;
  height: 100%;
`;

export const Content = styled.div<{ $height: string }>`
  position: relative;

  overflow: auto;
  height: ${({ $height }) => $height};
  width: 100%;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 100%;
`;

export const SortContent = styled.div<{ $hassort: boolean; $showsort: boolean }>`
  ${({ $hassort, $showsort }) => {
    if ($hassort) {
      return css`
        display: flex;
        align-items: center;

        width: min-content;

        & svg {
          line-height: 30px;
          margin-left: 6px;

          visibility: ${$showsort ? 'hidden' : 'visible'};
        }
      `;
    }

    return css`
      display: none;
    `;
  }}
`;

const Table = styled.table<{ $minwidth: number }>`
  ${({ $minwidth }) => $minwidth && `min-width: ${$minwidth}px;`}

  width: 100%;
  height: 100%;

  border-collapse: collapse;
  border-spacing: 0;
`;

const Thead = styled.thead`
  position: sticky;
  top: 0;
  left: 0;

  z-index: 5;

  background-color: white;
  box-shadow: 0rem 0.25rem 1rem 0rem rgba(0, 0, 0, 0.16);

  width: 100%;
`;

const TrHead = styled.tr`
  display: flex;

  width: 100%;
`;

const Th = styled.th<{
  $hassort: boolean;
  $showsort: boolean;
  $size: number;
}>`
  display: flex;
  align-items: center;

  height: 36px;

  overflow: hidden;

  padding: 4px 16px;
  background-color: rgba(0, 0, 0, 0.05);

  ${({ $size }) => {
    return css`
      flex: 0 0 ${$size ? `${$size}%` : 'auto'};
    `;
  }}

  ${({ $hassort, $showsort }) => {
    if ($hassort) {
      return css`
        cursor: pointer;

        &:hover {
          background-color: rgba(0, 0, 0, 0.15);

          & .sort-icon {
            visibility: ${$showsort ? 'visible' : 'hidden'};
          }
        }
      `;
    }
  }}
`;

const Tbody = styled.tbody.attrs<{ $height?: number }>(({ $height }) => ({
  style: { height: $height ? `${$height}px` : 'min(100vh - 400px)' },
}))`
  position: relative;
  background-color: white;

  width: 100%;
`;

const TrBody = styled.tr.attrs<{ $start?: number }>(({ $start }) => {
  if ($start >= 0) {
    return {
      style: {
        position: 'absolute',
        transform: `translateY(${$start}px)`,
      },
    };
  }
})<{ $start?: number; $hasclick?: boolean; $selected?: boolean }>`
  display: flex;

  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);

  ${({ $hasclick }) => $hasclick && 'cursor: pointer;'}
  ${({ $selected }) => {
    if ($selected) {
      return css`
        background-color: rgb(240, 236, 252);

        &:hover {
          background-color: transparent;
        }
      `;
    }
  }}
`;

const Td = styled.td<{ $size?: number }>`
  display: flex;
  align-items: center;

  height: 64px;
  overflow: hidden;

  padding: 12px 16px;

  ${({ $size }) => {
    return css`
      flex: 0 0 ${$size ? `${$size}%` : 'auto'};
    `;
  }}
`;

export const Styled = { Table, Thead, TrHead, Th, Tbody, TrBody, Td };
