import { List } from '@hexa-ui/icons';
import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useViews } from '../../../../store/hooks/customViewsHook';
import { CollapseButton } from '../../CollapseButton/CollapseButton';
import { useFilter } from '../Filter/Context/FilterContext';
import { ViewList } from './List/List';
import { ViewSelectorProps } from './ViewSelector.types';

export const ViewSelector: React.FC<ViewSelectorProps> = React.memo(({ table, onClear }) => {
  const { formatMessage } = useIntl();

  const { active } = useViews(table);
  const { onClear: onClearFilter } = useFilter();

  const [open, setOpen] = useState<boolean>(false);
  const isFirstRender = useRef<boolean>(true);

  const onClose = () => setOpen(false);

  const onClearAll = () => {
    onClearFilter(true);
    /* istanbul ignore next */
    if (onClear) onClear();
  };

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    onClearAll();
  }, [active.name]);

  return (
    <CollapseButton
      open={{ value: open, setter: setOpen }}
      label={formatMessage({ id: 'tables.views.selector.label' }, { name: active.name })}
      icon={List}
      style={{ width: 400 }}
    >
      <ViewList table={table} onClose={onClose} />
    </CollapseButton>
  );
});
