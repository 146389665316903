import { TextLink } from '@hexa-ui/components';
import { useIntl } from 'react-intl';
import { useGetUserPreferences } from '../../../hooks/useGetUserPreferences';
import { ActiveTabs } from '../../../interfaces';
import SegmentService from '../../../services/segment';
import { useFilter } from '../Table/Filter/Context/FilterContext';

export const ClearAllButton = () => {
  const { formatMessage } = useIntl();

  const { onClear } = useFilter();
  const { selected } = useGetUserPreferences();
  const tab = sessionStorage.getItem(ActiveTabs.ACTIVE_TAB_KEY)?.toUpperCase();

  const handleClick = () => {
    onClear();

    SegmentService.paymentsButtonClicked(
      'Clear all',
      'Clear all',
      selected?.vendorId,
      'Credit Management Filter Removed All',
      `CREDIT_MANAGEMENT_${tab}_TAB`
    );
  };

  return (
    <TextLink colorOption="blue" hasUnderline={false} size="small" onClick={handleClick}>
      {formatMessage({ id: 'tables.filters.buttons.clearAll' })}
    </TextLink>
  );
};
