import { Button, IconButton, LoadingDots, Paragraph, Tooltip } from '@hexa-ui/components';
import { Check2 } from '@hexa-ui/icons';
import React, { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useGetSources } from '../../../../hooks/useGetSources';
import { useGetUserPreferences } from '../../../../hooks/useGetUserPreferences';
import { Status } from '../../../../interfaces';
import SegmentService from '../../../../services/segment';
import { ApproveRecommendation } from '../../ApproveRecommendation/ApproveRecommendation';
import { ApprovalProps } from '../ApprovalButtons.types';

export const Approve: React.FC<ApprovalProps> = ({
  mode,
  type,
  button,
  counter,
  isLoading,
  selected,
  portal,
  onApproval,
}) => {
  const { formatMessage } = useIntl();

  const { onboardingRule } = useGetSources();
  const { permissions, selected: sellerSelected } = useGetUserPreferences();

  const [open, setOpen] = useState<boolean>(false);

  const onApprove = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement>, status: Status) => {
      event.stopPropagation();

      if (!onboardingRule) await onApproval(status);
    },
    [onApproval, onboardingRule]
  );

  const buttonClicked = (buttonLabel: string, buttonName: string, eventName: string) => {
    SegmentService.paymentsButtonClicked(
      buttonLabel,
      buttonName,
      sellerSelected?.vendorId,
      eventName,
      'CREDIT_MANAGEMENT_PENDING_TAB'
    );
  };

  const buttonMapping = useMemo(() => {
    return {
      'icon-button': (
        <IconButton
          id="approve-icon-button"
          title={formatMessage({ id: 'approval.buttons.APPROVED.tooltip' })}
          data-testid="approve-icon-button"
          icon={() => (
            <Tooltip
              placement="bottom"
              text={formatMessage({ id: 'approval.buttons.APPROVED.tooltip' })}
            >
              <Check2 size="medium" />
            </Tooltip>
          )}
          size={button.size}
          variant="tertiary"
          loading={isLoading}
          disabled={isLoading}
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            if (portal?.has) setOpen(true);

            onApprove(event, Status.APPROVED);
            buttonClicked(
              'Approve Icon',
              'Approve Icon Button',
              'Credit Management Approve Recommendation'
            );
          }}
        />
      ),
      button: (
        <Button
          id="approve-button"
          data-testid="approve-button"
          leading
          icon={() =>
            isLoading ? <LoadingDots data-testid="loading-dots" /> : <Check2 size="medium" />
          }
          size={button.size}
          variant="secondary"
          disabled={isLoading}
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            if (portal?.has) setOpen(true);

            onApprove(event, Status.APPROVED);
            buttonClicked(
              'Approve Selected',
              'Approve Selected Button',
              'Credit Management Mass Approval Approve Selected'
            );
          }}
        >
          <Paragraph size="small">
            {formatMessage({ id: `approval.buttons.APPROVED.${mode}` })}
          </Paragraph>
        </Button>
      ),
    };
  }, [isLoading, button, onboardingRule]);

  if (!permissions.has('actions.approve')) {
    return null;
  }

  if (onboardingRule) {
    return (
      <ApproveRecommendation
        mode={mode}
        data={mode === 'multiple' ? { counter } : selected}
        trigger={buttonMapping[type]}
        onApprove={onApproval}
        {...(portal?.has && { portal, open: { value: open, setter: setOpen } })}
      />
    );
  }

  return buttonMapping[type];
};
