import React from 'react';

export const NoPocs = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(
  (props, ref) => (
    <svg
      ref={ref}
      {...props}
      width="126"
      height="136"
      viewBox="0 0 126 136"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="no-pocs-icon"
    >
      <path
        d="M53.6906 6.53316L14.0334 27.908C8.20668 31.0486 4.57373 37.1338 4.57373 43.753L4.57373 90.7608C4.57373 97.2282 8.04334 103.198 13.6626 106.4L53.0917 128.867C58.6684 132.044 65.5152 132.012 71.0616 128.782L112.485 104.657C118.021 101.433 121.426 95.5091 121.426 89.1026L121.426 43.9092C121.426 37.2076 117.703 31.0608 111.764 27.9566L70.5686 6.42562C65.2735 3.65806 58.9501 3.69835 53.6906 6.53316Z"
        fill="white"
        stroke="#F0ECFC"
        strokeWidth="8"
        strokeLinejoin="round"
      />
      <svg
        ref={ref}
        {...props}
        x="5"
        y="5"
        width="117"
        height="132"
        viewBox="0 0 117 132"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M80.7146 88.613H31.8096C30.7277 88.613 29.7539 87.7474 29.7539 86.5573V49.6622C29.7539 48.5802 30.6195 47.6064 31.8096 47.6064H46.9572C47.7146 47.6064 48.4719 48.0392 48.7965 48.7966L50.4195 52.3671C50.8523 53.3409 51.826 53.9901 52.908 53.9901H80.8228C81.9047 53.9901 82.8785 54.8556 82.8785 56.0458V86.6655C82.7703 87.7474 81.7965 88.613 80.7146 88.613Z"
          fill="#5F538F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M79.0918 86.7736H30.0787C28.9967 86.7736 28.0229 85.9081 28.0229 84.7179V47.8228C28.0229 46.7409 28.8885 45.7671 30.0787 45.7671H45.2262C45.9836 45.7671 46.741 46.1999 47.0656 46.9573L48.6885 50.5277C49.1213 51.5015 50.0951 52.1507 51.177 52.1507H79.0918C80.1738 52.1507 81.1475 53.0163 81.1475 54.2064V84.8261C81.0393 85.9081 80.1738 86.7736 79.0918 86.7736Z"
          fill="#7C74B1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.0788 86.7737H79.9575C80.6067 86.7737 81.2558 86.3409 81.364 85.6917L88.2886 61.8885C88.6132 60.9147 87.8558 59.9409 86.8821 59.9409H37.1116C36.4624 59.9409 35.8132 60.3737 35.705 61.0229L28.7804 84.8262C28.3476 85.7999 29.105 86.7737 30.0788 86.7737Z"
          fill="#9993E1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M88.721 84.0688C88.721 88.5048 85.1506 91.9671 80.8227 91.9671C76.4948 91.9671 72.9243 88.3966 72.9243 84.0688C72.9243 79.6327 76.4948 76.1704 80.8227 76.1704C85.1506 76.1704 88.721 79.6327 88.721 84.0688Z"
          fill="#E2D246"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M87.6395 83.2033C87.6395 87.6394 84.069 91.1017 79.7411 91.1017C75.3051 91.1017 71.8428 87.5312 71.8428 83.2033C71.8428 78.7672 75.4133 75.3049 79.7411 75.3049C84.069 75.3049 87.6395 78.8754 87.6395 83.2033Z"
          fill="#F0E44C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M84.3933 86.0164L81.5802 83.2032L84.3933 80.3901L82.554 78.5508L79.7408 81.3639L76.9277 78.5508L75.0884 80.3901L77.9015 83.2032L75.0884 86.0164L76.9277 87.8557L79.7408 85.0426L82.554 87.8557L84.3933 86.0164Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M80.3903 81.5803L82.987 78.9836L82.5542 78.5508L79.9575 81.1475L80.3903 81.5803Z"
          fill="#DDDCDC"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M80.3902 85.259L80.1738 85.4754L82.5542 87.8558L82.7705 87.6394L80.3902 85.259Z"
          fill="#DDDCDC"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M75.7376 86.2328L78.5507 83.4196L75.7376 80.6065L77.3605 78.9836L76.9277 78.5508L75.0884 80.3901L77.9015 83.2032L75.0884 86.0164L76.9277 87.8557L77.1441 87.6393L75.7376 86.2328Z"
          fill="#DDDCDC"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M79.7407 81.472L80.3899 81.6884L79.9571 81.2556L79.7407 81.472Z"
          fill="#DDDCDC"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M79.7407 85.0425L80.3899 85.2589L80.1735 85.4753L79.7407 85.0425Z"
          fill="#DDDCDC"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M75.0884 86.0164L75.7376 86.2328L77.1441 87.6393L76.9277 87.8557L75.0884 86.0164Z"
          fill="#424242"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M75.0884 80.3901L75.7376 80.6065L78.5507 83.4196L77.9015 83.2033L75.0884 80.3901Z"
          fill="#424242"
        />
      </svg>
    </svg>
  )
);
