import { IconButton, Tooltip } from '@hexa-ui/components';
import { Edit2 } from '@hexa-ui/icons';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { ActionsTypes } from '../../../../../../interfaces';
import { ApprovalButtons } from '../../../../../molecules/ApprovalButtons/ApprovalButtons';
import { EditRecommendation } from '../../../../../molecules/EditRecommendation/EditRecommendation';
import { ActionsDataProps } from '../Actions.types';

export const PendingRecommended: React.FC<ActionsDataProps[ActionsTypes.PENDING_RECOMMENDED]> = ({
  selected,
  callbackFn,
}) => {
  const { formatMessage } = useIntl();

  const [open, setOpen] = useState<boolean>(false);

  const onClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();

    setOpen(true);
  };

  return (
    <React.Fragment>
      <ApprovalButtons
        mode="single"
        type="icon-button"
        button={{ size: 'small' }}
        selected={selected}
        portal={{ approve: { has: true, container: document.body } }}
        callbackFn={callbackFn}
      />

      <EditRecommendation
        open={{ value: open, setter: setOpen }}
        portal={{ has: true, container: document.body }}
        data={selected}
        callbackFn={callbackFn}
        trigger={
          <IconButton
            id="edit-button"
            variant="tertiary"
            size="small"
            onClick={onClick}
            icon={() => (
              <Tooltip
                placement="bottom"
                text={formatMessage({ id: 'editRecommendation.buttons.tooltip' })}
              >
                <Edit2 size="medium" />
              </Tooltip>
            )}
          />
        }
      />
    </React.Fragment>
  );
};
