import { Tables } from '../../interfaces';

interface SelectedMapping {
  key: string;
  identifier: { key: string; mapkey: string };
}

export const keys: { [key: string]: string } = {
  cm_has_not_recommended: 'cm_has_not_recommended',
  cm_has_custom_views: 'cm_has_custom_views',
  cm_has_async_approval: 'cm_has_async_approval',
};

export const selectedMapping: { [key: string]: SelectedMapping } = {
  [Tables.PENDING]: {
    key: keys.cm_has_async_approval,
    identifier: { key: 'beesAccountId', mapkey: 'bees_account_id' },
  },
};
