import { File } from '../../../../hooks/useNotification';

export const adapterFile = (file): Omit<File, 'previousStatus'> => {
  if (!file) return null;

  const totalAnalysis = Number(file?.validated_analysis_count + file?.failed_analysis_count) || 0;

  return {
    id: file?.id ?? '',
    status: file?.status ?? '',
    referenceName: file?.reference_name ?? '',
    description: file?.description ?? '',
    total: file?.total_count ?? 0,
    totalAnalysis,
    validated: file?.validated_analysis_count ?? 0,
    failed: file?.failed_analysis_count ?? 0,
    processed: file?.processed_count ?? 0,
    analysisPercentage:
      file?.total_count > 0
        ? `${Number((totalAnalysis / file.total_count) * 100).toFixed()}%`
        : '0%',
    processedPercentage:
      file?.validated_analysis_count > 0
        ? `${Number((file?.processed_count / file.validated_analysis_count) * 100).toFixed()}%`
        : '0%',
  };
};