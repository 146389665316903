import {
  FileMultiUploader,
  Heading,
  Paragraph,
  TextArea,
  TextLink,
  UploadableFile,
} from '@hexa-ui/components';
import { AlertOctagon } from '@hexa-ui/icons';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useGetUserPreferences } from '../../../../hooks/useGetUserPreferences';
import SegmentService from '../../../../services/segment';
import { RulesAndConditions } from '../../../molecules/RulesAndConditions/RulesAndConditions';
import { useUpload } from '../Context';
import {
  DescriptionTextContainer,
  DescriptionToModal,
  ErrorMsgContainer,
  LabelContainer,
  TextAreaContainer,
  TextContainer,
  UploaderCard,
} from './styles';

export const Uploader: React.FC = () => {
  const { formatMessage } = useIntl();
  const [errorMessage, setErrorMessage] = useState('');

  const { files, description, uploaded, onClear, shouldDisplayError } = useUpload();
  const { fileId } = uploaded.value;

  const { configs, selected } = useGetUserPreferences();
  const { filesConfig } = configs;

  const hasFile = useMemo(() => {
    return files.value.some((file) => !file.errors.length);
  }, [files]);

  const onClean = useCallback(() => {
    SegmentService.paymentsButtonClicked(
      'X Icon',
      'File Uploader Remove File',
      selected?.vendorId,
      'Credit Management Files Upload Remove File',
      'CREDIT_MANAGEMENT_FILE_PAGE'
    );
    onClear();
  }, []);

  useEffect(() => {
    const validFormats = filesConfig.accept.split(',').map((ext) => ext.trim().toLowerCase());
    if (files?.value?.length === 0) {
      setErrorMessage(formatMessage({ id: 'pages.upload.uploader.errors.noFile' }));
    } else {
      const fileName = files?.value[0]?.name;
      const fileExtension = fileName.slice(fileName.lastIndexOf('.'));
      if (!validFormats.includes(fileExtension)) {
        setErrorMessage(formatMessage({ id: 'pages.upload.uploader.errors.invalidFormat' }));
      } else if (files?.value[0]?.size > filesConfig.size) {
        setErrorMessage(
          formatMessage(
            { id: 'pages.upload.uploader.errors.maxFileSize' },
            { maxFileSize: filesConfig.size / 1000000 }
          )
        );
      }
    }
  }, [files, filesConfig]);

  const onDrop = useCallback(
    (filesDroped: UploadableFile[]) => {
      files.setter(filesDroped);

      SegmentService.paymentsButtonClicked(
        'File Uploader',
        'File Uploader Button',
        selected?.vendorId,
        'Credit Management Files Upload File Inserted',
        'CREDIT_MANAGEMENT_FILE_PAGE'
      );
    },
    [selected]
  );

  return (
    <UploaderCard border="medium" elevated="small" data-testid="uploader-card">
      <TextContainer>
        <Heading size="H4">{formatMessage({ id: 'pages.upload.uploader.title' })}</Heading>
        <DescriptionToModal colortype="primary">
          {formatMessage({ id: 'pages.upload.uploader.rulesAndConditions.description' })}{' '}
          <RulesAndConditions
            data-testid="rules-and-conditions-modal"
            trigger={
              <TextLink css={{ fontSize: '16px' }}>
                {formatMessage({ id: 'pages.upload.uploader.rulesAndConditions.modalLink' })}
              </TextLink>
            }
          />
        </DescriptionToModal>
      </TextContainer>

      <FileMultiUploader
        accept={filesConfig.accept}
        maxFileSize={filesConfig.size}
        disabled={!!fileId}
        size="large"
        hasPreviewButton={false}
        title={formatMessage({ id: 'pages.upload.uploader.fileUploader.title' })}
        inputLabel={formatMessage({ id: 'pages.upload.uploader.fileUploader.label' })}
        message={formatMessage(
          { id: 'pages.upload.uploader.fileUploader.description' },
          { maxFileSize: filesConfig.size / 1000000 }
        )}
        value={files.value}
        onDrop={onDrop}
        onClean={onClean}
      />
      {shouldDisplayError.value && !hasFile && (
        <ErrorMsgContainer>
          <AlertOctagon size="tiny" />
          <Paragraph colortype="error" size="xsmall">
            {errorMessage}
          </Paragraph>
        </ErrorMsgContainer>
      )}
      <TextAreaContainer data-testid="text-area-container">
        <LabelContainer>
          <DescriptionTextContainer>
            <Paragraph size="small" weight="semibold">
              {formatMessage({ id: 'pages.upload.uploader.fileDescription.title' })}
            </Paragraph>

            <Paragraph size="small" colortype="secondary">
              {formatMessage({ id: 'pages.upload.uploader.fileDescription.subTitle' })}
            </Paragraph>
          </DescriptionTextContainer>
        </LabelContainer>

        <TextArea
          id="file-description"
          width={'100%'}
          optionalText=""
          characterCounter
          maxLength={150}
          disabled={!!fileId}
          value={description.value}
          placeholder={formatMessage({ id: 'pages.upload.uploader.fileDescription.description' })}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            description.setter(e.target.value)
          }
        />
      </TextAreaContainer>
    </UploaderCard>
  );
};
