import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  width: 100%;

  div[data-testid='progress-tracker-container'] {
    width: 100%;

    > ul {
      width: 100%;

      > li {
        flex: 1;
      }
    }
  }
`;
