import { createGenerateClassName, StylesProvider } from '@material-ui/core';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { AppProvider } from './providers';
import { routerConfig } from './routes/RouterConfig';
import { persistor, store } from './store/store';

export default function App() {
  const generateClassName = createGenerateClassName({
    productionPrefix: 'bees-hub-bees-fintech-credit-mfe',
    seed: 'bees-hub-bees-fintech-credit-mfe',
  });

  return (
    <StylesProvider generateClassName={generateClassName}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <AppProvider>
            <RouterProvider router={routerConfig} />
          </AppProvider>
        </PersistGate>
      </Provider>
    </StylesProvider>
  );
}
