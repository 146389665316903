import React from 'react';

export const NoConfigError = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(
  (props, ref) => (
    <svg
      ref={ref}
      {...props}
      data-testid="no-config-error-icon"
      width="200"
      height="200"
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5969_775)">
        <g opacity="0.3">
          <mask
            id="mask0_5969_775"
            style={{ maskType: 'luminance' }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="24"
            width="76"
            height="76"
          >
            <path
              d="M37.7777 99.4444C58.4577 99.4444 75.2221 82.68 75.2221 62C75.2221 41.32 58.4577 24.5555 37.7777 24.5555C17.0977 24.5555 0.333252 41.32 0.333252 62C0.333252 82.68 17.0977 99.4444 37.7777 99.4444Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask0_5969_775)">
            <path
              d="M37.7777 99.4444C58.4577 99.4444 75.2221 82.68 75.2221 62C75.2221 41.32 58.4577 24.5555 37.7777 24.5555C17.0977 24.5555 0.333252 41.32 0.333252 62C0.333252 82.68 17.0977 99.4444 37.7777 99.4444Z"
              fill="url(#paint0_linear_5969_775)"
            />
          </g>
        </g>
        <mask
          id="mask1_5969_775"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="4"
          y="-2"
          width="195"
          height="164"
        >
          <path
            d="M165.222 96.6667C165.222 132.222 136.333 161.111 100.778 161.111C65.2221 161.111 36.3332 132.222 36.3332 96.6667L4.6665 97.2223L17.3332 -1.66663H188.889L198.889 97.2223L165.222 96.6667Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask1_5969_775)">
          <mask
            id="mask2_5969_775"
            style={{ maskType: 'luminance' }}
            maskUnits="userSpaceOnUse"
            x="36"
            y="32"
            width="130"
            height="130"
          >
            <path
              d="M100.778 161.111C136.369 161.111 165.222 132.258 165.222 96.6667C165.222 61.0751 136.369 32.2223 100.778 32.2223C65.186 32.2223 36.3333 61.0751 36.3333 96.6667C36.3333 132.258 65.186 161.111 100.778 161.111Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask2_5969_775)">
            <path
              d="M100.778 161.111C136.369 161.111 165.222 132.258 165.222 96.6667C165.222 61.0751 136.369 32.2223 100.778 32.2223C65.186 32.2223 36.3333 61.0751 36.3333 96.6667C36.3333 132.258 65.186 161.111 100.778 161.111Z"
              fill="url(#paint1_linear_5969_775)"
            />
          </g>
        </g>
        <mask
          id="mask3_5969_775"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="4"
          y="-2"
          width="195"
          height="164"
        >
          <path
            d="M165.222 96.6667C165.222 132.222 136.333 161.111 100.778 161.111C65.2221 161.111 36.3332 132.222 36.3332 96.6667L4.6665 97.2223L17.3332 -1.66663H188.889L198.889 97.2223L165.222 96.6667Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask3_5969_775)">
          <path d="M61.7775 48H57.9998V51.5556H61.7775V48Z" fill="#FCA705" />
          <path
            d="M61.7775 48V50C61.222 50.2222 60.5553 50.2222 59.8886 50.2222C59.222 50.2222 58.5553 50.1111 57.9998 50V48H61.7775Z"
            fill="#E2E2E2"
          />
          <path
            d="M59.8885 49.6667C63.4477 49.6667 66.333 46.7814 66.333 43.2223C66.333 39.6631 63.4477 36.7778 59.8885 36.7778C56.3294 36.7778 53.4441 39.6631 53.4441 43.2223C53.4441 46.7814 56.3294 49.6667 59.8885 49.6667Z"
            fill="#F5B758"
          />
          <path
            d="M59.4441 41.2223C59.4441 42.6667 59.4441 44.0001 59.4441 45.4445C59.4441 46.0001 60.333 46.0001 60.333 45.4445C60.333 44.0001 60.333 42.6667 60.333 41.2223C60.333 40.5556 59.4441 40.5556 59.4441 41.2223Z"
            fill="#EEEEEE"
          />
          <path d="M148.555 48H144.777V51.5556H148.555V48Z" fill="#FCA705" />
          <path
            d="M148.666 48V50C148.111 50.2222 147.444 50.2222 146.778 50.2222C146.111 50.2222 145.444 50.1111 144.889 50V48H148.666Z"
            fill="#E2E2E2"
          />
          <path
            d="M146.777 49.6667C150.337 49.6667 153.222 46.7814 153.222 43.2223C153.222 39.6631 150.337 36.7778 146.777 36.7778C143.218 36.7778 140.333 39.6631 140.333 43.2223C140.333 46.7814 143.218 49.6667 146.777 49.6667Z"
            fill="#F5B758"
          />
          <path
            d="M146.222 41.2223C146.222 42.6667 146.222 44.0001 146.222 45.4445C146.222 46.0001 147.111 46.0001 147.111 45.4445C147.111 44.0001 147.111 42.6667 147.111 41.2223C147.222 40.5556 146.222 40.5556 146.222 41.2223Z"
            fill="#EEEEEE"
          />
          <path
            d="M151.666 157.556H141.778V56.0001C141.778 53.5557 143.778 51.5557 146.222 51.5557H147.222C149.666 51.5557 151.666 53.5557 151.666 56.0001V157.556Z"
            fill="#4D4D4D"
          />
          <path
            d="M64.8886 156.889H54.9998V55.3334C54.9998 52.8889 56.9998 50.8889 59.4442 50.8889H60.4442C62.8886 50.8889 64.8886 52.8889 64.8886 55.3334V156.889Z"
            fill="#4D4D4D"
          />
          <path
            d="M149.555 85.5556H53.9997C47.4442 85.5556 42.1108 80.2222 42.1108 73.6667V73.3333C42.1108 66.7778 47.4442 61.4445 53.9997 61.4445H149.555C156.111 61.4445 161.444 66.7778 161.444 73.3333V73.6667C161.444 80.2222 156.111 85.5556 149.555 85.5556Z"
            fill="#F2E600"
          />
          <path
            d="M67.5554 61.4445L59.6665 85.5556H74.9998L82.8887 61.4445H67.5554Z"
            fill="#222222"
          />
          <path
            d="M46.5553 61.4445C44.1108 61.4445 42.1108 63.7778 42.1108 66.7778V80.3334C42.1108 82.4445 43.1108 84.2222 44.6664 85.1111L52.4442 61.4445H46.5553Z"
            fill="#222222"
          />
          <path
            d="M98.1108 61.4445L90.2219 85.5556H105.444L113.333 61.4445H98.1108Z"
            fill="#222222"
          />
          <path
            d="M158.889 62L151.111 85.6667H156.889C159.333 85.6667 161.333 83.3333 161.333 80.3333V66.7778C161.444 64.6667 160.444 62.7778 158.889 62Z"
            fill="#222222"
          />
          <path
            d="M128.555 61.4445L120.667 85.5556H135.889L143.778 61.4445H128.555Z"
            fill="#222222"
          />
          <path
            d="M149.555 119.556H53.9997C47.4442 119.556 42.1108 114.222 42.1108 107.667V107.333C42.1108 100.778 47.4442 95.4445 53.9997 95.4445H149.555C156.111 95.4445 161.444 100.778 161.444 107.333V107.667C161.444 114.222 156.111 119.556 149.555 119.556Z"
            fill="#F2E600"
          />
          <path
            d="M67.5554 95.3334L59.6665 119.556H74.9998L82.8887 95.3334H67.5554Z"
            fill="#222222"
          />
          <path
            d="M46.5553 95.3334C44.1108 95.3334 42.1108 97.6667 42.1108 100.667V114.222C42.1108 116.333 43.1108 118.111 44.6664 119L52.4442 95.3334H46.5553Z"
            fill="#222222"
          />
          <path
            d="M98.1108 95.3334L90.2219 119.556H105.444L113.333 95.3334H98.1108Z"
            fill="#222222"
          />
          <path
            d="M158.889 95.8889L151.111 119.556H156.889C159.333 119.556 161.333 117.222 161.333 114.222V100.667C161.444 98.5556 160.444 96.7778 158.889 95.8889Z"
            fill="#222222"
          />
          <path
            d="M128.555 95.3334L120.667 119.556H135.889L143.778 95.3334H128.555Z"
            fill="#222222"
          />
          <path
            d="M146.889 104.889C147.441 104.889 147.889 104.441 147.889 103.889C147.889 103.337 147.441 102.889 146.889 102.889C146.336 102.889 145.889 103.337 145.889 103.889C145.889 104.441 146.336 104.889 146.889 104.889Z"
            fill="#808080"
          />
          <path
            d="M146.889 112C147.441 112 147.889 111.552 147.889 111C147.889 110.448 147.441 110 146.889 110C146.336 110 145.889 110.448 145.889 111C145.889 111.552 146.336 112 146.889 112Z"
            fill="#808080"
          />
          <path
            d="M146.666 71C147.219 71 147.666 70.5523 147.666 70C147.666 69.4477 147.219 69 146.666 69C146.114 69 145.667 69.4477 145.667 70C145.667 70.5523 146.114 71 146.666 71Z"
            fill="#808080"
          />
          <path
            d="M146.666 78C147.219 78 147.666 77.5523 147.666 77C147.666 76.4477 147.219 76 146.666 76C146.114 76 145.667 76.4477 145.667 77C145.667 77.5523 146.114 78 146.666 78Z"
            fill="#808080"
          />
          <path
            d="M59.8887 71C60.441 71 60.8887 70.5523 60.8887 70C60.8887 69.4477 60.441 69 59.8887 69C59.3364 69 58.8887 69.4477 58.8887 70C58.8887 70.5523 59.3364 71 59.8887 71Z"
            fill="#808080"
          />
          <path
            d="M59.8887 78C60.441 78 60.8887 77.5523 60.8887 77C60.8887 76.4477 60.441 76 59.8887 76C59.3364 76 58.8887 76.4477 58.8887 77C58.8887 77.5523 59.3364 78 59.8887 78Z"
            fill="#808080"
          />
          <path
            d="M59.7776 104.889C60.3299 104.889 60.7776 104.441 60.7776 103.889C60.7776 103.337 60.3299 102.889 59.7776 102.889C59.2253 102.889 58.7776 103.337 58.7776 103.889C58.7776 104.441 59.2253 104.889 59.7776 104.889Z"
            fill="#808080"
          />
          <path
            d="M59.7776 112C60.3299 112 60.7776 111.552 60.7776 111C60.7776 110.448 60.3299 110 59.7776 110C59.2253 110 58.7776 110.448 58.7776 111C58.7776 111.552 59.2253 112 59.7776 112Z"
            fill="#808080"
          />
          <path opacity="0.2" d="M64.8886 85.5557H54.9998V88.6668H64.8886V85.5557Z" fill="black" />
          <path opacity="0.2" d="M64.8886 119.556H54.9998V122.667H64.8886V119.556Z" fill="black" />
          <path opacity="0.2" d="M151.666 85.5557H141.778V88.6668H151.666V85.5557Z" fill="black" />
          <path opacity="0.2" d="M151.666 119.556H141.778V122.667H151.666V119.556Z" fill="black" />
        </g>
        <g opacity="0.5">
          <mask
            id="mask4_5969_775"
            style={{ maskType: 'luminance' }}
            maskUnits="userSpaceOnUse"
            x="162"
            y="46"
            width="23"
            height="23"
          >
            <path
              d="M173.889 68.6667C180.025 68.6667 185 63.6921 185 57.5556C185 51.4191 180.025 46.4445 173.889 46.4445C167.752 46.4445 162.778 51.4191 162.778 57.5556C162.778 63.6921 167.752 68.6667 173.889 68.6667Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask4_5969_775)">
            <path
              d="M173.889 68.6667C180.025 68.6667 185 63.6921 185 57.5556C185 51.4191 180.025 46.4445 173.889 46.4445C167.752 46.4445 162.778 51.4191 162.778 57.5556C162.778 63.6921 167.752 68.6667 173.889 68.6667Z"
              fill="url(#paint2_linear_5969_775)"
            />
          </g>
        </g>
        <g opacity="0.5">
          <mask
            id="mask5_5969_775"
            style={{ maskType: 'luminance' }}
            maskUnits="userSpaceOnUse"
            x="7"
            y="115"
            width="24"
            height="23"
          >
            <path
              d="M19 137.333C25.1365 137.333 30.1111 132.359 30.1111 126.222C30.1111 120.086 25.1365 115.111 19 115.111C12.8635 115.111 7.88892 120.086 7.88892 126.222C7.88892 132.359 12.8635 137.333 19 137.333Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask5_5969_775)">
            <path
              d="M19 137.333C25.1365 137.333 30.1111 132.359 30.1111 126.222C30.1111 120.086 25.1365 115.111 19 115.111C12.8635 115.111 7.88892 120.086 7.88892 126.222C7.88892 132.359 12.8635 137.333 19 137.333Z"
              fill="url(#paint3_linear_5969_775)"
            />
          </g>
        </g>
        <g opacity="0.5">
          <mask
            id="mask6_5969_775"
            style={{ maskType: 'luminance' }}
            maskUnits="userSpaceOnUse"
            x="106"
            y="86"
            width="90"
            height="90"
          >
            <path
              d="M151.111 175.556C175.719 175.556 195.667 155.607 195.667 131C195.667 106.393 175.719 86.4445 151.111 86.4445C126.504 86.4445 106.556 106.393 106.556 131C106.556 155.607 126.504 175.556 151.111 175.556Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask6_5969_775)">
            <path
              d="M151.111 175.556C175.719 175.556 195.667 155.607 195.667 131C195.667 106.393 175.719 86.4445 151.111 86.4445C126.504 86.4445 106.556 106.393 106.556 131C106.556 155.607 126.504 175.556 151.111 175.556Z"
              fill="url(#paint4_linear_5969_775)"
            />
          </g>
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M173 131C173 143.111 163.222 153 151 153C138.889 153 129 143.222 129 131C129 118.889 138.778 109 151 109C163.222 109.111 173 118.889 173 131Z"
          fill="#D85951"
        />
        <path
          opacity="0.16"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M161.778 139.111L156.333 133.667L161.778 128.222C163.222 126.778 163.222 124.444 161.778 123C160.333 121.556 158 121.556 156.556 123L151.111 128.444L145.667 123C144.222 121.556 141.889 121.556 140.444 123C139 124.444 139 126.778 140.444 128.222L145.889 133.667L140.444 139.111C139 140.556 139 142.889 140.444 144.333C141.889 145.778 144.222 145.778 145.667 144.333L151.111 138.889L156.556 144.333C158 145.778 160.333 145.778 161.778 144.333C163.222 142.778 163.222 140.556 161.778 139.111Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M161.778 136.889L156.333 131.444L161.778 126C163.222 124.556 163.222 122.222 161.778 120.778C160.333 119.333 158 119.333 156.556 120.778L151.111 126.222L145.667 120.778C144.222 119.333 141.889 119.333 140.444 120.778C139 122.222 139 124.556 140.444 126L145.889 131.444L140.444 136.889C139 138.333 139 140.667 140.444 142.111C141.889 143.556 144.222 143.556 145.667 142.111L151.111 136.667L156.556 142.111C158 143.556 160.333 143.556 161.778 142.111C163.222 140.667 163.222 138.333 161.778 136.889Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_5969_775"
          x1="0.416585"
          y1="62.0541"
          x2="75.2239"
          y2="62.0541"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFE0E4" />
          <stop offset="1" stopColor="#EEEFF1" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_5969_775"
          x1="36.3587"
          y1="96.6611"
          x2="165.23"
          y2="96.6611"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFE0E4" />
          <stop offset="1" stopColor="#EEEFF1" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_5969_775"
          x1="162.832"
          y1="57.526"
          x2="185.051"
          y2="57.526"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFE0E4" />
          <stop offset="1" stopColor="#EEEFF1" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_5969_775"
          x1="7.89903"
          y1="126.224"
          x2="30.1178"
          y2="126.224"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFE0E4" />
          <stop offset="1" stopColor="#EEEFF1" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_5969_775"
          x1="106.536"
          y1="131.043"
          x2="195.653"
          y2="131.043"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFE0E4" />
          <stop offset="1" stopColor="#EEEFF1" />
        </linearGradient>
        <clipPath id="clip0_5969_775">
          <rect width="200" height="200" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
);
