import { Alert, Button, Divider, Heading, Paragraph, Radio } from '@hexa-ui/components';
import { Check2 } from '@hexa-ui/icons';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useGetSources } from '../../../hooks/useGetSources';
import { useGetUserPreferences } from '../../../hooks/useGetUserPreferences';
import { Status } from '../../../interfaces';
import SegmentService from '../../../services/segment';
import { Modal } from '../Modal/Modal';
import { ApproveRecommendationProps } from './ApproveRecommendation.types';
import { ModalContent, RadioContainer, TextContainer } from './styles';

export const ApproveRecommendation: React.FC<ApproveRecommendationProps> = ({
  mode,
  data,
  trigger,
  open,
  portal,
  onApprove,
}) => {
  const { formatMessage } = useIntl();

  const { selected, language } = useGetUserPreferences();
  const { locale } = language;

  const { onboardingRule } = useGetSources();

  const [onboardingByPass, setOnboardingByPass] = useState<boolean>(undefined);

  const onRadio = (value: string) => setOnboardingByPass(value === 'doNotRequest');

  const onClear = () => {
    setOnboardingByPass(undefined);

    if (open?.value) open.setter(false);
  };

  useEffect(() => {
    if (!onboardingRule) {
      setOnboardingByPass(true);
    }
  }, []);

  const onClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    onClear();
  };

  const buttonClicked = (buttonLabel: string, buttonName: string, eventName: string) => {
    SegmentService.paymentsButtonClicked(
      buttonLabel,
      buttonName,
      selected?.vendorId,
      eventName,
      'CREDIT_MANAGEMENT_EDIT_RECOMMENDATION_MODAL'
    );
  };

  return (
    <Modal
      css={{ maxWidth: '720px' }}
      open={open}
      portal={portal}
      trigger={trigger}
      onClose={onClose}
      onInteractOutside={onClear}
      actions={{
        buttons: {
          cancel: (
            <Button id="on-back" size="medium" variant="secondary" onClick={onClose}>
              {formatMessage({ id: 'approveRecommendation.buttons.back' })}
            </Button>
          ),
          confirm: (
            <Button
              id="on-approve"
              leading={mode === 'single'}
              icon={Check2}
              size="medium"
              variant="primary"
              disabled={onboardingRule ? onboardingByPass === undefined : false}
              onClick={() => {
                onApprove(Status.APPROVED, onboardingByPass);

                buttonClicked(
                  'Yes, approve',
                  'Approve Recommendation Button',
                  onboardingByPass
                    ? 'Credit Management Bypass Onboarding'
                    : 'Credit Management Request Onboarding'
                );
              }}
            >
              {formatMessage({ id: 'approveRecommendation.buttons.approve' })}
            </Button>
          ),
        },
      }}
    >
      <ModalContent data-testid="modal-content" onClick={(e) => e.stopPropagation()}>
        <Heading size="H2" css={{ fontSize: '32px' }}>
          {formatMessage(
            { id: `approveRecommendation.title.${mode}` },
            { counter: data.counter?.toLocaleString(locale) }
          )}
        </Heading>

        <Paragraph size="basis">
          {formatMessage(
            { id: `approveRecommendation.description.${mode}` },
            { counter: data.counter?.toLocaleString(locale) }
          )}
        </Paragraph>

        {onboardingRule && (
          <React.Fragment>
            <Divider orientation="horizontal" />

            {mode === 'single' && (
              <Heading size="H4">{`${data.pocName} - ${data.accountId}`}</Heading>
            )}

            <TextContainer data-testid="text-container">
              <Heading size="H5">
                {formatMessage({ id: 'approveRecommendation.info.title' })}
              </Heading>

              <Paragraph size="small" style={{ color: '#757575' }}>
                {formatMessage({ id: 'approveRecommendation.info.description' })}
              </Paragraph>
            </TextContainer>

            <Radio.Root onValueChange={onRadio}>
              <RadioContainer>
                <Radio.Item
                  id="request"
                  value="request"
                  label={formatMessage({ id: 'approveRecommendation.radios.request' })}
                />

                <Radio.Item
                  id="doNotRequest"
                  value="doNotRequest"
                  label={formatMessage({ id: 'approveRecommendation.radios.doNotRequest' })}
                />
              </RadioContainer>
            </Radio.Root>

            {onboardingByPass && (
              <Alert
                type="warning"
                message={formatMessage({ id: 'approveRecommendation.warning' })}
                css={{ width: '100%' }}
              />
            )}
          </React.Fragment>
        )}
      </ModalContent>
    </Modal>
  );
};
