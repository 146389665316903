import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { SourceContext } from '../contexts';
import { MappedValidation, Operators, UseGetSource, ValidateProps } from '../interfaces';
import { useGetUserPreferences } from './useGetUserPreferences';

export const useGetSources = (): UseGetSource => {
  const { formatMessage } = useIntl();
  const { currency } = useGetUserPreferences();

  const { onboardingRule, validations } = useContext(SourceContext);
  const expressions: boolean[] = [];

  const formatted = (code: string, operator: Operators, value: number) => {
    switch (code) {
      case 'error:term:zero':
        return value + 1;
      case 'error:term:small':
        return value - 1;
      case 'error:term:fee:big':
        return value - 1;
      case 'error:term:fee:negative':
        return value;
      case 'error:limit:big':
        return value * 100;
      case 'error:limit:small':
        return currency.format(value);
      default:
        return value;
    }
  };

  const validate = ({
    lhs_term,
    operator,
    rhs_term,
    expression,
    config,
    field,
  }: ValidateProps): boolean => {
    if (field) expressions.length = 0;

    if (expression?.operator === 'TIMES') {
      return validate({
        lhs_term,
        operator,
        rhs_term: config.param * expression.rhs_term,
        expression: expression.expression,
        config,
      });
    }

    switch (operator) {
      case 'EQUAL':
        expressions.push(lhs_term === rhs_term);
        break;

      case 'GREATER_THAN':
        expressions.push(lhs_term > rhs_term);
        break;

      case 'LESS_THAN':
        expressions.push(lhs_term < rhs_term);
        break;

      case 'GREATER_THAN_OR_EQUAL':
        expressions.push(lhs_term >= rhs_term);
        break;

      case 'LESS_THAN_OR_EQUAL':
        expressions.push(lhs_term <= rhs_term);
        break;

      default:
        expressions.push(true);
    }

    if (expression) {
      return validate({
        lhs_term,
        operator: expression.operator,
        rhs_term: expression.rhs_term,
        expression: expression.expression,
        config,
      });
    }

    return expressions.every((isValid) => isValid);
  };

  const getValidation = (field: string, value?: number, param?: number): MappedValidation => {
    if (!validations?.length || !validations.find((validation) => validation.field === field)) {
      return { has: false, message: '' };
    }

    const { error_code, expression, description } = validations.find(
      (validation) => validation.field === field
    );

    return {
      has: !validate({
        lhs_term: value ?? 0,
        operator: expression.operator,
        rhs_term: expression.rhs_term,
        expression: expression.expression,
        config: { param },
        field,
      }),
      message: formatMessage(
        { id: `source.validations.errors.${error_code}`, defaultMessage: description },
        {
          value: formatted(
            error_code,
            expression.operator,
            parseInt(expression.rhs_term ?? expression.expression.rhs_term, 10)
          ),
        }
      ),
    };
  };

  return { onboardingRule, getValidation };
};
