import { useMemo } from 'react';
import { NoConfigError } from '../../components/icons/Error/NoConfig/NoConfig.svg';
import { Page } from '../../components/pages/Page/Page';
import { useHeader } from '../../hooks/useHeader';
import { ProviderProps } from '../../interfaces';
import { useSelected } from '../../store/hooks/selectedHook';

export const OptionsProvider: React.FC<ProviderProps> = ({ children }) => {
  const { countryOptions, vendorOptions } = useHeader();
  const { selected } = useSelected();

  const error = useMemo(() => {
    const countryPermission = countryOptions.countries?.some(
      (country) => country?.id === selected?.country
    );

    const vendorPermission = vendorOptions.vendors?.some(
      (vendor) => vendor?.id === selected?.vendorId
    );

    if (!countryPermission && !vendorPermission) return 'both';
    if (!countryPermission) return 'country';
    if (!vendorPermission) return 'vendor';

    return null;
  }, [selected.country, selected.vendorId]);

  if (error) {
    return (
      <Page.Error
        type="custom"
        icon={NoConfigError}
        title={`errors.custom.not-enabled.${error}.title`}
        description={`errors.custom.not-enabled.${error}.description`}
      />
    );
  }

  return children;
};
