import { FilterState } from '../../components/atoms/Table/Filter/Context/FilterContext.types';
import { OnUpdateByFilterParams } from '../../components/molecules/ApprovalButtons/ApprovalButtons.types';
import { serializer } from '../filter/serialize';

export interface MassSelectionPayload {
  recommended: boolean;
  tableStatus: string[];
  status: string;
  requestOnboarding: boolean;
  userId: string;
  filter?: FilterState;
  search?: string;
  exclusions?: {
    [x: string]: string;
  }[];
}

export function mappedMassSelectionPayload({
  recommended,
  tableStatus,
  status,
  requestOnboarding,
  userId,
  filter,
  search,
  exclusions,
}: MassSelectionPayload): OnUpdateByFilterParams {
  const params = filter && new URLSearchParams(serializer(filter).filters);
  const range = params?.get('range');
  const date = params?.get('date');
  const tag = params?.get('tag');

  const filterParams = {
    recommended: recommended,
    status: tableStatus.join(','),
    poc_or_id: search,
    range: range,
    date: date,
    tag: tag,
  };

  return {
    filter: filterParams,
    skip_bees_account_ids: exclusions,
    status,
    request_on_boarding: requestOnboarding,
    user_id: userId,
  };
}
