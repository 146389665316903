import { Dialog } from '@hexa-ui/components';
import styled from 'styled-components';
import { ButtonDirection } from './Modal.types';

export const Container = styled.div``;

export const Root = styled(Dialog.Root)`
  padding: 16px;
`;

export const Close = styled(Dialog.Close)``;

export const ButtonContainer = styled.div<{ $direction: ButtonDirection }>`
  display: flex;
  align-items: center;
  justify-content: ${({ $direction }) => $direction};
  flex-direction: row;

  width: 100%;
  column-gap: 12px;
`;
