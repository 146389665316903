import React from 'react';

export const Done = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>((props, ref) => (
  <svg
    ref={ref}
    {...props}
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="done-icon"
  >
    <g clipPath="url(#clip0_6853_12576)">
      <path
        d="M28 52.5C41.531 52.5 52.5 41.531 52.5 28C52.5 14.469 41.531 3.5 28 3.5C14.469 3.5 3.5 14.469 3.5 28C3.5 41.531 14.469 52.5 28 52.5Z"
        fill="url(#paint0_linear_6853_12576)"
      />
      <g clipPath="url(#clip1_6853_12576)">
        <path
          opacity="0.16"
          d="M42.7175 51.3975L19.5387 47.5125C14.3238 46.6375 10.5 42.1225 10.5 36.8288V15.2425L10.5262 7.8225C10.5262 6.88625 11.2875 6.125 12.2325 6.125H42.9975C43.9425 6.125 44.7038 6.88625 44.7038 7.83125V49.7175C44.7038 50.7675 43.7588 51.5725 42.7175 51.3975Z"
          fill="black"
        />
        <path
          d="M42.7175 49.6475L19.5387 45.7625C14.3238 44.8875 10.5 40.3725 10.5 35.0788V13.4925L10.5262 6.0725C10.5262 5.13625 11.2875 4.375 12.2325 4.375H42.9975C43.9425 4.375 44.7038 5.13625 44.7038 6.08125V47.9675C44.7038 49.0175 43.7588 49.8225 42.7175 49.6475Z"
          fill="url(#paint1_linear_6853_12576)"
        />
        <path
          d="M15.0938 12.2062C15.0938 11.8912 15.3475 11.6375 15.6625 11.6375H39.6025C39.9175 11.6375 40.1713 11.8912 40.1713 12.2062C40.1713 12.5212 39.9175 12.775 39.6025 12.775H15.6625C15.3475 12.775 15.0938 12.5212 15.0938 12.2062ZM15.6625 17.08H39.6025C39.9175 17.08 40.1713 16.8262 40.1713 16.5112C40.1713 16.1962 39.9175 15.9425 39.6025 15.9425H15.6625C15.3475 15.9425 15.0938 16.1962 15.0938 16.5112C15.0938 16.8262 15.3475 17.08 15.6625 17.08ZM15.6625 21.385H39.6025C39.9175 21.385 40.1713 21.1312 40.1713 20.8162C40.1713 20.5012 39.9175 20.2474 39.6025 20.2474H15.6625C15.3475 20.2474 15.0938 20.5012 15.0938 20.8162C15.0938 21.1312 15.3475 21.385 15.6625 21.385ZM15.6625 25.69H39.6025C39.9175 25.69 40.1713 25.4362 40.1713 25.1212C40.1713 24.8062 39.9175 24.5524 39.6025 24.5524H15.6625C15.3475 24.5524 15.0938 24.8062 15.0938 25.1212C15.0938 25.4362 15.3475 25.69 15.6625 25.69ZM15.6625 29.9949H32.6025C32.9175 29.9949 33.1713 29.7412 33.1713 29.4262C33.1713 29.1112 32.9175 28.8575 32.6025 28.8575H15.6625C15.3475 28.8575 15.0938 29.1112 15.0938 29.4262C15.0938 29.7412 15.3475 29.9949 15.6625 29.9949Z"
          fill="#4D4D4D"
        />
        <path
          opacity="0.24"
          d="M43.4437 49.0962L25.655 37.6687C24.745 37.1175 23.5462 37.6337 23.3712 38.675C22.96 41.0725 21.07 44.9837 17.0275 44.9837L17.9725 45.3162C18.5762 45.5262 19.2062 45.6837 19.845 45.7712L43.2512 49.6125C43.5575 49.665 43.7062 49.2537 43.4437 49.0962Z"
          fill="black"
        />
        <path
          d="M43.4437 49.0962L26.0487 38.5525C25.13 38.0012 23.94 38.5262 23.7562 39.585C23.3362 41.9825 22.26 45.4387 18.235 45.4387L19.635 45.78C19.915 45.85 20.195 45.9025 20.4837 45.955L43.26 49.63C43.5662 49.6825 43.715 49.2712 43.4525 49.1137L43.4437 49.0962Z"
          fill="url(#paint2_linear_6853_12576)"
        />
      </g>
      <path
        opacity="0.16"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.25 46.375C19.25 51.208 15.335 55.125 10.5044 55.125C5.67377 55.125 1.75 51.2167 1.75 46.375C1.75 41.5333 5.66505 37.625 10.4956 37.625C15.3262 37.625 19.2413 41.542 19.2413 46.375H19.25Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.25 45.5C19.25 50.333 15.335 54.25 10.5044 54.25C5.67377 54.25 1.75 50.3417 1.75 45.5C1.75 40.6583 5.66505 36.75 10.4956 36.75C15.3262 36.75 19.2413 40.667 19.2413 45.5H19.25Z"
        fill="#54AE52"
      />
      <path
        d="M5.62844 47.4142L9.00594 50.7917C9.23344 51.0192 9.60969 51.0192 9.83719 50.7917L15.3234 45.1217C15.8309 44.5967 15.8222 43.7567 15.2972 43.2492C14.7722 42.7417 13.9322 42.7504 13.4247 43.2754L9.39969 47.4492L7.50969 45.5592C6.99344 45.0429 6.15344 45.0429 5.63719 45.5592C5.12094 46.0754 5.12094 46.9154 5.63719 47.4317L5.62844 47.4142Z"
        fill="black"
        fillOpacity="0.16"
      />
      <path
        d="M5.62844 46.5392L9.00594 49.9167C9.23344 50.1442 9.60969 50.1442 9.83719 49.9167L15.3234 44.2467C15.8309 43.7217 15.8222 42.8817 15.2972 42.3742C14.7722 41.8667 13.9322 41.8754 13.4247 42.4004L9.39969 46.5742L7.50969 44.6842C6.99344 44.1679 6.15344 44.1679 5.63719 44.6842C5.12094 45.2004 5.12094 46.0404 5.63719 46.5567L5.62844 46.5392Z"
        fill="white"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_6853_12576"
        x1="3.5"
        y1="28"
        x2="52.5"
        y2="28"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DFE0E4" />
        <stop offset="1" stopColor="#EEEFF1" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_6853_12576"
        x1="10.5"
        y1="27.02"
        x2="44.7038"
        y2="27.02"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D3E1F6" />
        <stop offset="1" stopColor="#EFF4FC" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_6853_12576"
        x1="19.4687"
        y1="41.09"
        x2="43.2687"
        y2="49.0175"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D4E1FF" />
        <stop offset="1" stopColor="#4F93DB" />
      </linearGradient>
      <clipPath id="clip0_6853_12576">
        <rect width="56" height="56" fill="white" />
      </clipPath>
      <clipPath id="clip1_6853_12576">
        <rect width="34.2038" height="47.04" fill="white" transform="translate(10.5 4.375)" />
      </clipPath>
    </defs>
  </svg>
));
