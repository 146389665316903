import { useEffect, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { UploadPage as Page } from '../../components/pages';
import { useHeader } from '../../hooks/useHeader';

export const UploadPage: React.FC = () => {
  const { pathname } = useLocation();
  const { fileId, fileName } = useParams();

  const { setPageHeader } = useHeader();

  const isProgress = useMemo(
    () => pathname.includes('progress') && fileId && fileName,
    [pathname, fileId, fileName]
  );

  useEffect(() => {
    setPageHeader({
      page: isProgress ? 'progress' : 'upload',
      attributes: { pageTitle: fileName },
    });
  }, [isProgress, fileName]);

  return <Page />;
};
