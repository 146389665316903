import { Divider, Heading, Paragraph, TextButton } from '@hexa-ui/components';
import { Edit2 } from '@hexa-ui/icons';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useFormatting } from '../../../../hooks/useFormatting';
import { ApprovalMethod } from '../../../../interfaces';
import { StatusLabel } from '../../../atoms/StatusLabel';
import { EditRecommendation } from '../../../molecules/EditRecommendation/EditRecommendation';
import { Subheader } from '../styles';
import { ConditionsCardProps, FieldsMapping } from './Conditions.types';
import { Card, HeaderContainer, HeaderContent, InfoContainer, InfoContent } from './styles';

export const Conditions: React.FC<ConditionsCardProps> = ({
  data,
  conditions,
  onCallback,
  onOpenEditRecommendationModal,
}) => {
  const { formatMessage } = useIntl();

  const { formatting } = useFormatting();

  const fieldsMapping: FieldsMapping = useMemo(() => {
    return {
      first: {
        creditLimit: { type: 'currency', value: conditions?.creditLimit },
        limitAvailable: { type: 'currency', value: conditions?.limitAvailable },
        balance: { type: 'currency', value: conditions?.balance },
        overdue: { type: 'currency', value: conditions?.overdue },
        termFee: { type: 'term-fee', value: conditions?.termFee },
        paymentMethod: { type: 'payment-method', value: conditions?.paymentMethod },
      },
      second: {
        activatedOn: { type: 'datetime', value: conditions?.activatedOn },
        approvedBy: { type: 'text', value: conditions?.approvedBy },
        approvalMethod: { type: 'approval-method', value: conditions?.approvalMethod },
        fileName: {
          type: 'text',
          value: conditions?.fileName,
          notShow: conditions?.approvalMethod !== ApprovalMethod.FILE_BATCH_UPDATE,
        },
        fileDescription: {
          type: 'text',
          value: conditions?.fileDescription,
          notShow: conditions?.approvalMethod !== ApprovalMethod.FILE_BATCH_UPDATE,
        },
      },
    };
  }, [conditions]);

  if (!conditions) {
    return null;
  }

  return (
    <Card border="large" elevated="large">
      <HeaderContainer>
        <HeaderContent>
          <Heading size="H3">{formatMessage({ id: 'pages.poc-details.conditions.title' })}</Heading>

          <StatusLabel status={conditions?.status} />
        </HeaderContent>

        <EditRecommendation
          conditions
          data={data}
          callbackFn={onCallback}
          onOpenModal={onOpenEditRecommendationModal}
          trigger={
            <TextButton
              size="medium"
              iconPosition="leading"
              icon={Edit2}
              css={{ color: '#0F7FFA' }}
            >
              {formatMessage({ id: 'editRecommendation.buttons.edit.conditions' })}
            </TextButton>
          }
        />
      </HeaderContainer>

      <InfoContainer>
        {Object.entries(fieldsMapping.first).map(([key, attributes]) => {
          const { formatted } = formatting({
            type: attributes.type,
            value: attributes.value,
            config: { datetimeType: 'time' },
          });

          return (
            <InfoContent key={key}>
              <Subheader size="H5">
                {formatMessage({ id: `pages.poc-details.conditions.labels.${key}` })}
              </Subheader>

              <Paragraph size="basis">{formatted.completed || '-'}</Paragraph>
            </InfoContent>
          );
        })}
      </InfoContainer>

      <Divider orientation="horizontal" />

      <InfoContainer>
        {Object.entries(fieldsMapping.second).map(([key, attributes]) => {
          if (attributes?.notShow) return null;

          const { formatted } = formatting({
            type: attributes.type,
            value: attributes.value,
            config: { datetimeType: 'time' },
          });

          return (
            <InfoContent key={key}>
              <Heading size="H5">
                {formatMessage({ id: `pages.poc-details.conditions.labels.${key}` })}
              </Heading>

              <Paragraph size="basis">{formatted.completed || '-'}</Paragraph>
            </InfoContent>
          );
        })}
      </InfoContainer>
    </Card>
  );
};
