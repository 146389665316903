import { useEffect, useRef, useState } from 'react';
import { MAX_ATTEMPTS } from '../constants';
import { FileStatus, UseDownload } from '../interfaces';
import {
  downloadAssessments,
  getProgress,
  downloadFileFromBackend as serviceDownloadFileFromBackend,
} from '../services';
import { useGetUserPreferences } from './useGetUserPreferences';
import { useRequester } from './useRequester';
import { useToast } from './useToast';

export const useDownload = (): UseDownload => {
  const { toast } = useToast();
  const { get, downloadFileFromBackend } = useRequester();

  const { configs } = useGetUserPreferences();
  const { filesConfig } = configs;

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const interval = useRef<NodeJS.Timeout | null>(null);
  const attempts = useRef<number>(0);

  const onPooling = async (fileId: string, callbackFn: () => void) => {
    if (!fileId) {
      toast.error({ message: 'errors.500.title' });
      setIsLoading(false);

      callbackFn();
      return;
    }

    const intervalId = setInterval(async () => {
      const response = await getProgress({
        api: { get },
        params: { fileId },
      });

      if (!response?.success || response.data?.status === FileStatus.FAILED) {
        if (attempts.current === MAX_ATTEMPTS || response.data?.status === FileStatus.FAILED) {
          clearInterval(intervalId);

          toast.error({ message: 'errors.500.title' });
          setIsLoading(false);

          callbackFn();
          return;
        }

        attempts.current += 1;
      }

      if (response.data?.status === FileStatus.DONE) {
        attempts.current = 0;
        clearInterval(intervalId);

        const response = await serviceDownloadFileFromBackend({
          api: { downloadFileFromBackend },
          params: { fileId, filesConfig },
        });

        if (!response?.success) {
          toast.error({ message: 'errors.500.title' });
          setIsLoading(false);

          callbackFn();
          return;
        }

        setIsLoading(false);
        callbackFn();
      }

      interval.current = intervalId;
    }, 1000);
  };

  const getDownload = async (status: string[], callbackFn: () => void) => {
    toast.info({ message: 'files.modal.toasts.info' });
    setIsLoading(true);

    const response = await downloadAssessments({
      api: { get },
      params: { status: status.join(','), filesConfig },
    });

    if (!response?.success) {
      toast.error({ message: 'errors.500.title' });
      setIsLoading(false);

      callbackFn();
      return;
    }

    await onPooling(response.data?.id, callbackFn);
  };

  useEffect(() => {
    return () => {
      attempts.current = 0;

      if (interval.current) clearInterval(interval.current);
    };
  }, []);

  return { isLoading, getDownload };
};
