import { TypeToast, useToast as useBeesOneToast } from 'admin-portal-shared-services';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';

interface ToastTypeProps {
  type: TypeToast;
}

interface Action {
  name: string;
  action: () => void;
}

interface ToastProps {
  message: string;
  attributes?: Record<string, string | number>;
  firstAction?: Action;
}

export const useToast = () => {
  const { formatMessage } = useIntl();
  const beesOneToast = useBeesOneToast();

  const notify = useCallback(
    ({ type }: ToastTypeProps) =>
      ({ message, attributes, firstAction }: ToastProps) =>
        beesOneToast.notify({
          type,
          message: formatMessage({ id: message }, attributes),
          actions: firstAction
            ? [
                {
                  name: formatMessage({ id: firstAction?.name }),
                  action: firstAction?.action,
                },
              ]
            : undefined,
        }),
    []
  );

  const toast = {
    info: notify({ type: TypeToast.INFO }),
    success: notify({ type: TypeToast.SUCCESS }),
    warning: notify({ type: TypeToast.WARNING }),
    error: notify({ type: TypeToast.ERROR }),
  };

  return { toast };
};
