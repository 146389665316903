import { Badge, Button, Divider, Paragraph } from '@hexa-ui/components';
import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useGetUserPreferences } from '../../../../../../hooks/useGetUserPreferences';
import { FilterProps } from '../../../../../../interfaces';
import SegmentService from '../../../../../../services/segment';
import { processFilters } from '../../../../../../utils/filter/processFilters';
import { ClearAllButton } from '../../../../ClearAllButton/ClearAllButton';
import { useFilter } from '../../Context/FilterContext';
import { FilterOptions } from '../Options/FilterOptions';
import { FilterCardProps } from './FilterCard.types';
import {
  FilterCardContainer,
  FilterContent,
  FilterFooter,
  FilterOptionsContainer,
  FooterContent,
  Item,
  ItemContent,
  List,
  Menu,
} from './styles';

export const FilterCard: React.FC<FilterCardProps> = ({ filters, onFilterFn, onClearFn }) => {
  const { formatMessage } = useIntl();

  const { table, filtered, onCancel, onApply } = useFilter();
  const { selected: selectedHeader } = useGetUserPreferences();

  const [selected, setSelected] = useState<FilterProps>(filters[0]);

  const direction = useMemo(() => {
    return selected.type === 'checkbox' ? 'column' : 'row';
  }, [selected]);

  const hasFiltered = useMemo(() => {
    return Object.keys(filtered.value).length > 0;
  }, [filtered.value]);

  const onSelectFilter = (filter: FilterProps) => setSelected(filter);

  const onFilter = () => {
    onApply({ state: filtered.value, onFilterFn, onClearFn });

    const filterValues = processFilters(filtered.value);

    SegmentService.paymentsButtonClicked(
      'Apply',
      'Apply filter',
      selectedHeader.vendorId,
      'Credit Management Filter Applied',
      `CREDIT_MANAGEMENT_${table.toUpperCase()}_TAB`,
      filterValues
    );
  };

  return (
    <FilterCardContainer elevated="small" border="small">
      <FilterContent>
        <Menu data-testid="filter-menu">
          <List>
            {filters.map((filter) => (
              <Item
                key={filter.identifier}
                id={filter.identifier}
                $active={selected.name === filter.name}
                variant="navigation"
                onClick={() => onSelectFilter(filter)}
              >
                <ItemContent $hascounter={Boolean(filtered.counter[filter.identifier])}>
                  {formatMessage({ id: filter.name })}

                  {Boolean(filtered.counter[filter.identifier]) && (
                    <Badge.Counter
                      counterLabel={filtered.counter[filter.identifier]}
                      size="large"
                    />
                  )}
                </ItemContent>
              </Item>
            ))}
          </List>
        </Menu>

        <FilterOptionsContainer $direction={direction} data-testid="filter-options-container">
          {selected.title && (
            <Paragraph size="basis" weight="semibold">
              {formatMessage({ id: selected.title })}
            </Paragraph>
          )}

          {selected.input.map((input) => (
            <FilterOptions
              key={input.identifier}
              id={selected.identifier}
              type={selected.type}
              input={input}
              filtered={filtered}
            />
          ))}
        </FilterOptionsContainer>
      </FilterContent>

      <Divider orientation="horizontal" css={{ width: '100%' }} />

      <FilterFooter $hasfiltered={hasFiltered} data-testid="filter-footer">
        {hasFiltered && <ClearAllButton />}

        <FooterContent>
          <Button id="cancel-button" variant="secondary" onClick={onCancel}>
            {formatMessage({ id: 'tables.filters.buttons.cancel' })}
          </Button>

          <Button id="filter-button" variant="primary" disabled={!hasFiltered} onClick={onFilter}>
            {formatMessage({ id: 'tables.filters.buttons.apply' })}
          </Button>
        </FooterContent>
      </FilterFooter>
    </FilterCardContainer>
  );
};
