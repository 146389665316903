import { Paragraph } from '@hexa-ui/components';
import styled from 'styled-components';

export const CustomParagraph = styled(Paragraph)<{
  $size: number;
  $hasellipsis?: boolean;
}>`
  max-width: ${({ $size }) => `calc((100vw * ${$size / 100}) - 50px)`};

  ${({ $hasellipsis }) =>
    $hasellipsis && `text-overflow: ellipsis; white-space: nowrap; overflow: hidden;`};
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  column-gap: 24px;
`;
