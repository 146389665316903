import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useNotification } from '../../../hooks/useNotification';
import { useRequester } from '../../../hooks/useRequester';
import { getFiles } from '../../../services';
import { adapterFile } from './AdapterFile';
import { handleFileStatus } from './utils';

export const SSENotification: React.FC = () => {
  const { get } = useRequester();
  const { pathname } = useLocation();

  const { notify } = useNotification();

  const getData = async () => {
    const response = await getFiles({
      api: { get },
      queryParams: '&status=ANALYZING,ANALYZED,PROCESSING,DONE,FAILED',
    });

    if (!response?.success || !response.data[0]) {
      return;
    }

    const latestFile = response.data[0];
    const file = adapterFile(latestFile);
    handleFileStatus(file, notify);
  };

  useEffect(() => {
    getData();
  }, [pathname]);

  return null;
};
