import styled from 'styled-components';

export const ProgressBarContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid var(--border-secondary, #1414141f);
  border-radius: 16px;
  padding: 16px;
  gap: 12px;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 4px;
`;

export const BarContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 8px;
`;

export const GreyBar = styled.div`
  height: 8px;
  background-color: #14141414;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
`;

export const BlueBar = styled.div<{ $percentage: string }>`
  height: 100%;
  width: ${({ $percentage }) => $percentage};
  background-color: #006edb;
  border-radius: 10px;
  transition: width 0.5s ease-in-out;
`;
