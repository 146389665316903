import { Button, Divider, Paragraph, Tooltip } from '@hexa-ui/components';
import { Info } from '@hexa-ui/icons';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { completed, files, fixed, inprogress, keys, pending } from '../../../constants';
import { useGetUserPreferences } from '../../../hooks/useGetUserPreferences';
import { useToast } from '../../../hooks/useToast';
import { AssessmentTag, MappedView, Tables, TablesMapping } from '../../../interfaces';
import { useViews } from '../../../store/hooks/customViewsHook';
import { Input } from '../../atoms/Input/Input';
import { Page } from '../Page/Page';
import {
  ButtonsContainer,
  Card,
  CheckboxItem,
  CheckboxList,
  Columns,
  Container,
  Content,
  InputContainer,
  Item,
  ItemContainer,
  Radio,
  RadioContainer,
} from './styles';

export const ViewsPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const { toast } = useToast();
  const { toggles } = useGetUserPreferences();

  const { mode, table, index } = useParams();
  const { standard, custom, views } = useViews(table as Tables);

  const tablesMapping: TablesMapping = {
    [Tables.PENDING]: pending,
    [Tables.INPROGRESS]: inprogress,
    [Tables.COMPLETED]: completed,
    [Tables.FILES]: files,
  };

  const editView = useMemo(
    () => (mode === 'edit' ? custom[Number(index)] : null),
    [mode, custom, index]
  );

  const [name, setName] = useState<string>(editView?.name ?? '');
  const [tag, setTag] = useState<AssessmentTag>(editView?.tag ?? AssessmentTag.RECOMMENDED);
  const [selected, setSelected] = useState<string[]>(editView?.columns ?? fixed[table]);

  const isEqual = (array1: string[], array2: string[]) => {
    if (array1.length !== array2.length) return false;

    const sorted1 = array1.slice().sort((a: string, b: string) => a.localeCompare(b));
    const sorted2 = array2.slice().sort((a: string, b: string) => a.localeCompare(b));

    return sorted1.every((value, index) => value === sorted2[index]);
  };

  const columns = useMemo(
    () =>
      tablesMapping[table].columns
        .map(({ accessor }) => accessor)
        .filter(
          (accessor) => accessor !== 'recommendation' || tag !== AssessmentTag.NOT_RECOMMENDED
        )
        .filter((accessor) => !fixed[table].includes(accessor)),
    [tag, tablesMapping, table]
  );

  const hasChanges = useMemo(() => {
    if (mode === 'create') return true;

    return (
      editView?.name !== name || editView?.tag !== tag || !isEqual(editView?.columns, selected)
    );
  }, [name, tag, selected, isEqual, editView]);

  const disabled = useMemo(
    () => !name || !tag || selected.length <= fixed[table].length || !hasChanges,
    [name, tag, selected, hasChanges]
  );

  const maxLength = useMemo(
    () => selected.length - fixed[table].length === 6,
    [selected, fixed, table]
  );

  const onName = ({ value }) => setName(value);
  const onRadio = (value: AssessmentTag) => {
    setTag(value);

    setSelected(fixed[table]);
  };

  const onChecked = ({ formatted }) =>
    setSelected((prev) => {
      if (prev.includes(formatted.simple)) {
        return prev.filter((item) => item !== formatted.simple);
      }

      return [...prev, formatted.simple];
    });

  const onBack = () => navigate('/bees-credit-management/credit');
  const onSave = useCallback(() => {
    if (mode === 'edit') {
      views.edit(Number(index), { name, tag, columns: selected });
    } else {
      views.create({ name, tag, columns: selected });
    }

    toast.success({ message: 'pages.views.toasts.success', attributes: { name } });
    onBack();
  }, [mode, index, name, tag, selected]);

  useEffect(() => {
    if (maxLength) toast.warning({ message: 'pages.views.toasts.warning' });
  }, [maxLength]);

  if ((mode === 'edit' && !editView) || !columns.length) {
    return <Navigate to="/bees-credit-management/credit" />;
  }

  if (!toggles.has(keys.cm_has_custom_views)) {
    return (
      <Page.Error
        type="custom"
        title={'errors.custom.something-went-wrong.title'}
        description={'errors.custom.something-went-wrong.description'}
        button={{ has: true, text: 'errors.custom.buttons.reload' }}
      />
    );
  }

  return (
    <Container data-testid="views-page-container">
      <Card border="medium" elevated="medium">
        <Paragraph size="basis">{formatMessage({ id: 'pages.views.description' })}</Paragraph>

        <InputContainer data-testid="input-container">
          <Input.Text
            counter
            id="name"
            format="text"
            size="large"
            width="45%"
            label={formatMessage({ id: 'pages.views.inputs.name.label' })}
            placeholder={formatMessage({ id: 'pages.views.inputs.name.placeholder' })}
            maxLength={40}
            value={name}
            onChange={onName}
          />

          <RadioContainer data-testid="radio-container">
            <label id="radio-label">{formatMessage({ id: 'pages.views.inputs.type.label' })}</label>

            <Radio onValueChange={onRadio}>
              {standard.map((view: MappedView) => (
                <ItemContainer key={view.tag}>
                  <Item
                    id={view.tag}
                    value={view.tag}
                    checked={tag === view.tag}
                    label={formatMessage({
                      id: `pages.views.inputs.type.options.${view.tag}.label`,
                    })}
                  />

                  <Tooltip
                    placement="bottom"
                    text={formatMessage({
                      id: `pages.views.inputs.type.options.${view.tag}.tooltip`,
                    })}
                  >
                    <Info size="medium" />
                  </Tooltip>
                </ItemContainer>
              ))}
            </Radio>
          </RadioContainer>
        </InputContainer>

        <Columns data-testid="columns-container">
          <Content data-testid="columns-content">
            <Paragraph size="basis">
              {formatMessage({ id: 'pages.views.columns.select.description' })}
            </Paragraph>

            <CheckboxList data-testid="checkbox-list">
              {columns.map((column: string, index: number) => (
                <CheckboxItem key={column}>
                  <Input.Checkbox
                    id={column}
                    size="medium"
                    name={column}
                    value={column}
                    checked={selected.includes(column)}
                    disabled={
                      (maxLength && !selected.includes(column)) || fixed[table].includes(column)
                    }
                    label={formatMessage({ id: `tables.${table}.columns.${column}` })}
                    onChange={onChecked}
                  />

                  {index < columns.length - 1 && <Divider orientation="horizontal" />}
                </CheckboxItem>
              ))}
            </CheckboxList>
          </Content>
        </Columns>
      </Card>

      <ButtonsContainer data-testid="buttons-container">
        <Button id="back-button" size="large" variant="secondary" onClick={onBack}>
          {formatMessage({ id: 'pages.views.buttons.back' })}
        </Button>

        <Button
          id="save-button"
          size="large"
          variant="primary"
          disabled={disabled}
          onClick={onSave}
        >
          {formatMessage({ id: 'pages.views.buttons.save' })}
        </Button>
      </ButtonsContainer>
    </Container>
  );
};
