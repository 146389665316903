import styled from 'styled-components';

export const OuterButtonsContainer = styled.div<{ $alignend: boolean }>`
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: ${({ $alignend }) => ($alignend ? 'flex-end' : 'space-between')};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;

  width: 70%;
  column-gap: 12px;
`;
