import { Heading, Paragraph, ProgressTracker } from '@hexa-ui/components';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useGetUserPreferences } from '../../../../../hooks/useGetUserPreferences';
import { IsCompletedProps, Step, StepData, TimelineItem, TimelineProps } from './Timeline.types';
import { Container } from './styles';

export const Timeline: React.FC<TimelineProps> = ({ history, byPass }) => {
  const { formatMessage } = useIntl();

  const { configs, datetime } = useGetUserPreferences();
  const { features } = configs;
  const { timeline } = features['poc-details'];

  const isCompleted = ({ config, history }: IsCompletedProps) => {
    if (config.type === 'OR') {
      return config.status.some((s: string) => history.some((item) => item.status === s));
    }

    return config.status.every((s: string) => history.some((item) => item.status === s));
  };

  const { currentStep, steps } = useMemo((): StepData => {
    const completedSteps: string[] = [];

    const firstStep = timeline.find((item: TimelineItem) => item.name === 'APPROVED')?.config;

    const lastApprovedDate = history.reduce((latest, item) => {
      return firstStep.status.includes(item.status) && item.date > latest ? item.date : latest;
    }, '');

    const filteredTimeline = timeline.filter(
      (item: TimelineItem) => !(byPass && item.name === 'ONBOARDING')
    );

    const steps: Step[] = filteredTimeline.reduce((acc: Step[], { name, config }: TimelineItem) => {
      const filteredHistory = history.filter(
        (item) => config.status.includes(item.status) && item.date >= lastApprovedDate
      );

      if (isCompleted({ config, history: filteredHistory })) completedSteps.push(name);

      const completed = completedSteps.some((item) => item === name);
      const date = filteredHistory.reduce((latest, item) => {
        return item.date > latest ? item.date : latest;
      }, '');

      acc.push({ name, date, completed, disabled: false });
      return acc;
    }, []);

    const stepIndex = steps.findIndex((step: Step) => !step.completed);
    const currentStep: number = stepIndex === -1 ? steps.length : stepIndex;

    steps.forEach((step: Step, index: number) => {
      step.disabled = index !== stepIndex && !step.completed;
    });

    return { currentStep, steps };
  }, [history, timeline]);

  return (
    <Container data-testid="timeline-container">
      <ProgressTracker.Root size="large" orientation="horizontal" currentStep={currentStep}>
        <ProgressTracker.Header>
          {steps.map((step: Step, index: number) => (
            <ProgressTracker.Step
              key={step.name}
              index={index}
              completed={step.completed}
              disabled={step.disabled}
            >
              <Heading size="H5">
                {formatMessage({
                  id: `pages.poc-details.recommendation.timeline.steps.${step.name}`,
                })}
              </Heading>

              {step.date && (
                <Paragraph size="small" style={{ color: '#1414148F', marginTop: 4 }}>
                  {datetime.format(step.date, 'short-time')}
                </Paragraph>
              )}
            </ProgressTracker.Step>
          ))}
        </ProgressTracker.Header>
      </ProgressTracker.Root>
    </Container>
  );
};
