import React from 'react';

export const GenericError = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(
  (props, ref) => (
    <svg
      ref={ref}
      {...props}
      data-testid="generic-error-icon"
      width="200"
      height="200"
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5971_1303)">
        <g opacity="0.3">
          <mask
            id="mask0_5971_1303"
            style={{ maskType: 'luminance' }}
            maskUnits="userSpaceOnUse"
            x="104"
            y="33"
            width="91"
            height="91"
          >
            <path
              d="M149.467 123.267C174.178 123.267 194.211 103.234 194.211 78.5223C194.211 53.8106 174.178 33.7778 149.467 33.7778C124.755 33.7778 104.722 53.8106 104.722 78.5223C104.722 103.234 124.755 123.267 149.467 123.267Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask0_5971_1303)">
            <path
              d="M149.467 123.267C174.178 123.267 194.211 103.234 194.211 78.5223C194.211 53.8106 174.178 33.7778 149.467 33.7778C124.755 33.7778 104.722 53.8106 104.722 78.5223C104.722 103.234 124.755 123.267 149.467 123.267Z"
              fill="url(#paint0_linear_5971_1303)"
            />
          </g>
        </g>
        <g opacity="0.3">
          <mask
            id="mask1_5971_1303"
            style={{ maskType: 'luminance' }}
            maskUnits="userSpaceOnUse"
            x="-2"
            y="96"
            width="76"
            height="77"
          >
            <path
              d="M35.9778 172.156C56.9523 172.156 73.9556 155.152 73.9556 134.178C73.9556 113.203 56.9523 96.2001 35.9778 96.2001C15.0032 96.2001 -2 113.203 -2 134.178C-2 155.152 15.0032 172.156 35.9778 172.156Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask1_5971_1303)">
            <path
              d="M35.9778 172.156C56.9523 172.156 73.9556 155.152 73.9556 134.178C73.9556 113.203 56.9523 96.2001 35.9778 96.2001C15.0032 96.2001 -2 113.203 -2 134.178C-2 155.152 15.0032 172.156 35.9778 172.156Z"
              fill="url(#paint1_linear_5971_1303)"
            />
          </g>
        </g>
        <mask
          id="mask2_5971_1303"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="5"
          width="198"
          height="167"
        >
          <path
            d="M163.8 105.789C163.8 141.922 134.511 171.222 98.3665 171.222C62.222 171.222 32.9331 141.933 32.9331 105.789L0.755371 106.256L13.6665 5.88892H187.811L198 106.256L163.8 105.789Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask2_5971_1303)">
          <mask
            id="mask3_5971_1303"
            style={{ maskType: 'luminance' }}
            maskUnits="userSpaceOnUse"
            x="32"
            y="40"
            width="132"
            height="132"
          >
            <path
              d="M98.3889 171.167C134.527 171.167 163.822 141.871 163.822 105.733C163.822 69.5956 134.527 40.3 98.3889 40.3C62.2511 40.3 32.9556 69.5956 32.9556 105.733C32.9556 141.871 62.2511 171.167 98.3889 171.167Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask3_5971_1303)">
            <path
              d="M98.3889 171.167C134.527 171.167 163.822 141.871 163.822 105.733C163.822 69.5956 134.527 40.3 98.3889 40.3C62.2511 40.3 32.9556 69.5956 32.9556 105.733C32.9556 141.871 62.2511 171.167 98.3889 171.167Z"
              fill="url(#paint2_linear_5971_1303)"
            />
          </g>
        </g>
        <mask
          id="mask4_5971_1303"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="5"
          width="198"
          height="167"
        >
          <path
            d="M163.8 105.789C163.8 141.922 134.511 171.222 98.3665 171.222C62.222 171.222 32.9331 141.933 32.9331 105.789L0.755371 106.256L13.6665 5.88892H187.811L198 106.256L163.8 105.789Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask4_5971_1303)">
          <path
            d="M135.178 77.7444L151.467 65.2888C152.422 64.5666 153.767 64.6999 154.544 65.6111L169.544 83.1666C170.4 84.1666 170.222 85.6888 169.144 86.4666L151.033 99.4444L135.178 77.7444Z"
            fill="#E5D770"
          />
          <path
            d="M63.8222 77.7444L47.5222 65.2888C46.5667 64.5666 45.2222 64.6999 44.4445 65.6111L29.4445 83.1666C28.5889 84.1666 28.7667 85.6888 29.8445 86.4666L47.9667 99.4444L63.8222 77.7444Z"
            fill="#E5D770"
          />
          <path
            d="M150.889 99.4444H47.9668L63.8224 77.7444H135.045L150.889 99.4444Z"
            fill="#8C7C1B"
          />
          <path
            d="M150.889 99.4445H47.9668V202.367H150.889V99.4445Z"
            fill="url(#paint3_linear_5971_1303)"
          />
          <path
            opacity="0.16"
            d="M113.589 30.2445H85.2779C83.4112 30.2445 81.6334 30.989 80.3112 32.3001L59.2001 53.4112C57.8779 54.7334 57.1445 56.5112 57.1445 58.3778V86.689C57.1445 88.5556 57.889 90.3334 59.2001 91.6556L80.3112 112.767C81.6334 114.089 83.4112 114.822 85.2779 114.822H113.589C115.456 114.822 117.233 114.078 118.556 112.767L139.667 91.6556C140.989 90.3334 141.722 88.5556 141.722 86.689V58.3778C141.722 56.5112 140.978 54.7334 139.667 53.4112L118.556 32.3001C117.233 30.989 115.445 30.2445 113.589 30.2445Z"
            fill="black"
          />
          <path
            d="M114.344 24.9778H84.5223C82.5556 24.9778 80.6778 25.7556 79.2889 27.1444L57.0334 49.3889C55.6445 50.7778 54.8667 52.6556 54.8667 54.6222V84.4556C54.8667 86.4222 55.6445 88.3 57.0334 89.6889L79.2778 111.933C80.6667 113.322 82.5445 114.1 84.5111 114.1H114.344C116.311 114.1 118.189 113.322 119.578 111.933L141.822 89.6889C143.211 88.3 143.989 86.4222 143.989 84.4556V54.6222C143.989 52.6556 143.211 50.7778 141.822 49.3889L119.578 27.1444C118.189 25.7556 116.311 24.9778 114.344 24.9778Z"
            fill="#D85951"
          />
          <path
            d="M113.089 28.7334H85.7776C83.9776 28.7334 82.2554 29.4445 80.9887 30.7223L60.611 51.089C59.3443 52.3556 58.6221 54.089 58.6221 55.8778V83.189C58.6221 84.989 59.3332 86.7112 60.611 87.9778L80.9887 108.356C82.2554 109.622 83.9887 110.345 85.7776 110.345H113.089C114.889 110.345 116.611 109.633 117.878 108.356L138.255 87.9778C139.522 86.7112 140.244 84.9778 140.244 83.189V55.8778C140.244 54.0778 139.533 52.3556 138.255 51.089L117.878 30.7112C116.611 29.4445 114.889 28.7334 113.089 28.7334Z"
            fill="#D85951"
          />
          <path
            d="M96.0555 91.9888C95.1666 91.0777 94.7222 89.9222 94.7222 88.5111C94.7222 87.0999 95.1666 85.9555 96.0555 85.0666C96.9444 84.1777 98.0888 83.7333 99.4999 83.7333C100.911 83.7333 102.067 84.1777 102.978 85.0666C103.889 85.9555 104.344 87.0999 104.344 88.5111C104.344 89.9222 103.889 91.0777 102.978 91.9888C102.067 92.8999 100.911 93.3555 99.4999 93.3555C98.0888 93.3555 96.9444 92.8999 96.0555 91.9888ZM96.9444 77.1444C96.7888 76.9888 96.711 76.7888 96.711 76.5666L95.9555 46.5333C95.9555 46.3111 96.0333 46.1111 96.1999 45.9555C96.3555 45.7999 96.5555 45.7222 96.7777 45.7222H103.056C103.278 45.7222 103.478 45.7999 103.633 45.9555C103.789 46.1111 103.867 46.3111 103.867 46.5333L103.122 76.5666C103.122 76.7999 103.044 76.9888 102.878 77.1444C102.722 77.2999 102.522 77.3888 102.3 77.3888H97.5222C97.2999 77.3888 97.0999 77.3111 96.9444 77.1444Z"
            fill="white"
          />
          <path
            d="M113.089 31.689C114.111 31.689 115.067 32.089 115.789 32.8001L136.167 53.1778C136.889 53.9001 137.289 54.8556 137.289 55.8778V83.189C137.289 84.2112 136.889 85.1667 136.178 85.889L115.8 106.267C115.078 106.989 114.122 107.378 113.1 107.378H85.7776C84.7554 107.378 83.7998 106.978 83.0776 106.267L62.6998 85.889C61.9776 85.1667 61.5776 84.2112 61.5776 83.189V55.8778C61.5776 54.8556 61.9776 53.9001 62.6887 53.1778L83.0665 32.8001C83.7887 32.0778 84.7443 31.689 85.7665 31.689H113.089ZM113.089 28.7334H85.7776C83.9776 28.7334 82.2554 29.4445 80.9887 30.7223L60.611 51.089C59.3443 52.3556 58.6221 54.089 58.6221 55.8778V83.189C58.6221 84.989 59.3332 86.7112 60.611 87.9778L80.9887 108.356C82.2554 109.622 83.9887 110.345 85.7776 110.345H113.089C114.889 110.345 116.611 109.633 117.878 108.356L138.255 87.9778C139.522 86.7112 140.244 84.9778 140.244 83.189V55.8778C140.244 54.0778 139.533 52.3556 138.255 51.089L117.878 30.7112C116.611 29.4445 114.889 28.7334 113.089 28.7334Z"
            fill="white"
          />
          <path
            d="M48.1111 99.4445H150.967L156.544 119.144C157.411 122.2 155.067 125.222 151.844 125.222H47.2444C44.0222 125.222 41.6778 122.2 42.5444 119.144L48.1111 99.4445Z"
            fill="#E5D770"
          />
          <path opacity="0.16" d="M150.878 125.233H48.022V130.645H150.878V125.233Z" fill="black" />
        </g>
        <path
          opacity="0.16"
          d="M132.389 96.2001H66.5557L69.689 99.4445H129.067L132.389 96.2001Z"
          fill="black"
        />
        <g opacity="0.5">
          <mask
            id="mask5_5971_1303"
            style={{ maskType: 'luminance' }}
            maskUnits="userSpaceOnUse"
            x="17"
            y="57"
            width="24"
            height="23"
          >
            <path
              d="M28.9555 79.5556C35.1841 79.5556 40.2333 74.5063 40.2333 68.2778C40.2333 62.0492 35.1841 57 28.9555 57C22.727 57 17.6777 62.0492 17.6777 68.2778C17.6777 74.5063 22.727 79.5556 28.9555 79.5556Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask5_5971_1303)">
            <path
              d="M28.9555 79.5556C35.1841 79.5556 40.2333 74.5063 40.2333 68.2778C40.2333 62.0492 35.1841 57 28.9555 57C22.727 57 17.6777 62.0492 17.6777 68.2778C17.6777 74.5063 22.727 79.5556 28.9555 79.5556Z"
              fill="url(#paint4_linear_5971_1303)"
            />
          </g>
        </g>
        <g opacity="0.5">
          <mask
            id="mask6_5971_1303"
            style={{ maskType: 'luminance' }}
            maskUnits="userSpaceOnUse"
            x="159"
            y="152"
            width="23"
            height="23"
          >
            <path
              d="M170.4 174.811C176.628 174.811 181.678 169.762 181.678 163.533C181.678 157.305 176.628 152.256 170.4 152.256C164.171 152.256 159.122 157.305 159.122 163.533C159.122 169.762 164.171 174.811 170.4 174.811Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask6_5971_1303)">
            <path
              d="M170.4 174.811C176.628 174.811 181.678 169.762 181.678 163.533C181.678 157.305 176.628 152.256 170.4 152.256C164.171 152.256 159.122 157.305 159.122 163.533C159.122 169.762 164.171 174.811 170.4 174.811Z"
              fill="url(#paint5_linear_5971_1303)"
            />
          </g>
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_5971_1303"
          x1="104.721"
          y1="78.5185"
          x2="194.217"
          y2="78.5185"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFE0E4" />
          <stop offset="1" stopColor="#EEEFF1" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_5971_1303"
          x1="-2"
          y1="134.175"
          x2="73.9609"
          y2="134.175"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFE0E4" />
          <stop offset="1" stopColor="#EEEFF1" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_5971_1303"
          x1="32.9638"
          y1="105.733"
          x2="163.822"
          y2="105.733"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFE0E4" />
          <stop offset="1" stopColor="#EEEFF1" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_5971_1303"
          x1="99.431"
          y1="202.375"
          x2="99.431"
          y2="99.4483"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D8AF32" />
          <stop offset="1" stopColor="#D8BC32" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_5971_1303"
          x1="17.6761"
          y1="68.2754"
          x2="40.2375"
          y2="68.2754"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFE0E4" />
          <stop offset="1" stopColor="#EEEFF1" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_5971_1303"
          x1="159.116"
          y1="163.537"
          x2="181.678"
          y2="163.537"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFE0E4" />
          <stop offset="1" stopColor="#EEEFF1" />
        </linearGradient>
        <clipPath id="clip0_5971_1303">
          <rect width="200" height="200" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
);
