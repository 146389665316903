export function processFilters(input: object) {
  const result = {
    limit: null,
    terms: null,
    fee: null,
    recommendation: null,
    update: null,
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  function processMinMax(minKey, maxKey) {
    const minValue = input[minKey]?.formatted?.simple || null;
    const maxValue = input[maxKey]?.formatted?.simple || null;
    return minValue || maxValue ? `min: ${minValue}, max: ${maxValue}` : null;
  }

  function processRecommendations() {
    const recommendations = [];
    if (input['recommendation:creditOffer:checkbox']?.value) {
      recommendations.push('Credit Offer');
    }
    if (input['recommendation:creditIncrease:checkbox']?.value) {
      recommendations.push('Credit Increase');
    }
    if (input['recommendation:creditDecrease:checkbox']?.value) {
      recommendations.push('Credit Decrease');
    }
    return recommendations.length > 0 ? recommendations.join(', ') : null;
  }

  function processUpdateDates() {
    const startDate = input['updatedAt:startDate:range-date']?.value[0] || null;
    const endDate = input['updatedAt:endDate:range-date']?.value[0] || null;
    const formattedStartDate = startDate ? formatDate(startDate) : null;
    const formattedEndDate = endDate ? formatDate(endDate) : null;
    return startDate || endDate
      ? `Start Date: ${formattedStartDate}, End Date: ${formattedEndDate}`
      : null;
  }

  result.limit = processMinMax('creditLimit:min:range-value', 'creditLimit:max:range-value');
  result.terms = processMinMax('term:min:range-value', 'term:max:range-value');
  result.fee = processMinMax('fee:min:range-value', 'fee:max:range-value');
  result.recommendation = processRecommendations();
  result.update = processUpdateDates();

  return result;
}
