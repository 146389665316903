import { Dialog } from '@hexa-ui/components';
import styled from 'styled-components';

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;

  width: 70%;
  column-gap: 12px;
`;

export const SubtitleSpan = styled.span`
  font-family: Work Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
`;

export const Root = styled(Dialog.Root)``;

export const Close = styled(Dialog.Close)``;
