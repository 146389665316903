import { Button, Heading, Paragraph } from '@hexa-ui/components';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { ButtonsContainer, Close, Root, SubtitleSpan } from './styles';

export const CancelUploadModal: React.FC<{ blocker: any }> = ({ blocker }) => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const handleOnCloseModal = () => {
    blocker.reset();
  };

  return (
    <Root
      open={blocker?.state === 'blocked'}
      data-testid="cancel-upload-modal"
      title={<Heading size="H2">{formatMessage({ id: 'pages.upload.cancelModal.title' })}</Heading>}
      contentCss={{ minWidth: '570px' }}
      trigger={
        <Button
          id="back-button"
          size="medium"
          variant="secondary"
          onClick={() => navigate('/bees-credit-management/files')}
        >
          {formatMessage({ id: 'pages.upload.buttons.back' })}
        </Button>
      }
      onClose={handleOnCloseModal}
      actions={
        <ButtonsContainer>
          <Close>
            <Button
              size="large"
              variant="destructive"
              onClick={() => {
                blocker.proceed();
              }}
            >
              {formatMessage({ id: 'pages.upload.cancelModal.buttons.confirm' })}
            </Button>
          </Close>
          <Close>
            <Button size="large" variant="secondary" onClick={handleOnCloseModal}>
              {formatMessage({ id: 'pages.upload.cancelModal.buttons.cancel' })}
            </Button>
          </Close>
        </ButtonsContainer>
      }
    >
      <Paragraph size="small" colortype="primary" css={{ whiteSpace: 'pre-line' }}>
        <SubtitleSpan>{formatMessage({ id: 'pages.upload.cancelModal.subtitle' })}</SubtitleSpan>
      </Paragraph>
    </Root>
  );
};
