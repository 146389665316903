import { FileStatus } from '../../interfaces';

export const actions = {
  files: {
    finished: [FileStatus.DONE, FileStatus.FAILED],
    progress: [
      FileStatus.PENDING,
      FileStatus.UPLOADED,
      FileStatus.ANALYZING,
      FileStatus.ANALYZED,
      FileStatus.PROCESSING,
    ],
  },
};
