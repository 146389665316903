import { Card, Paragraph } from '@hexa-ui/components';
import styled from 'styled-components';

export const ProgressCard = styled(Card)`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  width: 70%;
  padding: 24px;
`;

export const ProgressContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  row-gap: 24px;
`;

export const TotalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  margin-top: 16px;
  width: 100%;
  column-gap: 12px;
`;

export const TotalTextContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  width: 40%;
`;

export const TooltipContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;

  width: 100%;
  column-gap: 6px;
`;

export const ProgressMeterContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  width: 100%;
  row-gap: 12px;
`;

export const TextContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 24px;

  width: 100%;
  row-gap: 8px;
`;

export const DescriptionTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;

  text-align: center;

  width: 100%;
  column-gap: 8px;
`;

export const DescriptionToModal = styled(Paragraph)`
  display: inline-flex;
  flex-wrap: wrap;
  column-gap: 8px;
  font-size: 16px;
`;

export const ProgressCardAnalyzed = styled(Card)`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  border: 1px solid var(--border-secondary, #1414141f);
  margin-top: 16px;
  width: 100%;
  padding: 24px;
  gap: 24px;
`;
