import { Button, Heading, Paragraph } from '@hexa-ui/components';
import { Check2 } from '@hexa-ui/icons';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useGetUserPreferences } from '../../../hooks/useGetUserPreferences';
import { Status } from '../../../interfaces';
import SegmentService from '../../../services/segment';
import { Modal } from '../Modal/Modal';
import { DenyRecommendationProps } from './DenyRecommendationProps.types';
import { ModalContent } from './styles';

export const DenyRecommendation: React.FC<DenyRecommendationProps> = ({
  data,
  trigger,
  open,
  portal,
  onApproval,
}) => {
  const { formatMessage } = useIntl();

  const { selected, language } = useGetUserPreferences();
  const { locale } = language;

  const onClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    if (open?.value) open.setter(false);
  };

  const onDeny = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement>, status: Status) => {
      event.stopPropagation();

      await onApproval(status);
    },
    [onApproval]
  );

  const buttonClicked = (buttonLabel: string, buttonName: string, eventName: string) => {
    SegmentService.paymentsButtonClicked(
      buttonLabel,
      buttonName,
      selected?.vendorId,
      eventName,
      'CREDIT_MANAGEMENT_PENDING_TAB'
    );
  };

  return (
    <Modal
      css={{ maxWidth: '720px' }}
      open={open}
      portal={portal}
      trigger={trigger}
      onClose={onClose}
      actions={{
        buttons: {
          cancel: (
            <Button id="on-back" size="medium" variant="secondary" onClick={onClose}>
              {formatMessage({ id: 'approveRecommendation.buttons.back' })}
            </Button>
          ),
          confirm: (
            <Button
              id="on-deny"
              icon={Check2}
              size="medium"
              variant="primary"
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                onDeny(event, Status.DENIED);

                buttonClicked(
                  'Deny Selected',
                  'Deny Selected Button',
                  'Credit Management Mass Approval Deny Selected'
                );
              }}
            >
              {formatMessage({ id: 'denyRecommendation.button' })}
            </Button>
          ),
        },
      }}
    >
      <ModalContent data-testid="modal-content" onClick={(e) => e.stopPropagation()}>
        <Heading size="H2" css={{ fontSize: '32px' }}>
          {formatMessage(
            { id: 'denyRecommendation.title' },
            { counter: data.counter?.toLocaleString(locale) }
          )}
        </Heading>

        <Paragraph size="basis">
          {formatMessage(
            { id: 'denyRecommendation.description' },
            { counter: data.counter?.toLocaleString(locale) }
          )}
        </Paragraph>
      </ModalContent>
    </Modal>
  );
};
