import { FilterChips } from './Chips/FilterChips';
import { FilterProvider } from './Context/FilterContext';
import { ContextProps } from './Context/FilterContext.types';
import { FilterComponentProps } from './Filter.types';
import { FilterRoot } from './Root/FilterRoot';

export const Context: React.FC<ContextProps> = ({ table, children }) => {
  return <FilterProvider table={table}>{children}</FilterProvider>;
};

export const Filter: FilterComponentProps = { Root: FilterRoot, Chips: FilterChips, Context };
