import { Heading, TextButton } from '@hexa-ui/components';
import { History2 } from '@hexa-ui/icons';
import { useIntl } from 'react-intl';
import { useGetUserPreferences } from '../../../../../hooks/useGetUserPreferences';
import SegmentService from '../../../../../services/segment';
import { CreditHistoryCardProps } from './CreditHistory.types';
import { CreditInformation } from './Information/CreditInformation';
import { CreditModal } from './Modal/CreditModal';
import { Card } from './styles';

export const CreditHistory: React.FC<CreditHistoryCardProps> = ({
  beesAccountId,
  creditHistory,
}) => {
  const { formatMessage } = useIntl();

  const { selected } = useGetUserPreferences();

  const handleClick = () => {
    SegmentService.paymentsButtonClicked(
      'Show credit history',
      'Show credit history',
      selected?.vendorId,
      'Credit Management Show Credit History',
      'POC_DETAILS_PAGE'
    );
  };

  return (
    <Card border="large" elevated="large">
      <Heading size="H3" css={{ marginBottom: '$2' }}>
        {formatMessage({ id: 'pages.poc-details.history.credit.title' })}
      </Heading>

      {creditHistory.map((history) => (
        <CreditInformation key={`${history.status}-${history.date}`} history={history} />
      ))}

      <CreditModal
        beesAccountId={beesAccountId}
        trigger={
          <TextButton
            size="medium"
            iconPosition="leading"
            icon={() => <History2 size="large" />}
            css={{
              gap: '8px',
              color: '#0F7FFA',
              svg: { color: '#0F7FFA', fill: '#0F7FFA', g: { fill: '#0F7FFA' } },
            }}
            onClick={handleClick}
          >
            {formatMessage({ id: 'pages.poc-details.history.credit.buttons.trigger' })}
          </TextButton>
        }
      />
    </Card>
  );
};
