// This client was automatically generated by Segment Typewriter. ** Do Not Edit **
// To update this file, run:
//   npx typewriter

/**
 * Triggered when user clicks on "Copy" button in account number card on Bank Transfer Screen
 */
export interface BankAccountNumberCopied {
  /**
   * The label of the button in which the user clicked. Samples: "Update", "Cancel"
   */
  button_label: null | string;
  /**
   * What is the name of the button. Samples: Place Order
   */
  button_name: null | string;
  /**
   * Where did the user come from to get to this page/screen. The PREVIOUS screen. Samples:
   * 'Home Screen', 'Invoices', 'My Truck'
   */
  referrer: null | string;
  /**
   * What is the name of the screen where the user is taking this action. The CURRENT screen.
   * Samples: 'Home Screen', 'Invoices', 'My Truck'
   */
  screen_name: null | string;
  [property: string]: any;
}

/**
 * When the user sees a list of Banks
 */
export interface BankListViewed {
  /**
   * This property is added as a workaround to generate type for events without properties
   */
  _?: null;
  properties: any;
  [property: string]: any;
}

/**
 * When the user selects a bank
 */
export interface BankSelected {
  /**
   * The ID of the bank in the given context.
   */
  bank_id: null | string;
  /**
   * The name of the bank in the given context. Samples: Wells Fargo
   */
  bank_name: null | string;
  /**
   * Image URL of the product. Samples:
   * "https://www.socioscerveceria.com.do/media/catalog/product/m/a/malta_morena_8_onz_pla
   */
  image_url: null | string;
  /**
   * The product's ABSOLUTE position in the page. It MUST start at 1, NOT at 0. If it's a
   * pagination context, it won't be position in the given page, it will be ABSOLUTE position
   * across the pages. Samples: 1, 8, 4
   */
  position: number | null;
  /**
   * What is the name of the screen where the user is taking this action. The CURRENT screen.
   * Samples: 'Home Screen', 'Invoices', 'My Truck'
   */
  screen_name: null | string;
  [property: string]: any;
}

/**
 * When the screen with the bank transfer code is loaded
 */
export interface BankTransferCodeViewed {
  /**
   * ISO currency - Currency associated with product. Samples: DOP, ZAR
   */
  currency: null | string;
  /**
   * The due date in the given context. It's a date time prop. Samples:
   * 2020-11-11T19:01:40.032Z
   */
  due_date: Date | null;
  /**
   * The amount of invoice due. It's a number. Samples: '10450,50'
   */
  invoice_amount_due: number | null;
  /**
   * Invoice ID - generated from ABI Invoice service. Sample: B0101450319
   */
  invoice_id: null | string;
  /**
   * The payment method in the given context. Samples: CASH, CHECK, CREDIT, BOLETO,
   * CREDIT_CARD_POS, BANK_SLIP
   */
  payment_method: PaymentMethod | null;
  [property: string]: any;
}

export enum PaymentMethod {
  BankDeposit = 'BANK_DEPOSIT',
  BankSlip = 'BANK_SLIP',
  BankSlipInstallment = 'BANK_SLIP_INSTALLMENT',
  BankTransfer = 'BANK_TRANSFER',
  Boleto = 'BOLETO',
  Cash = 'CASH',
  Check = 'CHECK',
  Credit = 'CREDIT',
  CreditCardOnline = 'CREDIT_CARD_ONLINE',
  CreditCardPos = 'CREDIT_CARD_POS',
  DirectPayAtDelivery = 'DIRECT_PAY_AT_DELIVERY',
  Gps = 'GPS',
  PixAtDelivery = 'PIX_AT_DELIVERY',
  PixOnline = 'PIX_ONLINE',
  ReceivableAsCredit = 'RECEIVABLE_AS_CREDIT',
}

/**
 * Triggered when user clicks on "Bank Transfer Info" button inside Grupo Modelo Credit Page
 */
export interface BankTransferInfoClicked {
  /**
   * The label of the button in which the user clicked. Samples: "Update", "Cancel"
   */
  button_label: null | string;
  /**
   * What is the name of the button. Samples: Place Order
   */
  button_name: null | string;
  /**
   * Where did the user come from to get to this page/screen. The PREVIOUS screen. Samples:
   * 'Home Screen', 'Invoices', 'My Truck'
   */
  referrer: null | string;
  /**
   * What is the name of the screen where the user is taking this action. The CURRENT screen.
   * Samples: 'Home Screen', 'Invoices', 'My Truck'
   */
  screen_name: null | string;
  [property: string]: any;
}

/**
 * This event must fire when the bank webview request fails and the API returns an error
 * message
 */
export interface BankWebviewRequestFailed {
  /**
   * The name of the bank in the given context. Samples: Wells Fargo
   */
  bank_name: null | string;
  /**
   * Communication Service error description for 400 and 500 status. Samples: "EMPTY_FIELDS",
   * "The email request has invalid information."
   */
  failure_reason: null | string;
  /**
   * What is the name of the screen where the user is taking this action. The CURRENT screen.
   * Samples: 'Home Screen', 'Invoices', 'My Truck'
   */
  screen_name: null | string;
  [property: string]: any;
}

/**
 * Triggered when user clicks on "Copy" button in beneficary's name card on Bank Transfer
 * Screen
 */
export interface BeneficiaryNameCopied {
  /**
   * The label of the button in which the user clicked. Samples: "Update", "Cancel"
   */
  button_label: null | string;
  /**
   * What is the name of the button. Samples: Place Order
   */
  button_name: null | string;
  /**
   * Where did the user come from to get to this page/screen. The PREVIOUS screen. Samples:
   * 'Home Screen', 'Invoices', 'My Truck'
   */
  referrer: null | string;
  /**
   * What is the name of the screen where the user is taking this action. The CURRENT screen.
   * Samples: 'Home Screen', 'Invoices', 'My Truck'
   */
  screen_name: null | string;
  [property: string]: any;
}

/**
 * This event must be fired when the user clicks to download the PDF of the 'Boleto' (the
 * Bill Payment)
 */
export interface BillPaymentPDFDownloaded {
  /**
   * Invoice ID - generated from ABI Invoice service. Sample: B0101450319
   */
  invoice_id: null | string;
  /**
   * What is the name of the screen where the user is taking this action. The CURRENT screen.
   * Samples: 'Home Screen', 'Invoices', 'My Truck'
   */
  screen_name: null | string;
  /**
   * Internal BEES ID that identifies the wholesaler. It's the vendorId from the Accounts
   * Microservice.
   */
  vendorId: null | string;
  [property: string]: any;
}

/**
 * Triggered in credit summary screen when user clicks on credit blocked reason.
 */
export interface CreditBlockedReasonClicked {
  /**
   * The label of the button in which the user clicked. Samples: "Update", "Cancel"
   */
  button_label: null | string;
  /**
   * What is the name of the button. Samples: Place Order
   */
  button_name: null | string;
  /**
   * Where did the user come from to get to this page/screen. The PREVIOUS screen. Samples:
   * 'Home Screen', 'Invoices', 'My Truck'
   */
  referrer: null | string;
  /**
   * What is the name of the screen where the user is taking this action. The CURRENT screen.
   * Samples: 'Home Screen', 'Invoices', 'My Truck'
   */
  screen_name: null | string;
  /**
   * Internal BEES ID that identifies the wholesaler. It's the vendorId from the Accounts
   * Microservice.
   */
  vendorId: null | string;
  [property: string]: any;
}

/**
 * Triggered in seller selection screen when user clicks on credit vendor that is with
 * status blocked
 */
export interface CreditBlockedVendorClicked {
  /**
   * Where did the user come from to get to this page/screen. The PREVIOUS screen. Samples:
   * 'Home Screen', 'Invoices', 'My Truck'
   */
  referrer: null | string;
  /**
   * What is the name of the screen where the user is taking this action. The CURRENT screen.
   * Samples: 'Home Screen', 'Invoices', 'My Truck'
   */
  screen_name: null | string;
  /**
   * Internal BEES ID that identifies the wholesaler. It's the vendorId from the Accounts
   * Microservice.
   */
  vendorId: null | string;
  [property: string]: any;
}

/**
 * Triggered when users see the empty state screen to access the Credit Web View
 */
export interface CreditEmptyStateViewed {
  /**
   * The POC identifier to which the order is being placed
   */
  account_id: null | string;
  /**
   * The total amount of rotative credit the POC still has to buy from BEES.
   */
  credit_available?: number | null;
  /**
   * The credit balance value in the seller screen. Samples: $ 1702
   */
  credit_balance?: number | null;
  /**
   * Credit value that is pending payment by the account
   */
  credit_consumption?: number | null;
  /**
   * The credit overdue for a given Vendor to the current user
   */
  credit_overdue?: number | null;
  /**
   * Credit limit value provide by vendor to the account spent in orders
   */
  credit_total?: number | null;
  [property: string]: any;
}

/**
 * Triggered when users see the error screen to access Credit Web View
 */
export interface CreditErrorReturnViewed {
  /**
   * The POC identifier to which the order is being placed
   */
  account_id: null | string;
  /**
   * The total amount of rotative credit the POC still has to buy from BEES.
   */
  credit_available?: number | null;
  /**
   * The credit balance value in the seller screen. Samples: $ 1702
   */
  credit_balance?: number | null;
  /**
   * Credit value that is pending payment by the account
   */
  credit_consumption?: number | null;
  /**
   * The credit overdue for a given Vendor to the current user
   */
  credit_overdue?: number | null;
  /**
   * Credit limit value provide by vendor to the account spent in orders
   */
  credit_total?: number | null;
  [property: string]: any;
}

/**
 * When user clicks on "Cancel" button inside "Activate Credit Line" modal
 */
export interface CreditLineActivationCanceled {
  /**
   * The label of the button in which the user clicked. Samples: "Update", "Cancel"
   */
  button_label: null | string;
  /**
   * What is the name of the button. Samples: Place Order
   */
  button_name: null | string;
  /**
   * Where did the user come from to get to this page/screen. The PREVIOUS screen. Samples:
   * 'Home Screen', 'Invoices', 'My Truck'
   */
  referrer: null | string;
  /**
   * What is the name of the screen where the user is taking this action. The CURRENT screen.
   * Samples: 'Home Screen', 'Invoices', 'My Truck'
   */
  screen_name: null | string;
  [property: string]: any;
}

/**
 * When user clicks on "Activate Credit Line" button inside Credit Page
 */
export interface CreditLineActivationClicked {
  /**
   * The label of the button in which the user clicked. Samples: "Update", "Cancel"
   */
  button_label: null | string;
  /**
   * What is the name of the button. Samples: Place Order
   */
  button_name: null | string;
  /**
   * Where did the user come from to get to this page/screen. The PREVIOUS screen. Samples:
   * 'Home Screen', 'Invoices', 'My Truck'
   */
  referrer: null | string;
  /**
   * What is the name of the screen where the user is taking this action. The CURRENT screen.
   * Samples: 'Home Screen', 'Invoices', 'My Truck'
   */
  screen_name: null | string;
  /**
   * Internal BEES ID that identifies the wholesaler. It's the vendorId from the Accounts
   * Microservice.
   */
  vendorId: null | string;
  [property: string]: any;
}

/**
 * When user clicks on "Ok, continue" button inside "Activate Credit Line" modal
 */
export interface CreditLineActivationConfirmed {
  /**
   * The label of the button in which the user clicked. Samples: "Update", "Cancel"
   */
  button_label: null | string;
  /**
   * What is the name of the button. Samples: Place Order
   */
  button_name: null | string;
  /**
   * Where did the user come from to get to this page/screen. The PREVIOUS screen. Samples:
   * 'Home Screen', 'Invoices', 'My Truck'
   */
  referrer: null | string;
  /**
   * What is the name of the screen where the user is taking this action. The CURRENT screen.
   * Samples: 'Home Screen', 'Invoices', 'My Truck'
   */
  screen_name: null | string;
  [property: string]: any;
}

/**
 * Triggered when user clicks on "Credit" button in My Account Hub and it's available to
 * interact with the feature.​
 */
export interface CreditLineActivationPopUpViewed {
  /**
   * What is the name of the screen where the user is taking this action. The CURRENT screen.
   * Samples: 'Home Screen', 'Invoices', 'My Truck'
   */
  screen_name: null | string;
  /**
   * Internal BEES ID that identifies the wholesaler. It's the vendorId from the Accounts
   * Microservice.
   */
  vendorId: null | string;
  [property: string]: any;
}

/**
 * Triggered when user clicks on "Get help" button inside request in under review or not
 * approved.
 */
export interface CreditLineActivationResultHelpClicked {
  /**
   * Credit limit value provide by vendor to the account spent in orders
   */
  credit_total?: number | null;
  /**
   * Identification of type of credit line error
   */
  result_type: ResultType | null;
  /**
   * Internal BEES ID that identifies the wholesaler. It's the vendorId from the Accounts
   * Microservice.
   */
  vendorId: null | string;
  [property: string]: any;
}

export enum ResultType {
  DocumentsFailed = 'DOCUMENTS_FAILED',
  NotApproved = 'NOT_APPROVED',
  NotOwner = 'NOT_OWNER',
  UnderReview = 'UNDER_REVIEW',
}

/**
 * Triggered when user pass through Credit Line journey and request is in under review,
 * approved or not approved.
 */
export interface CreditLineActivationResultShowed {
  /**
   * Identification of type of credit line error
   */
  result_type: ResultType | null;
  /**
   * Internal BEES ID that identifies the wholesaler. It's the vendorId from the Accounts
   * Microservice.
   */
  vendorId: null | string;
  [property: string]: any;
}

/**
 * When user clicks on "Start activation" button inside "Activate Credit Line" screen
 */
export interface CreditLineActivationStarted {
  /**
   * The label of the button in which the user clicked. Samples: "Update", "Cancel"
   */
  button_label: null | string;
  /**
   * What is the name of the button. Samples: Place Order
   */
  button_name: null | string;
  /**
   * Where did the user come from to get to this page/screen. The PREVIOUS screen. Samples:
   * 'Home Screen', 'Invoices', 'My Truck'
   */
  referrer: null | string;
  /**
   * What is the name of the screen where the user is taking this action. The CURRENT screen.
   * Samples: 'Home Screen', 'Invoices', 'My Truck'
   */
  screen_name: null | string;
  /**
   * Internal BEES ID that identifies the wholesaler. It's the vendorId from the Accounts
   * Microservice.
   */
  vendorId: null | string;
  [property: string]: any;
}

/**
 * Triggered when user clicks on 'Go back to Credit' inside Credit Documents Submitted
 * Screen.
 */
export interface CreditLineDocumentsSubmittedClicked {
  /**
   * The label of the button in which the user clicked. Samples: "Update", "Cancel"
   */
  button_label: null | string;
  /**
   * What is the name of the button. Samples: Place Order
   */
  button_name: null | string;
  /**
   * Where did the user come from to get to this page/screen. The PREVIOUS screen. Samples:
   * 'Home Screen', 'Invoices', 'My Truck'
   */
  referrer: null | string;
  /**
   * What is the name of the screen where the user is taking this action. The CURRENT screen.
   * Samples: 'Home Screen', 'Invoices', 'My Truck'
   */
  screen_name: null | string;
  /**
   * Internal BEES ID that identifies the wholesaler. It's the vendorId from the Accounts
   * Microservice.
   */
  vendorId: null | string;
  [property: string]: any;
}

/**
 * Triggered when Credit Documents Submitted Screen opens.​
 */
export interface CreditLineDocumentsSubmittedViewed {
  /**
   * Where did the user come from to get to this page/screen. The PREVIOUS screen. Samples:
   * 'Home Screen', 'Invoices', 'My Truck'
   */
  referrer: null | string;
  /**
   * What is the name of the screen where the user is taking this action. The CURRENT screen.
   * Samples: 'Home Screen', 'Invoices', 'My Truck'
   */
  screen_name: null | string;
  /**
   * Internal BEES ID that identifies the wholesaler. It's the vendorId from the Accounts
   * Microservice.
   */
  vendorId: null | string;
  [property: string]: any;
}

/**
 * Triggered when user clicks on "X" button inside the journey and Exit Modal opens.​
 */
export interface CreditLineExitModalShowed {
  /**
   * The label of the button in which the user clicked. Samples: "Update", "Cancel"
   */
  button_label: null | string;
  /**
   * What is the name of the button. Samples: Place Order
   */
  button_name: null | string;
  /**
   * Where did the user come from to get to this page/screen. The PREVIOUS screen. Samples:
   * 'Home Screen', 'Invoices', 'My Truck'
   */
  referrer: null | string;
  /**
   * What is the name of the screen where the user is taking this action. The CURRENT screen.
   * Samples: 'Home Screen', 'Invoices', 'My Truck'
   */
  screen_name: null | string;
  /**
   * Internal BEES ID that identifies the wholesaler. It's the vendorId from the Accounts
   * Microservice.
   */
  vendorId: null | string;
  [property: string]: any;
}

/**
 * Triggered when response between Phone Number, Phone Code and Terms Conditions screens is
 * unexpected.
 */
export interface CreditLinePageErrorShowed {
  /**
   * Where did the user come from to get to this page/screen. The PREVIOUS screen. Samples:
   * 'Home Screen', 'Invoices', 'My Truck'
   */
  referrer: null | string;
  /**
   * Internal BEES ID that identifies the wholesaler. It's the vendorId from the Accounts
   * Microservice.
   */
  vendorId: null | string;
  [property: string]: any;
}

/**
 * Triggered when users see the loading screen to access the Credit Web View
 */
export interface CreditLoadingViewed {
  /**
   * The POC identifier to which the order is being placed
   */
  account_id: null | string;
  /**
   * The total amount of rotative credit the POC still has to buy from BEES.
   */
  credit_available?: number | null;
  /**
   * The credit balance value in the seller screen. Samples: $ 1702
   */
  credit_balance?: number | null;
  /**
   * Credit value that is pending payment by the account
   */
  credit_consumption?: number | null;
  /**
   * The credit overdue for a given Vendor to the current user
   */
  credit_overdue?: number | null;
  /**
   * Credit limit value provide by vendor to the account spent in orders
   */
  credit_total?: number | null;
  [property: string]: any;
}

/**
 * Triggered when users click on "X" inside the the banner notification on Credit Web View
 */
export interface CreditPartialInfoExit {
  /**
   * The POC identifier to which the order is being placed
   */
  account_id: null | string;
  /**
   * The total amount of rotative credit the POC still has to buy from BEES.
   */
  credit_available?: number | null;
  /**
   * The credit balance value in the seller screen. Samples: $ 1702
   */
  credit_balance?: number | null;
  /**
   * Credit value that is pending payment by the account
   */
  credit_consumption?: number | null;
  /**
   * The credit overdue for a given Vendor to the current user
   */
  credit_overdue?: number | null;
  /**
   * Credit limit value provide by vendor to the account spent in orders
   */
  credit_total?: number | null;
  [property: string]: any;
}

/**
 * Triggered when users see the banner notification to access the Credit Web View
 */
export interface CreditPartialInfoViewed {
  /**
   * The POC identifier to which the order is being placed
   */
  account_id: null | string;
  /**
   * The total amount of rotative credit the POC still has to buy from BEES.
   */
  credit_available?: number | null;
  /**
   * The credit balance value in the seller screen. Samples: $ 1702
   */
  credit_balance?: number | null;
  /**
   * Credit value that is pending payment by the account
   */
  credit_consumption?: number | null;
  /**
   * The credit overdue for a given Vendor to the current user
   */
  credit_overdue?: number | null;
  /**
   * Credit limit value provide by vendor to the account spent in orders
   */
  credit_total?: number | null;
  [property: string]: any;
}

/**
 * Triggered when users see the SSO loading screen during the Credit Web View journey
 */
export interface CreditSSOLoadingViewed {
  /**
   * The POC identifier to which the order is being placed
   */
  account_id: null | string;
  /**
   * The total amount of rotative credit the POC still has to buy from BEES.
   */
  credit_available?: number | null;
  /**
   * The credit balance value in the seller screen. Samples: $ 1702
   */
  credit_balance?: number | null;
  /**
   * Credit value that is pending payment by the account
   */
  credit_consumption?: number | null;
  /**
   * The credit overdue for a given Vendor to the current user
   */
  credit_overdue?: number | null;
  /**
   * Credit limit value provide by vendor to the account spent in orders
   */
  credit_total?: number | null;
  [property: string]: any;
}

/**
 * Triggered when users begins interacting with the Experimental Feature
 */
export interface CreditSellerExperimentFeatureClicked {
  /**
   * The total amount of rotative credit the POC still has to buy from BEES.
   */
  credit_available?: number | null;
  /**
   * The credit balance value in the seller screen. Samples: $ 1702
   */
  credit_balance?: number | null;
  /**
   * The credit limit value in the seller screen. Samples: $ 1702
   */
  credit_limit: number | null;
  /**
   * The credit overdue for a given Vendor to the current user
   */
  credit_overdue?: number | null;
  /**
   * The credit vendor unique ID for a given Vendor to the current user
   */
  credit_vendorid: null | string;
  /**
   * internal identification of the experimental feature name
   */
  feature_name: null | string;
  /**
   * internal identification of the experimental feature type
   */
  feature_type: FeatureType | null;
  /**
   * internal identification of the experimental feature audience ID on Optmizely
   */
  optimizely_audience_id: null | string;
  [property: string]: any;
}

export enum FeatureType {
  Button = 'BUTTON',
  PopUp = 'POP_UP',
}

/**
 * Triggered when users completed interacting with the Experimental Feature
 */
export interface CreditSellerExperimentFeatureCompleted {
  /**
   * The total amount of rotative credit the POC still has to buy from BEES.
   */
  credit_available?: number | null;
  /**
   * The credit balance value in the seller screen. Samples: $ 1702
   */
  credit_balance?: number | null;
  /**
   * The credit limit value in the seller screen. Samples: $ 1702
   */
  credit_limit: number | null;
  /**
   * The credit overdue for a given Vendor to the current user
   */
  credit_overdue?: number | null;
  /**
   * The credit vendor unique ID for a given Vendor to the current user
   */
  credit_vendorid: null | string;
  /**
   * internal identification of the experimental feature name
   */
  feature_name: null | string;
  /**
   * internal identification of the experimental feature type
   */
  feature_type: FeatureType | null;
  /**
   * internal identification of the experimental feature audience ID on Optmizely
   */
  optimizely_audience_id: null | string;
  [property: string]: any;
}

/**
 * Triggered when users clicks on the button of the Experimental Feature
 */
export interface CreditSellerExperimentFeatureInteracted {
  /**
   * The total amount of rotative credit the POC still has to buy from BEES.
   */
  credit_available?: number | null;
  /**
   * The credit balance value in the seller screen. Samples: $ 1702
   */
  credit_balance?: number | null;
  /**
   * The credit limit value in the seller screen. Samples: $ 1702
   */
  credit_limit: number | null;
  /**
   * The credit overdue for a given Vendor to the current user
   */
  credit_overdue?: number | null;
  /**
   * The credit vendor unique ID for a given Vendor to the current user
   */
  credit_vendorid: null | string;
  /**
   * internal identification of the experimental feature name
   */
  feature_name: null | string;
  /**
   * internal identification of the experimental feature type
   */
  feature_type: FeatureType | null;
  /**
   * internal identification of the experimental feature audience ID on Optmizely
   */
  optimizely_audience_id: null | string;
  [property: string]: any;
}

/**
 * Triggered when users sees the button that can initiate de Experimental Feature
 */
export interface CreditSellerExperimentFeatureViewed {
  /**
   * The total amount of rotative credit the POC still has to buy from BEES.
   */
  credit_available?: number | null;
  /**
   * The credit balance value in the seller screen. Samples: $ 1702
   */
  credit_balance?: number | null;
  /**
   * The credit limit value in the seller screen. Samples: $ 1702
   */
  credit_limit: number | null;
  /**
   * The credit overdue for a given Vendor to the current user
   */
  credit_overdue?: number | null;
  /**
   * The credit vendor unique ID for a given Vendor to the current user
   */
  credit_vendorid: null | string;
  /**
   * internal identification of the experimental feature name
   */
  feature_name: null | string;
  /**
   * internal identification of the experimental feature type
   */
  feature_type: FeatureType | null;
  /**
   * internal identification of the experimental feature audience ID on Optmizely
   */
  optimizely_audience_id: null | string;
  [property: string]: any;
}

/**
 * Triggered when users click on the other Credit Seller name
 */
export interface CreditSellerOptionsChange {
  /**
   * Internal BEES ID that identifies the wholesaler. It's the vendorId from the Accounts
   * Microservice.
   */
  vendorId: null | string;
  [property: string]: any;
}

/**
 * Triggered when users click on the Credit Seller name button
 */
export interface CreditSellerOptionsInteraction {
  /**
   * Internal BEES ID that identifies the wholesaler. It's the vendorId from the Accounts
   * Microservice.
   */
  vendorId: null | string;
  [property: string]: any;
}

/**
 * Triggered when users clicks on the Credit Seller Selection
 */
export interface CreditSellerSelectionClicked {
  /**
   * The total amount of rotative credit the POC still has to buy from BEES.
   */
  credit_available?: number | null;
  /**
   * The credit balance value in the seller screen. Samples: $ 1702
   */
  credit_balance?: number | null;
  /**
   * The credit limit value in the seller screen. Samples: $ 1702
   */
  credit_limit: number | null;
  /**
   * Where did the user come from to get to this page/screen. The PREVIOUS screen. Samples:
   * 'Home Screen', 'Invoices', 'My Truck'
   */
  referrer: null | string;
  /**
   * What is the name of the screen where the user is taking this action. The CURRENT screen.
   * Samples: 'Home Screen', 'Invoices', 'My Truck'
   */
  screen_name: null | string;
  /**
   * Internal BEES ID that identifies the wholesaler. It's the vendorId from the Accounts
   * Microservice.
   */
  vendorId: null | string;
  [property: string]: any;
}

/**
 * Triggered when users access the Credit Seller Selection screen
 */
export interface CreditSellerSelectionViewed {
  /**
   * The array of credits. Attention: Array props should directly contain the sub objects, not
   * the 'items' as an object like in the Protocols UI
   */
  credits: Credit[] | null;
  /**
   * Where did the user come from to get to this page/screen. The PREVIOUS screen. Samples:
   * 'Home Screen', 'Invoices', 'My Truck'
   */
  referrer: null | string;
  /**
   * What is the name of the screen where the user is taking this action. The CURRENT screen.
   * Samples: 'Home Screen', 'Invoices', 'My Truck'
   */
  screen_name: null | string;
  [property: string]: any;
}

/**
 * Do not implement with the 'items' structure/object, it's a normal array
 */
export interface Credit {
  /**
   * The total amount of rotative credit the POC still has to buy from BEES.
   */
  credit_available: number | null;
  /**
   * The credit balance value in the seller screen. Samples: $ 1702
   */
  credit_balance: number | null;
  /**
   * The credit limit value in the seller screen. Samples: $ 1702
   */
  credit_limit: number | null;
  /**
   * Internal BEES ID that identifies the wholesaler. It's the vendorId from the Accounts
   * Microservice.
   */
  vendorId: string;
  [property: string]: any;
}

/**
 * Triggered when users access the Credit Seller summary screen
 */
export interface CreditSellerSummaryViewed {
  /**
   * The total amount of rotative credit the POC still has to buy from BEES.
   */
  credit_available?: number | null;
  /**
   * The credit balance value in the seller screen. Samples: $ 1702
   */
  credit_balance?: number | null;
  /**
   * The credit limit value in the seller screen. Samples: $ 1702
   */
  credit_limit: number | null;
  /**
   * Where did the user come from to get to this page/screen. The PREVIOUS screen. Samples:
   * 'Home Screen', 'Invoices', 'My Truck'
   */
  referrer: null | string;
  /**
   * What is the name of the screen where the user is taking this action. The CURRENT screen.
   * Samples: 'Home Screen', 'Invoices', 'My Truck'
   */
  screen_name: null | string;
  /**
   * Internal BEES ID that identifies the wholesaler. It's the vendorId from the Accounts
   * Microservice.
   */
  vendorId: null | string;
  [property: string]: any;
}

/**
 * Triggered when users click on the Credit Seller Show terms button
 */
export interface CreditSellerTerms {
  /**
   * Internal BEES ID that identifies the wholesaler. It's the vendorId from the Accounts
   * Microservice.
   */
  vendorId: null | string;
  [property: string]: any;
}

/**
 * Trigged when the credit is selected.
 */
export interface CreditStatementSelected {
  /**
   * ISO currency - Currency associated with product. Samples: DOP, ZAR
   */
  currency: null | string;
  /**
   * The month and year related to the statement. Samples: '04/2020', '03/2019'
   */
  statement_date: null | string;
  [property: string]: any;
}

/**
 * Triggered when user clicks on "Support" button inside Grupo Modelo Credit Page
 */
export interface CreditSupportButtonClicked {
  /**
   * The POC identifier to which the order is being placed
   */
  account_id: null | string;
  /**
   * The total amount of rotative credit the POC still has to buy from BEES.
   */
  credit_available?: number | null;
  /**
   * The credit balance value in the seller screen. Samples: $ 1702
   */
  credit_balance?: number | null;
  /**
   * Credit value that is pending payment by the account
   */
  credit_consumption?: number | null;
  /**
   * The credit overdue for a given Vendor to the current user
   */
  credit_overdue?: number | null;
  /**
   * Credit limit value provide by vendor to the account spent in orders
   */
  credit_total?: number | null;
  [property: string]: any;
}

/**
 * When there's a failure in the Invoice export, i.e. an API not available
 */
export interface ExportInvoiceFailed {
  /**
   * Communication Service error description for 400 and 500 status. Samples: "EMPTY_FIELDS",
   * "The email request has invalid information."
   */
  failure_reason: null | string;
  /**
   * Invoice ID - generated from ABI Invoice service. Sample: B0101450319
   */
  invoice_id: null | string;
  /**
   * Internal BEES ID that identifies the wholesaler. It's the vendorId from the Accounts
   * Microservice.
   */
  vendorId: null | string;
  [property: string]: any;
}

/**
 * Triggered when the user clicks on the VIEW DETAILS button on the invoices carousel
 */
export interface InvoiceCardDetailsViewed {
  /**
   * Invoice ID - generated from ABI Invoice service. Sample: B0101450319
   */
  invoice_id: null | string;
  /**
   * What is the status of the payment. Samples: OVERDUE
   */
  invoice_overdue_status: InvoiceOverdueStatus | null;
  /**
   * The status of the invoice, generated from the Zone ERP. Samples: Closed
   */
  invoice_status: null | string;
  /**
   * Internal BEES ID that identifies the wholesaler. It's the vendorId from the Accounts
   * Microservice.
   */
  vendorId: null | string;
  [property: string]: any;
}

export enum InvoiceOverdueStatus {
  Overdue = 'OVERDUE',
  Paid = 'PAID',
  Sent = 'SENT',
}

/**
 * Triggered when the user sees an invoice in the invoices carousel
 */
export interface InvoiceCardViewed {
  /**
   * Total amount of cards. It's a number. Samples: 3, 5, 9
   */
  cards_quantity: number | null;
  /**
   * The due date in the given context. It's a date time prop. Samples:
   * 2020-11-11T19:01:40.032Z
   */
  due_date: Date | null;
  /**
   * Invoice ID - generated from ABI Invoice service. Sample: B0101450319
   */
  invoice_id: null | string;
  /**
   * What is the status of the payment. Samples: OVERDUE
   */
  invoice_overdue_status: InvoiceOverdueStatus | null;
  /**
   * The status of the invoice, generated from the Zone ERP. Samples: Closed
   */
  invoice_status: null | string;
  /**
   * The product's ABSOLUTE position in the page. It MUST start at 1, NOT at 0. If it's a
   * pagination context, it won't be position in the given page, it will be ABSOLUTE position
   * across the pages. Samples: 1, 8, 4
   */
  position: number | null;
  /**
   * Internal BEES ID that identifies the wholesaler. It's the vendorId from the Accounts
   * Microservice.
   */
  vendorId: null | string;
  [property: string]: any;
}

/**
 * This event must fire when the user cancels the missing email registration journey by
 * tapping the 'Cancel/back'button
 */
export interface MissingEmailRegistrationCanceled {
  /**
   * What is the name of the button. Samples: Place Order
   */
  button_name: null | string;
  /**
   * What is the name of the screen where the user is taking this action. The CURRENT screen.
   * Samples: 'Home Screen', 'Invoices', 'My Truck'
   */
  screen_name: null | string;
  [property: string]: any;
}

/**
 * This event must fire when the user starts the missing email registration by tapping the
 * 'Register Email' button
 */
export interface MissingEmailRegistrationStarted {
  /**
   * What is the name of the button. Samples: Place Order
   */
  button_name: null | string;
  /**
   * What is the name of the screen where the user is taking this action. The CURRENT screen.
   * Samples: 'Home Screen', 'Invoices', 'My Truck'
   */
  screen_name: null | string;
  [property: string]: any;
}

/**
 * This event must be fired when the user clicks to download the Nota Fiscal (Official Proof
 * of Purchase)
 */
export interface OfficialProofOfPurchaseDownloaded {
  /**
   * Invoice ID - generated from ABI Invoice service. Sample: B0101450319
   */
  invoice_id: null | string;
  /**
   * Internal BEES ID that identifies the wholesaler. It's the vendorId from the Accounts
   * Microservice.
   */
  vendorId: null | string;
  [property: string]: any;
}

/**
 * Fires when user taps the 'Pay All Invoices' button
 */
export interface PayAllInvoices {
  /**
   * The name of the bank in the given context. Samples: Wells Fargo
   */
  bank_name: null | string;
  /**
   * The amount of open invoices in the given context. It's an integer. Samples: 1, 3, 8
   */
  closed_invoices_quantity: number | null;
  /**
   * The amount of open invoices in the given context. It's an integer. Samples: 1, 3, 8
   */
  open_invoices_quantity: number | null;
  /**
   * What is the name of the screen where the user is taking this action. The CURRENT screen.
   * Samples: 'Home Screen', 'Invoices', 'My Truck'
   */
  screen_name: null | string;
  /**
   * Internal BEES ID that identifies the wholesaler. It's the vendorId from the Accounts
   * Microservice.
   */
  vendorId: null | string;
  [property: string]: any;
}

/**
 * Triggered when user clicks on "Pay invoice" button in Invoices List.â€‹
 */
export interface PayInvoiceClicked {
  /**
   * The label of the button in which the user clicked. Samples: "Update", "Cancel"
   */
  button_label: null | string;
  /**
   * What is the name of the button. Samples: Place Order
   */
  button_name: null | string;
  /**
   * What is the name of the screen where the user is taking this action. The CURRENT screen.
   * Samples: 'Home Screen', 'Invoices', 'My Truck'
   */
  screen_name: null | string;
  /**
   * The value stream of the event. It must be an ENUM.
   */
  value_stream: ValueStream;
  [property: string]: any;
}

/**
 * The value stream of the event. It must be an ENUM.
 */
export enum ValueStream {
  Algo = 'ALGO',
  Cart = 'CART',
  Checkout = 'CHECKOUT',
  CustomerSupport = 'CUSTOMER_SUPPORT',
  Deals = 'DEALS',
  DigitalCoupons = 'DIGITAL_COUPONS',
  Homepage = 'HOMEPAGE',
  LinkJbp = 'LINK_JBP',
  LinkOrderTaking = 'LINK_ORDER_TAKING',
  LinkPlanning = 'LINK_PLANNING',
  LinkPostOrder = 'LINK_POST_ORDER',
  Membership = 'MEMBERSHIP',
  MiNegocio = 'MI_NEGOCIO',
  Payments = 'PAYMENTS',
  PostSales = 'POST_SALES',
  ProductPages = 'PRODUCT_PAGES',
  Rewards = 'REWARDS',
  Search = 'SEARCH',
  SocialMedia = 'SOCIAL_MEDIA',
}

/**
 * This event should fire when the user  is in the WebView and clicks on the back button and
 * is redirected to the previous screen, for Checkout Screen
 */
export interface PaymentGatewayWebviewCanceled {
  /**
   * The label of the button in which the user clicked. Samples: "Update", "Cancel"
   */
  button_label: null | string;
  /**
   * What is the name of the button. Samples: Place Order
   */
  button_name: null | string;
  /**
   * Where did the user come from to get to this page/screen. The PREVIOUS screen. Samples:
   * 'Home Screen', 'Invoices', 'My Truck'
   */
  referrer: null | string;
  /**
   * What is the name of the screen where the user is taking this action. The CURRENT screen.
   * Samples: 'Home Screen', 'Invoices', 'My Truck'
   */
  screen_name: null | string;
  [property: string]: any;
}

/**
 * Triggered when an error happens after the user try to submit an order inside the WebView
 * and return to the checkout screen
 */
export interface PaymentGatewayWebviewRequestFailed {
  /**
   * Communication Service error description for 400 and 500 status. Samples: "EMPTY_FIELDS",
   * "The email request has invalid information."
   */
  failure_reason: null | string;
  /**
   * The payment method in the given context. Samples: CASH, CHECK, CREDIT, BOLETO,
   * CREDIT_CARD_POS, BANK_SLIP
   */
  payment_method: PaymentMethod | null;
  /**
   * The payment provider in the given context. Samples: Stripe, Ztech, Banco Popular, Banco
   * Leon, PSE, etc
   */
  payment_provider: string;
  /**
   * Where did the user come from to get to this page/screen. The PREVIOUS screen. Samples:
   * 'Home Screen', 'Invoices', 'My Truck'
   */
  referrer: null | string;
  /**
   * What is the name of the screen where the user is taking this action. The CURRENT screen.
   * Samples: 'Home Screen', 'Invoices', 'My Truck'
   */
  screen_name: null | string;
  /**
   * The start date in the given context. Sample: '2019-12-31T23:59:59Z'
   */
  start_date: Date | null;
  /**
   * Grand total cost after tax and shipping. Samples: 31041.62
   */
  total: number | null;
  /**
   * url in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the
   * url the user will be redirected to. Samples:
   * https://tiendaenlinea.bavaria.co/aguila-rn-330cc-x-30-3128.html
   */
  url: null | string;
  [property: string]: any;
}

/**
 * Triggered when user clicks on the “Process to Payment” button and opens the WebView
 */
export interface PaymentGatewayWebviewStarted {
  /**
   * The payment method in the given context. Samples: CASH, CHECK, CREDIT, BOLETO,
   * CREDIT_CARD_POS, BANK_SLIP
   */
  payment_method: PaymentMethod | null;
  /**
   * The payment provider in the given context. Samples: Stripe, Ztech, Banco Popular, Banco
   * Leon, PSE, etc
   */
  payment_provider: string;
  /**
   * Where did the user come from to get to this page/screen. The PREVIOUS screen. Samples:
   * 'Home Screen', 'Invoices', 'My Truck'
   */
  referrer: null | string;
  /**
   * What is the name of the screen where the user is taking this action. The CURRENT screen.
   * Samples: 'Home Screen', 'Invoices', 'My Truck'
   */
  screen_name: null | string;
  /**
   * The start date in the given context. Sample: '2019-12-31T23:59:59Z'
   */
  start_date: Date | null;
  /**
   * Grand total cost after tax and shipping. Samples: 31041.62
   */
  total: number | null;
  /**
   * url in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the
   * url the user will be redirected to. Samples:
   * https://tiendaenlinea.bavaria.co/aguila-rn-330cc-x-30-3128.html
   */
  url: null | string;
  [property: string]: any;
}

/**
 * Triggered when user click in a button.
 */
export interface PaymentsButtonClicked {
  /**
   * The label of the button in which the user clicked. Samples: "Update", "Cancel"
   */
  button_label: null | string;
  /**
   * What is the name of the button. Samples: Place Order
   */
  button_name: null | string;
  /**
   * Score filter in Credit Management
   */
  credit_score?: null | string;
  /**
   * Fee field from edit recommendation
   */
  fee?: null | string;
  /**
   * Limit field from edit recommendation
   */
  limit?: null | string;
  /**
   * Recommendation filter in Credit Management
   */
  recommendation?: null | string;
  /**
   * Where did the user come from to get to this page/screen. The PREVIOUS screen. Samples:
   * 'Home Screen', 'Invoices', 'My Truck'
   */
  referrer: null | string;
  /**
   * What is the name of the screen where the user is taking this action. The CURRENT screen.
   * Samples: 'Home Screen', 'Invoices', 'My Truck'
   */
  screen_name: null | string;
  /**
   * Statuses included in download
   */
  statuses_included?: null | string;
  /**
   * Unique id of the store. Randomly generated, UUID formatted. Each store is a group of
   * different vendors in the Customer App experience
   */
  store_id: null | string;
  /**
   * Terms field from edit recommendation
   */
  terms?: null | string;
  /**
   * Update filter in Credit Management
   */
  update?: null | string;
  /**
   * The value stream of the event. It must be an ENUM.
   */
  value_stream: ValueStream;
  /**
   * Internal BEES ID that identifies the wholesaler. It's the vendorId from the Accounts
   * Microservice.
   */
  vendorId: null | string;
  [property: string]: any;
}

/**
 * Triggered when user view a specific page.
 */
export interface PaymentsPageViewed {
  /**
   * Users credit available
   */
  available?: null | string;
  /**
   * Users credit available minus consumption
   */
  available_minus_consumption?: null | string;
  /**
   * Users credit balance
   */
  balance?: null | string;
  /**
   * Users credit balance plus consumption
   */
  balance_plus_consumption?: null | string;
  /**
   * Where did the user come from to get to this page/screen. The PREVIOUS screen. Samples:
   * 'Home Screen', 'Invoices', 'My Truck'
   */
  referrer: null | string;
  /**
   * What is the name of the screen where the user is taking this action. The CURRENT screen.
   * Samples: 'Home Screen', 'Invoices', 'My Truck'
   */
  screen_name: null | string;
  /**
   * Unique id of the store. Randomly generated, UUID formatted. Each store is a group of
   * different vendors in the Customer App experience
   */
  store_id: null | string;
  /**
   * The value stream of the event. It must be an ENUM.
   */
  value_stream: ValueStream;
  /**
   * Internal BEES ID that identifies the wholesaler. It's the vendorId from the Accounts
   * Microservice.
   */
  vendorId: null | string;
  [property: string]: any;
}

/**
 * Triggered when user clicks on "Verify code" button inside Phone Code Screen.​
 */
export interface PhoneValidationConfirmed {
  /**
   * The label of the button in which the user clicked. Samples: "Update", "Cancel"
   */
  button_label: null | string;
  /**
   * What is the name of the button. Samples: Place Order
   */
  button_name: null | string;
  /**
   * Where did the user come from to get to this page/screen. The PREVIOUS screen. Samples:
   * 'Home Screen', 'Invoices', 'My Truck'
   */
  referrer: null | string;
  /**
   * What is the name of the screen where the user is taking this action. The CURRENT screen.
   * Samples: 'Home Screen', 'Invoices', 'My Truck'
   */
  screen_name: null | string;
  /**
   * Internal BEES ID that identifies the wholesaler. It's the vendorId from the Accounts
   * Microservice.
   */
  vendorId: null | string;
  [property: string]: any;
}

/**
 * Triggered when users see the error toast inside Phone Number Screen and Phone Code Screen.
 */
export interface PhoneValidationErrorViewed {
  /**
   * Identification of type of phone validation error.
   */
  phone_validation_error: PhoneValidationError | null;
  /**
   * Internal BEES ID that identifies the wholesaler. It's the vendorId from the Accounts
   * Microservice.
   */
  vendorId: null | string;
  [property: string]: any;
}

export enum PhoneValidationError {
  DuplicatedNumber = 'duplicated_number',
  ErrorNumber = 'error_number',
  InvalidCode = 'invalid_code',
  InvalidNumber = 'invalid_number',
}

/**
 * Triggered when user view confirmation toast when a new SMS is sent for verification code.
 */
export interface PhoneValidationNewSMSSentViewed {
  /**
   * Where did the user come from to get to this page/screen. The PREVIOUS screen. Samples:
   * 'Home Screen', 'Invoices', 'My Truck'
   */
  referrer: null | string;
  /**
   * What is the name of the screen where the user is taking this action. The CURRENT screen.
   * Samples: 'Home Screen', 'Invoices', 'My Truck'
   */
  screen_name: null | string;
  /**
   * Internal BEES ID that identifies the wholesaler. It's the vendorId from the Accounts
   * Microservice.
   */
  vendorId: null | string;
  [property: string]: any;
}

/**
 * Triggered when user clicks on "Send code" button inside Phone Number Screen.​
 */
export interface PhoneValidationStarted {
  /**
   * The label of the button in which the user clicked. Samples: "Update", "Cancel"
   */
  button_label: null | string;
  /**
   * What is the name of the button. Samples: Place Order
   */
  button_name: null | string;
  /**
   * Where did the user come from to get to this page/screen. The PREVIOUS screen. Samples:
   * 'Home Screen', 'Invoices', 'My Truck'
   */
  referrer: null | string;
  /**
   * What is the name of the screen where the user is taking this action. The CURRENT screen.
   * Samples: 'Home Screen', 'Invoices', 'My Truck'
   */
  screen_name: null | string;
  /**
   * Internal BEES ID that identifies the wholesaler. It's the vendorId from the Accounts
   * Microservice.
   */
  vendorId: null | string;
  [property: string]: any;
}

/**
 * Triggered when users click on "Show all" button on Credit Web View with Recent
 * Transactions
 */
export interface RecentTransactionsClicked {
  /**
   * The POC identifier to which the order is being placed
   */
  account_id: null | string;
  /**
   * The total amount of rotative credit the POC still has to buy from BEES.
   */
  credit_available?: number | null;
  /**
   * The credit balance value in the seller screen. Samples: $ 1702
   */
  credit_balance?: number | null;
  /**
   * Credit value that is pending payment by the account
   */
  credit_consumption?: number | null;
  /**
   * The credit overdue for a given Vendor to the current user
   */
  credit_overdue?: number | null;
  /**
   * Credit limit value provide by vendor to the account spent in orders
   */
  credit_total?: number | null;
  [property: string]: any;
}

/**
 * Triggered when Credit Web View opens with Recent Transactions feature
 */
export interface RecentTransactionsViewed {
  /**
   * The POC identifier to which the order is being placed
   */
  account_id: null | string;
  /**
   * The total amount of rotative credit the POC still has to buy from BEES.
   */
  credit_available?: number | null;
  /**
   * The credit balance value in the seller screen. Samples: $ 1702
   */
  credit_balance?: number | null;
  /**
   * Credit value that is pending payment by the account
   */
  credit_consumption?: number | null;
  /**
   * The credit overdue for a given Vendor to the current user
   */
  credit_overdue?: number | null;
  /**
   * Credit limit value provide by vendor to the account spent in orders
   */
  credit_total?: number | null;
  [property: string]: any;
}

/**
 * Triggered when user clicks on "Copy" button in reference number card on Bank Transfer
 * Screen
 */
export interface ReferenceNumberCopied {
  /**
   * The label of the button in which the user clicked. Samples: "Update", "Cancel"
   */
  button_label: null | string;
  /**
   * What is the name of the button. Samples: Place Order
   */
  button_name: null | string;
  /**
   * Where did the user come from to get to this page/screen. The PREVIOUS screen. Samples:
   * 'Home Screen', 'Invoices', 'My Truck'
   */
  referrer: null | string;
  /**
   * What is the name of the screen where the user is taking this action. The CURRENT screen.
   * Samples: 'Home Screen', 'Invoices', 'My Truck'
   */
  screen_name: null | string;
  [property: string]: any;
}

/**
 * Triggered when user clicks on "PIN button" inside Model Group Credit Page
 */
export interface ShowCreditPINClicked {
  /**
   * The label of the button in which the user clicked. Samples: "Update", "Cancel"
   */
  button_label: null | string;
  /**
   * What is the name of the button. Samples: Place Order
   */
  button_name: null | string;
  /**
   * Where did the user come from to get to this page/screen. The PREVIOUS screen. Samples:
   * 'Home Screen', 'Invoices', 'My Truck'
   */
  referrer: null | string;
  /**
   * What is the name of the screen where the user is taking this action. The CURRENT screen.
   * Samples: 'Home Screen', 'Invoices', 'My Truck'
   */
  screen_name: null | string;
  [property: string]: any;
}

/**
 * Triggered when user clicks on "Confirm" button inside Terms Conditions Screen.
 */
export interface TermsConditionsConfirmed {
  /**
   * The label of the button in which the user clicked. Samples: "Update", "Cancel"
   */
  button_label: null | string;
  /**
   * What is the name of the button. Samples: Place Order
   */
  button_name: null | string;
  /**
   * Where did the user come from to get to this page/screen. The PREVIOUS screen. Samples:
   * 'Home Screen', 'Invoices', 'My Truck'
   */
  referrer: null | string;
  /**
   * What is the name of the screen where the user is taking this action. The CURRENT screen.
   * Samples: 'Home Screen', 'Invoices', 'My Truck'
   */
  screen_name: null | string;
  /**
   * Internal BEES ID that identifies the wholesaler. It's the vendorId from the Accounts
   * Microservice.
   */
  vendorId: null | string;
  [property: string]: any;
}

/**
 * Triggered when user enters on Terms Conditions Screen.​
 */
export interface TermsConditionsStarted {
  /**
   * What is the name of the screen where the user is taking this action. The CURRENT screen.
   * Samples: 'Home Screen', 'Invoices', 'My Truck'
   */
  screen_name: null | string;
  /**
   * Internal BEES ID that identifies the wholesaler. It's the vendorId from the Accounts
   * Microservice.
   */
  vendorId: null | string;
  [property: string]: any;
}

/**
 * Triggered when Transaction History opens after users click on Show all button
 */
export interface TransactionHistoryViewed {
  /**
   * The POC identifier to which the order is being placed
   */
  account_id: null | string;
  /**
   * The total amount of rotative credit the POC still has to buy from BEES.
   */
  credit_available?: number | null;
  /**
   * The credit balance value in the seller screen. Samples: $ 1702
   */
  credit_balance?: number | null;
  /**
   * Credit value that is pending payment by the account
   */
  credit_consumption?: number | null;
  /**
   * The credit overdue for a given Vendor to the current user
   */
  credit_overdue?: number | null;
  /**
   * Credit limit value provide by vendor to the account spent in orders
   */
  credit_total?: number | null;
  [property: string]: any;
}

/**
 * Ajv is a peer dependency for development builds. It's used to apply run-time validation
 * to message payloads before passing them on to the underlying analytics instance.
 *
 * Note that the production bundle does not depend on Ajv.
 *
 * You can install it with: `npm install --save-dev ajv`.
 */
import Ajv, { ErrorObject } from 'ajv';

/**
 * The analytics.js snippet should be available via window.analytics.
 * You can install it by following instructions at: https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/
 * Make sure to also include the TypeScript declarations with: `npm install @segment/analytics-next` (install with --save-dev for type definitions only).
 *
 * If you don't want to use the snippet, you can also install the `@segment/analytics-next` library as a *production* dependency and use it like this:
 * ```ts
 * import { AnalyticsBrowser } from '@segment/analytics-next'
 * import { setTypewriterOptions } from './analytics'
 *
 * const analytics = AnalyticsBrowser.load({ writeKey: 'SEGMENT_WRITE_KEY' })
 *
 * setTypewriterOptions({ analytics: analytics })
 */
import type {
  Analytics,
  AnalyticsBrowser,
  AnalyticsSnippet,
  Options,
} from '@segment/analytics-next';

declare global {
  interface Window {
    analytics: AnalyticsSnippet;
  }
}

type AnyAnalytics = AnalyticsSnippet | Analytics | AnalyticsBrowser;

/** The callback exposed by analytics.js. */
export type Callback = () => void;

export type ViolationHandler = (message: Record<string, any>, violations: ErrorObject[]) => void;

/**
 * The default handler that is fired if none is supplied with setTypewriterOptions.
 * This handler will log a warning message to the console.
 */
export const defaultValidationErrorHandler: ViolationHandler = (message, violations) => {
  const msg = JSON.stringify(
    {
      type: 'Typewriter JSON Schema Validation Error',
      description:
        `You made an analytics call (${message.event}) using Typewriter that doesn't match the ` +
        'Tracking Plan spec.',
      errors: violations,
    },
    undefined,
    2
  );

  console.warn(msg);
};

let onViolation = defaultValidationErrorHandler;

let analytics: () => AnyAnalytics | undefined = () => {
  return window.analytics;
};

/** Options to customize the runtime behavior of a Typewriter client. */
export interface TypewriterOptions {
  /**
   * Underlying analytics instance where analytics calls are forwarded on to.
   * Defaults to window.analytics.
   */
  analytics?: AnyAnalytics;
  /**
   * Handler fired when if an event does not match its spec. This handler
   * does not fire in production mode, because it requires inlining the full
   * JSON Schema spec for each event in your Tracking Plan.
   *
   * By default, it will throw errors if NODE_ENV = "test" so that tests will fail
   * if a message does not match the spec. Otherwise, errors will be logged to stderr.
   */
  onViolation?: ViolationHandler;
}

/**
 * Updates the run-time configuration of this Typewriter client.
 *
 * @param {TypewriterOptions} options - the options to upsert
 *
 * @typedef {Object} TypewriterOptions
 * @property {AnyAnalytics} [analytics] - Underlying analytics instance where analytics
 * 		calls are forwarded on to. Defaults to window.analytics.
 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
 * 		will be logged to stderr.
 */
export function setTypewriterOptions(options: TypewriterOptions) {
  analytics = options.analytics ? () => options.analytics || window.analytics : analytics;
  onViolation = options.onViolation || onViolation;
}

/**
 * Validates a message against a JSON Schema using Ajv. If the message
 * is invalid, the `onViolation` handler will be called.
 */
function validateAgainstSchema(message: Record<string, any>, schema: object) {
  const ajv = new Ajv({ allErrors: true, verbose: true });

  if (!ajv.validate(schema, message) && ajv.errors) {
    onViolation(message, ajv.errors);
  }
}

/**
 * Helper to attach metadata on Typewriter to outbound requests.
 * This is used for attribution and debugging by the Segment team.
 */
function withTypewriterContext(message: Options = {}): Options {
  return {
    ...message,
    context: {
      ...(message.context || {}),
      typewriter: {
        language: 'typescript',
        version: '9.1.0',
      },
    },
  };
}

/**
 * Fires a 'BankAccountNumberCopied' track call.
 *
 * @param BankAccountNumberCopied props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bankAccountNumberCopied(
  props: BankAccountNumberCopied,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Bank_Account_Number_Copied',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Triggered when user clicks on "Copy" button in account number card on Bank Transfer Screen',
    properties: {
      button_label: {
        description:
          'The label of the button in which the user clicked. Samples: "Update", "Cancel"',
        type: ['string', 'null'],
      },
      button_name: {
        description: 'What is the name of the button. Samples: Place Order',
        type: ['string', 'null'],
      },
      referrer: {
        description:
          "Where did the user come from to get to this page/screen. The PREVIOUS screen. Samples: 'Home Screen', 'Invoices', 'My Truck'",
        type: ['string', 'null'],
      },
      screen_name: {
        description:
          "What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen', 'Invoices', 'My Truck'",
        type: ['string', 'null'],
      },
    },
    required: ['button_label', 'button_name', 'referrer', 'screen_name'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Bank Account Number Copied',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'BankListViewed' track call.
 *
 * @param BankListViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bankListViewed(
  props: BankListViewed,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Bank_List_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When the user sees a list of Banks',
    properties: {
      _: {
        type: 'null',
        $id: '/properties/_',
        description:
          'This property is added as a workaround to generate type for events without properties',
      },
    },
    required: ['properties'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Bank List Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'BankSelected' track call.
 *
 * @param BankSelected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bankSelected(props: BankSelected, options?: Options, callback?: Callback): void {
  const schema = {
    $id: 'Bank_Selected',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When the user selects a bank',
    properties: {
      bank_id: {
        description: 'The ID of the bank in the given context.',
        type: ['string', 'null'],
      },
      bank_name: {
        description: 'The name of the bank in the given context. Samples: Wells Fargo',
        type: ['string', 'null'],
      },
      image_url: {
        description:
          'Image URL of the product. Samples: "https://www.socioscerveceria.com.do/media/catalog/product/m/a/malta_morena_8_onz_pla',
        type: ['string', 'null'],
      },
      position: {
        description:
          "The product's ABSOLUTE position in the page. It MUST start at 1, NOT at 0. If it's a pagination context, it won't be position in the given page, it will be ABSOLUTE position across the pages. Samples: 1, 8, 4",
        type: ['integer', 'null'],
      },
      screen_name: {
        description:
          "What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen', 'Invoices', 'My Truck'",
        type: ['string', 'null'],
      },
    },
    required: ['bank_id', 'bank_name', 'image_url', 'position', 'screen_name'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Bank Selected',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'BankTransferCodeViewed' track call.
 *
 * @param BankTransferCodeViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bankTransferCodeViewed(
  props: BankTransferCodeViewed,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Bank_Transfer_Code_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When the screen with the bank transfer code is loaded',
    properties: {
      currency: {
        description: 'ISO currency - Currency associated with product. Samples: DOP, ZAR',
        type: ['string', 'null'],
      },
      due_date: {
        description:
          "The due date in the given context. It's a date time prop. Samples: 2020-11-11T19:01:40.032Z",
        format: 'date-time',
        type: ['string', 'null'],
      },
      invoice_amount_due: {
        description: "The amount of invoice due. It's a number. Samples: '10450,50'",
        type: ['number', 'null'],
      },
      invoice_id: {
        description: 'Invoice ID - generated from ABI Invoice service. Sample: B0101450319',
        type: ['string', 'null'],
      },
      payment_method: {
        description:
          'The payment method in the given context. Samples: CASH, CHECK, CREDIT, BOLETO, CREDIT_CARD_POS, BANK_SLIP',
        enum: [
          'BANK_DEPOSIT',
          'BANK_SLIP',
          'BANK_SLIP_INSTALLMENT',
          'BANK_TRANSFER',
          'BOLETO',
          'CASH',
          'CHECK',
          'CREDIT',
          'CREDIT_CARD_ONLINE',
          'CREDIT_CARD_POS',
          'DIRECT_PAY_AT_DELIVERY',
          'GPS',
          'PIX_AT_DELIVERY',
          'PIX_ONLINE',
          'RECEIVABLE_AS_CREDIT',
          null,
        ],
        type: ['string', 'null'],
      },
    },
    required: ['currency', 'due_date', 'invoice_amount_due', 'invoice_id', 'payment_method'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Bank Transfer Code Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'BankTransferInfoClicked' track call.
 *
 * @param BankTransferInfoClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bankTransferInfoClicked(
  props: BankTransferInfoClicked,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Bank_Transfer_Info_Clicked',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Triggered when user clicks on "Bank Transfer Info" button inside Grupo Modelo Credit Page',
    properties: {
      button_label: {
        description:
          'The label of the button in which the user clicked. Samples: "Update", "Cancel"',
        type: ['string', 'null'],
      },
      button_name: {
        description: 'What is the name of the button. Samples: Place Order',
        type: ['string', 'null'],
      },
      referrer: {
        description:
          "Where did the user come from to get to this page/screen. The PREVIOUS screen. Samples: 'Home Screen', 'Invoices', 'My Truck'",
        type: ['string', 'null'],
      },
      screen_name: {
        description:
          "What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen', 'Invoices', 'My Truck'",
        type: ['string', 'null'],
      },
    },
    required: ['button_label', 'button_name', 'referrer', 'screen_name'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Bank Transfer Info Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'BankWebviewRequestFailed' track call.
 *
 * @param BankWebviewRequestFailed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bankWebviewRequestFailed(
  props: BankWebviewRequestFailed,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Bank_Webview_Request_Failed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'This event must fire when the bank webview request fails and the API returns an error message',
    properties: {
      bank_name: {
        description: 'The name of the bank in the given context. Samples: Wells Fargo',
        type: ['string', 'null'],
      },
      failure_reason: {
        description:
          '\nCommunication Service error description for 400 and 500 status. Samples: "EMPTY_FIELDS", "The email request has invalid information."',
        type: ['string', 'null'],
      },
      screen_name: {
        description:
          "What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen', 'Invoices', 'My Truck'",
        type: ['string', 'null'],
      },
    },
    required: ['bank_name', 'failure_reason', 'screen_name'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Bank Webview Request Failed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'BeneficiaryNameCopied' track call.
 *
 * @param BeneficiaryNameCopied props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function beneficiaryNameCopied(
  props: BeneficiaryNameCopied,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Beneficiary_Name_Copied',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Triggered when user clicks on "Copy" button in beneficary\'s name card on Bank Transfer Screen',
    properties: {
      button_label: {
        description:
          'The label of the button in which the user clicked. Samples: "Update", "Cancel"',
        type: ['string', 'null'],
      },
      button_name: {
        description: 'What is the name of the button. Samples: Place Order',
        type: ['string', 'null'],
      },
      referrer: {
        description:
          "Where did the user come from to get to this page/screen. The PREVIOUS screen. Samples: 'Home Screen', 'Invoices', 'My Truck'",
        type: ['string', 'null'],
      },
      screen_name: {
        description:
          "What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen', 'Invoices', 'My Truck'",
        type: ['string', 'null'],
      },
    },
    required: ['button_label', 'button_name', 'referrer', 'screen_name'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Beneficiary Name Copied',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'BillPaymentPDFDownloaded' track call.
 *
 * @param BillPaymentPDFDownloaded props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function billPaymentPDFDownloaded(
  props: BillPaymentPDFDownloaded,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Bill_Payment_PDF_Downloaded',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      "This event must be fired when the user clicks to download the PDF of the 'Boleto' (the Bill Payment)",
    properties: {
      invoice_id: {
        description: 'Invoice ID - generated from ABI Invoice service. Sample: B0101450319',
        type: ['string', 'null'],
      },
      screen_name: {
        description:
          "What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen', 'Invoices', 'My Truck'",
        type: ['string', 'null'],
      },
      vendorId: {
        description:
          "Internal BEES ID that identifies the wholesaler. It's the vendorId from the Accounts Microservice.",
        type: ['string', 'null'],
      },
    },
    required: ['invoice_id', 'screen_name', 'vendorId'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Bill Payment PDF Downloaded',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'CreditBlockedReasonClicked' track call.
 *
 * @param CreditBlockedReasonClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function creditBlockedReasonClicked(
  props: CreditBlockedReasonClicked,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Credit_Blocked_Reason_Clicked',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered in credit summary screen when user clicks on credit blocked reason.',
    properties: {
      button_label: {
        description:
          'The label of the button in which the user clicked. Samples: "Update", "Cancel"',
        type: ['string', 'null'],
      },
      button_name: {
        description: 'What is the name of the button. Samples: Place Order',
        type: ['string', 'null'],
      },
      referrer: {
        description:
          "Where did the user come from to get to this page/screen. The PREVIOUS screen. Samples: 'Home Screen', 'Invoices', 'My Truck'",
        type: ['string', 'null'],
      },
      screen_name: {
        description:
          "What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen', 'Invoices', 'My Truck'",
        type: ['string', 'null'],
      },
      vendorId: {
        description:
          "Internal BEES ID that identifies the wholesaler. It's the vendorId from the Accounts Microservice.",
        type: ['string', 'null'],
      },
    },
    required: ['button_label', 'button_name', 'referrer', 'screen_name', 'vendorId'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Credit Blocked Reason Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'CreditBlockedVendorClicked' track call.
 *
 * @param CreditBlockedVendorClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function creditBlockedVendorClicked(
  props: CreditBlockedVendorClicked,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Credit_Blocked_Vendor_Clicked',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Triggered in seller selection screen when user clicks on credit vendor that is with status blocked',
    properties: {
      referrer: {
        description:
          "Where did the user come from to get to this page/screen. The PREVIOUS screen. Samples: 'Home Screen', 'Invoices', 'My Truck'",
        type: ['string', 'null'],
      },
      screen_name: {
        description:
          "What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen', 'Invoices', 'My Truck'",
        type: ['string', 'null'],
      },
      vendorId: {
        description:
          "Internal BEES ID that identifies the wholesaler. It's the vendorId from the Accounts Microservice.",
        type: ['string', 'null'],
      },
    },
    required: ['referrer', 'screen_name', 'vendorId'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Credit Blocked Vendor Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'CreditEmptyStateViewed' track call.
 *
 * @param CreditEmptyStateViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function creditEmptyStateViewed(
  props: CreditEmptyStateViewed,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Credit_Empty_State_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when users see the empty state screen to access the Credit Web View',
    properties: {
      account_id: {
        description: 'The POC identifier to which the order is being placed',
        type: ['string', 'null'],
      },
      credit_available: {
        description: 'The total amount of rotative credit the POC still has to buy from BEES. ',
        type: ['number', 'null'],
      },
      credit_balance: {
        description: 'The credit balance value in the seller screen. Samples: $ 1702',
        type: ['number', 'null'],
      },
      credit_consumption: {
        description: 'Credit value that is pending payment by the account',
        type: ['number', 'null'],
      },
      credit_overdue: {
        description: 'The credit overdue for a given Vendor to the current user',
        type: ['number', 'null'],
      },
      credit_total: {
        description: 'Credit limit value provide by vendor to the account spent in orders',
        type: ['number', 'null'],
      },
    },
    required: ['account_id'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Credit Empty State Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'CreditErrorReturnViewed' track call.
 *
 * @param CreditErrorReturnViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function creditErrorReturnViewed(
  props: CreditErrorReturnViewed,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Credit_Error_Return_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when users see the error screen to access Credit Web View',
    properties: {
      account_id: {
        description: 'The POC identifier to which the order is being placed',
        type: ['string', 'null'],
      },
      credit_available: {
        description: 'The total amount of rotative credit the POC still has to buy from BEES. ',
        type: ['number', 'null'],
      },
      credit_balance: {
        description: 'The credit balance value in the seller screen. Samples: $ 1702',
        type: ['number', 'null'],
      },
      credit_consumption: {
        description: 'Credit value that is pending payment by the account',
        type: ['number', 'null'],
      },
      credit_overdue: {
        description: 'The credit overdue for a given Vendor to the current user',
        type: ['number', 'null'],
      },
      credit_total: {
        description: 'Credit limit value provide by vendor to the account spent in orders',
        type: ['number', 'null'],
      },
    },
    required: ['account_id'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Credit Error Return Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'CreditLineActivationCanceled' track call.
 *
 * @param CreditLineActivationCanceled props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function creditLineActivationCanceled(
  props: CreditLineActivationCanceled,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Credit_Line_Activation_Canceled',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When user clicks on "Cancel" button inside "Activate Credit Line" modal',
    properties: {
      button_label: {
        description:
          'The label of the button in which the user clicked. Samples: "Update", "Cancel"',
        type: ['string', 'null'],
      },
      button_name: {
        description: 'What is the name of the button. Samples: Place Order',
        type: ['string', 'null'],
      },
      referrer: {
        description:
          "Where did the user come from to get to this page/screen. The PREVIOUS screen. Samples: 'Home Screen', 'Invoices', 'My Truck'",
        type: ['string', 'null'],
      },
      screen_name: {
        description:
          "What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen', 'Invoices', 'My Truck'",
        type: ['string', 'null'],
      },
    },
    required: ['button_label', 'button_name', 'referrer', 'screen_name'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Credit Line Activation Canceled',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'CreditLineActivationClicked' track call.
 *
 * @param CreditLineActivationClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function creditLineActivationClicked(
  props: CreditLineActivationClicked,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Credit_Line_Activation_Clicked',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When user clicks on "Activate Credit Line" button inside Credit Page',
    properties: {
      button_label: {
        description:
          'The label of the button in which the user clicked. Samples: "Update", "Cancel"',
        type: ['string', 'null'],
      },
      button_name: {
        description: 'What is the name of the button. Samples: Place Order',
        type: ['string', 'null'],
      },
      referrer: {
        description:
          "Where did the user come from to get to this page/screen. The PREVIOUS screen. Samples: 'Home Screen', 'Invoices', 'My Truck'",
        type: ['string', 'null'],
      },
      screen_name: {
        description:
          "What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen', 'Invoices', 'My Truck'",
        type: ['string', 'null'],
      },
      vendorId: {
        description:
          "Internal BEES ID that identifies the wholesaler. It's the vendorId from the Accounts Microservice.",
        type: ['string', 'null'],
      },
    },
    required: ['button_label', 'button_name', 'referrer', 'screen_name', 'vendorId'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Credit Line Activation Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'CreditLineActivationConfirmed' track call.
 *
 * @param CreditLineActivationConfirmed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function creditLineActivationConfirmed(
  props: CreditLineActivationConfirmed,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Credit_Line_Activation_Confirmed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When user clicks on "Ok, continue" button inside "Activate Credit Line" modal',
    properties: {
      button_label: {
        description:
          'The label of the button in which the user clicked. Samples: "Update", "Cancel"',
        type: ['string', 'null'],
      },
      button_name: {
        description: 'What is the name of the button. Samples: Place Order',
        type: ['string', 'null'],
      },
      referrer: {
        description:
          "Where did the user come from to get to this page/screen. The PREVIOUS screen. Samples: 'Home Screen', 'Invoices', 'My Truck'",
        type: ['string', 'null'],
      },
      screen_name: {
        description:
          "What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen', 'Invoices', 'My Truck'",
        type: ['string', 'null'],
      },
    },
    required: ['button_label', 'button_name', 'referrer', 'screen_name'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Credit Line Activation Confirmed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'CreditLineActivationPopUpViewed' track call.
 *
 * @param CreditLineActivationPopUpViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function creditLineActivationPopUpViewed(
  props: CreditLineActivationPopUpViewed,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Credit_Line_Activation_Pop_Up_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Triggered when user clicks on "Credit" button in My Account Hub and it\'s available to interact with the feature.​',
    properties: {
      screen_name: {
        description:
          "What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen', 'Invoices', 'My Truck'",
        type: ['string', 'null'],
      },
      vendorId: {
        description:
          "Internal BEES ID that identifies the wholesaler. It's the vendorId from the Accounts Microservice.",
        type: ['string', 'null'],
      },
    },
    required: ['screen_name', 'vendorId'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Credit Line Activation Pop Up Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'CreditLineActivationResultHelpClicked' track call.
 *
 * @param CreditLineActivationResultHelpClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function creditLineActivationResultHelpClicked(
  props: CreditLineActivationResultHelpClicked,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Credit_Line_Activation_Result_Help_Clicked',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Triggered when user clicks on "Get help" button inside request in under review or not approved.',
    properties: {
      credit_total: {
        description: 'Credit limit value provide by vendor to the account spent in orders',
        type: ['number', 'null'],
      },
      result_type: {
        description: 'Identification of type of credit line error',
        enum: ['DOCUMENTS_FAILED', 'NOT_APPROVED', 'NOT_OWNER', 'UNDER_REVIEW', null],
        type: ['string', 'null'],
      },
      vendorId: {
        description:
          "Internal BEES ID that identifies the wholesaler. It's the vendorId from the Accounts Microservice.",
        type: ['string', 'null'],
      },
    },
    required: ['result_type', 'vendorId'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Credit Line Activation Result Help Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'CreditLineActivationResultShowed' track call.
 *
 * @param CreditLineActivationResultShowed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function creditLineActivationResultShowed(
  props: CreditLineActivationResultShowed,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Credit_Line_Activation_Result_Showed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Triggered when user pass through Credit Line journey and request is in under review, approved or not approved.',
    properties: {
      result_type: {
        description: 'Identification of type of credit line error',
        enum: ['DOCUMENTS_FAILED', 'NOT_APPROVED', 'NOT_OWNER', 'UNDER_REVIEW', null],
        type: ['string', 'null'],
      },
      vendorId: {
        description:
          "Internal BEES ID that identifies the wholesaler. It's the vendorId from the Accounts Microservice.",
        type: ['string', 'null'],
      },
    },
    required: ['result_type', 'vendorId'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Credit Line Activation Result Showed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'CreditLineActivationStarted' track call.
 *
 * @param CreditLineActivationStarted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function creditLineActivationStarted(
  props: CreditLineActivationStarted,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Credit_Line_Activation_Started',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When user clicks on "Start activation" button inside "Activate Credit Line" screen',
    properties: {
      button_label: {
        description:
          'The label of the button in which the user clicked. Samples: "Update", "Cancel"',
        type: ['string', 'null'],
      },
      button_name: {
        description: 'What is the name of the button. Samples: Place Order',
        type: ['string', 'null'],
      },
      referrer: {
        description:
          "Where did the user come from to get to this page/screen. The PREVIOUS screen. Samples: 'Home Screen', 'Invoices', 'My Truck'",
        type: ['string', 'null'],
      },
      screen_name: {
        description:
          "What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen', 'Invoices', 'My Truck'",
        type: ['string', 'null'],
      },
      vendorId: {
        description:
          "Internal BEES ID that identifies the wholesaler. It's the vendorId from the Accounts Microservice.",
        type: ['string', 'null'],
      },
    },
    required: ['button_label', 'button_name', 'referrer', 'screen_name', 'vendorId'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Credit Line Activation Started',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'CreditLineDocumentsSubmittedClicked' track call.
 *
 * @param CreditLineDocumentsSubmittedClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function creditLineDocumentsSubmittedClicked(
  props: CreditLineDocumentsSubmittedClicked,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Credit_Line_Documents_Submitted_Clicked',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      "Triggered when user clicks on 'Go back to Credit' inside Credit Documents Submitted Screen.",
    properties: {
      button_label: {
        description:
          'The label of the button in which the user clicked. Samples: "Update", "Cancel"',
        type: ['string', 'null'],
      },
      button_name: {
        description: 'What is the name of the button. Samples: Place Order',
        type: ['string', 'null'],
      },
      referrer: {
        description:
          "Where did the user come from to get to this page/screen. The PREVIOUS screen. Samples: 'Home Screen', 'Invoices', 'My Truck'",
        type: ['string', 'null'],
      },
      screen_name: {
        description:
          "What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen', 'Invoices', 'My Truck'",
        type: ['string', 'null'],
      },
      vendorId: {
        description:
          "Internal BEES ID that identifies the wholesaler. It's the vendorId from the Accounts Microservice.",
        type: ['string', 'null'],
      },
    },
    required: ['button_label', 'button_name', 'referrer', 'screen_name', 'vendorId'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Credit Line Documents Submitted Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'CreditLineDocumentsSubmittedViewed' track call.
 *
 * @param CreditLineDocumentsSubmittedViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function creditLineDocumentsSubmittedViewed(
  props: CreditLineDocumentsSubmittedViewed,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Credit_Line_Documents_Submitted_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when Credit Documents Submitted Screen opens.​',
    properties: {
      referrer: {
        description:
          "Where did the user come from to get to this page/screen. The PREVIOUS screen. Samples: 'Home Screen', 'Invoices', 'My Truck'",
        type: ['string', 'null'],
      },
      screen_name: {
        description:
          "What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen', 'Invoices', 'My Truck'",
        type: ['string', 'null'],
      },
      vendorId: {
        description:
          "Internal BEES ID that identifies the wholesaler. It's the vendorId from the Accounts Microservice.",
        type: ['string', 'null'],
      },
    },
    required: ['referrer', 'screen_name', 'vendorId'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Credit Line Documents Submitted Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'CreditLineExitModalShowed' track call.
 *
 * @param CreditLineExitModalShowed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function creditLineExitModalShowed(
  props: CreditLineExitModalShowed,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Credit_Line_Exit_Modal_Showed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Triggered when user clicks on "X" button inside the journey and Exit Modal opens.​',
    properties: {
      button_label: {
        description:
          'The label of the button in which the user clicked. Samples: "Update", "Cancel"',
        type: ['string', 'null'],
      },
      button_name: {
        description: 'What is the name of the button. Samples: Place Order',
        type: ['string', 'null'],
      },
      referrer: {
        description:
          "Where did the user come from to get to this page/screen. The PREVIOUS screen. Samples: 'Home Screen', 'Invoices', 'My Truck'",
        type: ['string', 'null'],
      },
      screen_name: {
        description:
          "What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen', 'Invoices', 'My Truck'",
        type: ['string', 'null'],
      },
      vendorId: {
        description:
          "Internal BEES ID that identifies the wholesaler. It's the vendorId from the Accounts Microservice.",
        type: ['string', 'null'],
      },
    },
    required: ['button_label', 'button_name', 'referrer', 'screen_name', 'vendorId'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Credit Line Exit Modal Showed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'CreditLinePageErrorShowed' track call.
 *
 * @param CreditLinePageErrorShowed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function creditLinePageErrorShowed(
  props: CreditLinePageErrorShowed,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Credit_Line_Page_Error_Showed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Triggered when response between Phone Number, Phone Code and Terms Conditions screens is unexpected.',
    properties: {
      referrer: {
        description:
          "Where did the user come from to get to this page/screen. The PREVIOUS screen. Samples: 'Home Screen', 'Invoices', 'My Truck'",
        type: ['string', 'null'],
      },
      vendorId: {
        description:
          "Internal BEES ID that identifies the wholesaler. It's the vendorId from the Accounts Microservice.",
        type: ['string', 'null'],
      },
    },
    required: ['referrer', 'vendorId'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Credit Line Page Error Showed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'CreditLoadingViewed' track call.
 *
 * @param CreditLoadingViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function creditLoadingViewed(
  props: CreditLoadingViewed,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Credit_Loading_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when users see the loading screen to access the Credit Web View',
    properties: {
      account_id: {
        description: 'The POC identifier to which the order is being placed',
        type: ['string', 'null'],
      },
      credit_available: {
        description: 'The total amount of rotative credit the POC still has to buy from BEES. ',
        type: ['number', 'null'],
      },
      credit_balance: {
        description: 'The credit balance value in the seller screen. Samples: $ 1702',
        type: ['number', 'null'],
      },
      credit_consumption: {
        description: 'Credit value that is pending payment by the account',
        type: ['number', 'null'],
      },
      credit_overdue: {
        description: 'The credit overdue for a given Vendor to the current user',
        type: ['number', 'null'],
      },
      credit_total: {
        description: 'Credit limit value provide by vendor to the account spent in orders',
        type: ['number', 'null'],
      },
    },
    required: ['account_id'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Credit Loading Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'CreditPartialInfoExit' track call.
 *
 * @param CreditPartialInfoExit props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function creditPartialInfoExit(
  props: CreditPartialInfoExit,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Credit_Partial_Info_Exit',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Triggered when users click on "X" inside the the banner notification on Credit Web View',
    properties: {
      account_id: {
        description: 'The POC identifier to which the order is being placed',
        type: ['string', 'null'],
      },
      credit_available: {
        description: 'The total amount of rotative credit the POC still has to buy from BEES. ',
        type: ['number', 'null'],
      },
      credit_balance: {
        description: 'The credit balance value in the seller screen. Samples: $ 1702',
        type: ['number', 'null'],
      },
      credit_consumption: {
        description: 'Credit value that is pending payment by the account',
        type: ['number', 'null'],
      },
      credit_overdue: {
        description: 'The credit overdue for a given Vendor to the current user',
        type: ['number', 'null'],
      },
      credit_total: {
        description: 'Credit limit value provide by vendor to the account spent in orders',
        type: ['number', 'null'],
      },
    },
    required: ['account_id'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Credit Partial Info Exit',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'CreditPartialInfoViewed' track call.
 *
 * @param CreditPartialInfoViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function creditPartialInfoViewed(
  props: CreditPartialInfoViewed,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Credit_Partial_Info_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when users see the banner notification to access the Credit Web View',
    properties: {
      account_id: {
        description: 'The POC identifier to which the order is being placed',
        type: ['string', 'null'],
      },
      credit_available: {
        description: 'The total amount of rotative credit the POC still has to buy from BEES. ',
        type: ['number', 'null'],
      },
      credit_balance: {
        description: 'The credit balance value in the seller screen. Samples: $ 1702',
        type: ['number', 'null'],
      },
      credit_consumption: {
        description: 'Credit value that is pending payment by the account',
        type: ['number', 'null'],
      },
      credit_overdue: {
        description: 'The credit overdue for a given Vendor to the current user',
        type: ['number', 'null'],
      },
      credit_total: {
        description: 'Credit limit value provide by vendor to the account spent in orders',
        type: ['number', 'null'],
      },
    },
    required: ['account_id'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Credit Partial Info Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'CreditSSOLoadingViewed' track call.
 *
 * @param CreditSSOLoadingViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function creditSSOLoadingViewed(
  props: CreditSSOLoadingViewed,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Credit_SSO_Loading_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Triggered when users see the SSO loading screen during the Credit Web View journey',
    properties: {
      account_id: {
        description: 'The POC identifier to which the order is being placed',
        type: ['string', 'null'],
      },
      credit_available: {
        description: 'The total amount of rotative credit the POC still has to buy from BEES. ',
        type: ['number', 'null'],
      },
      credit_balance: {
        description: 'The credit balance value in the seller screen. Samples: $ 1702',
        type: ['number', 'null'],
      },
      credit_consumption: {
        description: 'Credit value that is pending payment by the account',
        type: ['number', 'null'],
      },
      credit_overdue: {
        description: 'The credit overdue for a given Vendor to the current user',
        type: ['number', 'null'],
      },
      credit_total: {
        description: 'Credit limit value provide by vendor to the account spent in orders',
        type: ['number', 'null'],
      },
    },
    required: ['account_id'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Credit SSO Loading Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'CreditSellerExperimentFeatureClicked' track call.
 *
 * @param CreditSellerExperimentFeatureClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function creditSellerExperimentFeatureClicked(
  props: CreditSellerExperimentFeatureClicked,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Credit_Seller_Experiment_Feature_Clicked',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when users begins interacting with the Experimental Feature',
    properties: {
      credit_available: {
        description: 'The total amount of rotative credit the POC still has to buy from BEES. ',
        type: ['number', 'null'],
      },
      credit_balance: {
        description: 'The credit balance value in the seller screen. Samples: $ 1702',
        type: ['number', 'null'],
      },
      credit_limit: {
        description: 'The credit limit value in the seller screen. Samples: $ 1702',
        type: ['number', 'null'],
      },
      credit_overdue: {
        description: 'The credit overdue for a given Vendor to the current user',
        type: ['number', 'null'],
      },
      credit_vendorid: {
        description: 'The credit vendor unique ID for a given Vendor to the current user',
        type: ['string', 'null'],
      },
      feature_name: {
        description: 'internal identification of the experimental feature name',
        type: ['string', 'null'],
      },
      feature_type: {
        description: 'internal identification of the experimental feature type',
        enum: ['BUTTON', 'POP_UP', null],
        type: ['string', 'null'],
      },
      optimizely_audience_id: {
        description: 'internal identification of the experimental feature audience ID on Optmizely',
        type: ['string', 'null'],
      },
    },
    required: [
      'credit_limit',
      'credit_vendorid',
      'feature_name',
      'feature_type',
      'optimizely_audience_id',
    ],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Credit Seller Experiment Feature Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'CreditSellerExperimentFeatureCompleted' track call.
 *
 * @param CreditSellerExperimentFeatureCompleted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function creditSellerExperimentFeatureCompleted(
  props: CreditSellerExperimentFeatureCompleted,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Credit_Seller_Experiment_Feature_Completed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when users completed interacting with the Experimental Feature',
    properties: {
      credit_available: {
        description: 'The total amount of rotative credit the POC still has to buy from BEES. ',
        type: ['number', 'null'],
      },
      credit_balance: {
        description: 'The credit balance value in the seller screen. Samples: $ 1702',
        type: ['number', 'null'],
      },
      credit_limit: {
        description: 'The credit limit value in the seller screen. Samples: $ 1702',
        type: ['number', 'null'],
      },
      credit_overdue: {
        description: 'The credit overdue for a given Vendor to the current user',
        type: ['number', 'null'],
      },
      credit_vendorid: {
        description: 'The credit vendor unique ID for a given Vendor to the current user',
        type: ['string', 'null'],
      },
      feature_name: {
        description: 'internal identification of the experimental feature name',
        type: ['string', 'null'],
      },
      feature_type: {
        description: 'internal identification of the experimental feature type',
        enum: ['BUTTON', 'POP_UP', null],
        type: ['string', 'null'],
      },
      optimizely_audience_id: {
        description: 'internal identification of the experimental feature audience ID on Optmizely',
        type: ['string', 'null'],
      },
    },
    required: [
      'credit_limit',
      'credit_vendorid',
      'feature_name',
      'feature_type',
      'optimizely_audience_id',
    ],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Credit Seller Experiment Feature Completed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'CreditSellerExperimentFeatureInteracted' track call.
 *
 * @param CreditSellerExperimentFeatureInteracted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function creditSellerExperimentFeatureInteracted(
  props: CreditSellerExperimentFeatureInteracted,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Credit_Seller_Experiment_Feature_Interacted',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when users clicks on the button of the Experimental Feature',
    properties: {
      credit_available: {
        description: 'The total amount of rotative credit the POC still has to buy from BEES. ',
        type: ['number', 'null'],
      },
      credit_balance: {
        description: 'The credit balance value in the seller screen. Samples: $ 1702',
        type: ['number', 'null'],
      },
      credit_limit: {
        description: 'The credit limit value in the seller screen. Samples: $ 1702',
        type: ['number', 'null'],
      },
      credit_overdue: {
        description: 'The credit overdue for a given Vendor to the current user',
        type: ['number', 'null'],
      },
      credit_vendorid: {
        description: 'The credit vendor unique ID for a given Vendor to the current user',
        type: ['string', 'null'],
      },
      feature_name: {
        description: 'internal identification of the experimental feature name',
        type: ['string', 'null'],
      },
      feature_type: {
        description: 'internal identification of the experimental feature type',
        enum: ['BUTTON', 'POP_UP', null],
        type: ['string', 'null'],
      },
      optimizely_audience_id: {
        description: 'internal identification of the experimental feature audience ID on Optmizely',
        type: ['string', 'null'],
      },
    },
    required: [
      'credit_limit',
      'credit_vendorid',
      'feature_name',
      'feature_type',
      'optimizely_audience_id',
    ],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Credit Seller Experiment Feature Interacted',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'CreditSellerExperimentFeatureViewed' track call.
 *
 * @param CreditSellerExperimentFeatureViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function creditSellerExperimentFeatureViewed(
  props: CreditSellerExperimentFeatureViewed,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Credit_Seller_Experiment_Feature_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when users sees the button that can initiate de Experimental Feature',
    properties: {
      credit_available: {
        description: 'The total amount of rotative credit the POC still has to buy from BEES. ',
        type: ['number', 'null'],
      },
      credit_balance: {
        description: 'The credit balance value in the seller screen. Samples: $ 1702',
        type: ['number', 'null'],
      },
      credit_limit: {
        description: 'The credit limit value in the seller screen. Samples: $ 1702',
        type: ['number', 'null'],
      },
      credit_overdue: {
        description: 'The credit overdue for a given Vendor to the current user',
        type: ['number', 'null'],
      },
      credit_vendorid: {
        description: 'The credit vendor unique ID for a given Vendor to the current user',
        type: ['string', 'null'],
      },
      feature_name: {
        description: 'internal identification of the experimental feature name',
        type: ['string', 'null'],
      },
      feature_type: {
        description: 'internal identification of the experimental feature type',
        enum: ['BUTTON', 'POP_UP', null],
        type: ['string', 'null'],
      },
      optimizely_audience_id: {
        description: 'internal identification of the experimental feature audience ID on Optmizely',
        type: ['string', 'null'],
      },
    },
    required: [
      'credit_limit',
      'credit_vendorid',
      'feature_name',
      'feature_type',
      'optimizely_audience_id',
    ],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Credit Seller Experiment Feature Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'CreditSellerOptionsChange' track call.
 *
 * @param CreditSellerOptionsChange props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function creditSellerOptionsChange(
  props: CreditSellerOptionsChange,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Credit_Seller_Options_Change',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when users click on the other Credit Seller name',
    properties: {
      vendorId: {
        description:
          "Internal BEES ID that identifies the wholesaler. It's the vendorId from the Accounts Microservice.",
        type: ['string', 'null'],
      },
    },
    required: ['vendorId'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Credit Seller Options Change',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'CreditSellerOptionsInteraction' track call.
 *
 * @param CreditSellerOptionsInteraction props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function creditSellerOptionsInteraction(
  props: CreditSellerOptionsInteraction,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Credit_Seller_Options_Interaction',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when users click on the Credit Seller name button ',
    properties: {
      vendorId: {
        description:
          "Internal BEES ID that identifies the wholesaler. It's the vendorId from the Accounts Microservice.",
        type: ['string', 'null'],
      },
    },
    required: ['vendorId'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Credit Seller Options Interaction',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'CreditSellerSelectionClicked' track call.
 *
 * @param CreditSellerSelectionClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function creditSellerSelectionClicked(
  props: CreditSellerSelectionClicked,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Credit_Seller_Selection_Clicked',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when users clicks on the Credit Seller Selection',
    properties: {
      credit_available: {
        description: 'The total amount of rotative credit the POC still has to buy from BEES. ',
        type: ['number', 'null'],
      },
      credit_balance: {
        description: 'The credit balance value in the seller screen. Samples: $ 1702',
        type: ['number', 'null'],
      },
      credit_limit: {
        description: 'The credit limit value in the seller screen. Samples: $ 1702',
        type: ['number', 'null'],
      },
      referrer: {
        description:
          "Where did the user come from to get to this page/screen. The PREVIOUS screen. Samples: 'Home Screen', 'Invoices', 'My Truck'",
        type: ['string', 'null'],
      },
      screen_name: {
        description:
          "What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen', 'Invoices', 'My Truck'",
        type: ['string', 'null'],
      },
      vendorId: {
        description:
          "Internal BEES ID that identifies the wholesaler. It's the vendorId from the Accounts Microservice.",
        type: ['string', 'null'],
      },
    },
    required: ['credit_limit', 'referrer', 'screen_name', 'vendorId'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Credit Seller Selection Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'CreditSellerSelectionViewed' track call.
 *
 * @param CreditSellerSelectionViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function creditSellerSelectionViewed(
  props: CreditSellerSelectionViewed,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Credit_Seller_Selection_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when users access the Credit Seller Selection screen ',
    properties: {
      credits: {
        description:
          "The array of credits. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
        items: {
          description: "Do not implement with the 'items' structure/object, it's a normal array",
          properties: {
            credit_available: {
              description:
                'The total amount of rotative credit the POC still has to buy from BEES. ',
              type: ['number', 'null'],
            },
            credit_balance: {
              description: 'The credit balance value in the seller screen. Samples: $ 1702',
              type: ['number', 'null'],
            },
            credit_limit: {
              description: 'The credit limit value in the seller screen. Samples: $ 1702',
              type: ['number', 'null'],
            },
            vendorId: {
              description:
                "Internal BEES ID that identifies the wholesaler. It's the vendorId from the Accounts Microservice.",
              type: ['string'],
            },
          },
          required: ['credit_available', 'credit_balance', 'credit_limit', 'vendorId'],
          type: ['object'],
        },
        type: ['array', 'null'],
      },
      referrer: {
        description:
          "Where did the user come from to get to this page/screen. The PREVIOUS screen. Samples: 'Home Screen', 'Invoices', 'My Truck'",
        type: ['string', 'null'],
      },
      screen_name: {
        description:
          "What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen', 'Invoices', 'My Truck'",
        type: ['string', 'null'],
      },
    },
    required: ['credits', 'referrer', 'screen_name'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Credit Seller Selection Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'CreditSellerSummaryViewed' track call.
 *
 * @param CreditSellerSummaryViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function creditSellerSummaryViewed(
  props: CreditSellerSummaryViewed,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Credit_Seller_Summary_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when users access the Credit Seller summary screen',
    properties: {
      credit_available: {
        description: 'The total amount of rotative credit the POC still has to buy from BEES. ',
        type: ['number', 'null'],
      },
      credit_balance: {
        description: 'The credit balance value in the seller screen. Samples: $ 1702',
        type: ['number', 'null'],
      },
      credit_limit: {
        description: 'The credit limit value in the seller screen. Samples: $ 1702',
        type: ['number', 'null'],
      },
      referrer: {
        description:
          "Where did the user come from to get to this page/screen. The PREVIOUS screen. Samples: 'Home Screen', 'Invoices', 'My Truck'",
        type: ['string', 'null'],
      },
      screen_name: {
        description:
          "What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen', 'Invoices', 'My Truck'",
        type: ['string', 'null'],
      },
      vendorId: {
        description:
          "Internal BEES ID that identifies the wholesaler. It's the vendorId from the Accounts Microservice.",
        type: ['string', 'null'],
      },
    },
    required: ['credit_limit', 'referrer', 'screen_name', 'vendorId'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Credit Seller Summary Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'CreditSellerTerms' track call.
 *
 * @param CreditSellerTerms props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function creditSellerTerms(
  props: CreditSellerTerms,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Credit_Seller_Terms',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when users click on the Credit Seller Show terms button ',
    properties: {
      vendorId: {
        description:
          "Internal BEES ID that identifies the wholesaler. It's the vendorId from the Accounts Microservice.",
        type: ['string', 'null'],
      },
    },
    required: ['vendorId'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Credit Seller Terms',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'CreditStatementSelected' track call.
 *
 * @param CreditStatementSelected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function creditStatementSelected(
  props: CreditStatementSelected,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Credit_Statement_Selected',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Trigged when the credit is selected.',
    properties: {
      currency: {
        description: 'ISO currency - Currency associated with product. Samples: DOP, ZAR',
        type: ['string', 'null'],
      },
      statement_date: {
        description: "The month and year related to the statement. Samples: '04/2020', '03/2019'",
        type: ['string', 'null'],
      },
    },
    required: ['currency', 'statement_date'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Credit Statement Selected',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'CreditSupportButtonClicked' track call.
 *
 * @param CreditSupportButtonClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function creditSupportButtonClicked(
  props: CreditSupportButtonClicked,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Credit_Support_Button_Clicked',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when user clicks on "Support" button inside Grupo Modelo Credit Page',
    properties: {
      account_id: {
        description: 'The POC identifier to which the order is being placed',
        type: ['string', 'null'],
      },
      credit_available: {
        description: 'The total amount of rotative credit the POC still has to buy from BEES. ',
        type: ['number', 'null'],
      },
      credit_balance: {
        description: 'The credit balance value in the seller screen. Samples: $ 1702',
        type: ['number', 'null'],
      },
      credit_consumption: {
        description: 'Credit value that is pending payment by the account',
        type: ['number', 'null'],
      },
      credit_overdue: {
        description: 'The credit overdue for a given Vendor to the current user',
        type: ['number', 'null'],
      },
      credit_total: {
        description: 'Credit limit value provide by vendor to the account spent in orders',
        type: ['number', 'null'],
      },
    },
    required: ['account_id'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Credit Support Button Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'ExportInvoiceFailed' track call.
 *
 * @param ExportInvoiceFailed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function exportInvoiceFailed(
  props: ExportInvoiceFailed,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Export_Invoice_Failed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: "When there's a failure in the Invoice export, i.e. an API not available",
    properties: {
      failure_reason: {
        description:
          '\nCommunication Service error description for 400 and 500 status. Samples: "EMPTY_FIELDS", "The email request has invalid information."',
        type: ['string', 'null'],
      },
      invoice_id: {
        description: 'Invoice ID - generated from ABI Invoice service. Sample: B0101450319',
        type: ['string', 'null'],
      },
      vendorId: {
        description:
          "Internal BEES ID that identifies the wholesaler. It's the vendorId from the Accounts Microservice.",
        type: ['string', 'null'],
      },
    },
    required: ['failure_reason', 'invoice_id', 'vendorId'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Export Invoice Failed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'InvoiceCardDetailsViewed' track call.
 *
 * @param InvoiceCardDetailsViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function invoiceCardDetailsViewed(
  props: InvoiceCardDetailsViewed,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Invoice_Card_Details_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Triggered when the user clicks on the VIEW DETAILS button on the invoices carousel',
    properties: {
      invoice_id: {
        description: 'Invoice ID - generated from ABI Invoice service. Sample: B0101450319',
        type: ['string', 'null'],
      },
      invoice_overdue_status: {
        description: '\nWhat is the status of the payment. Samples: OVERDUE',
        enum: ['OVERDUE', 'PAID', 'SENT', null],
        type: ['string', 'null'],
      },
      invoice_status: {
        description: 'The status of the invoice, generated from the Zone ERP. Samples: Closed',
        type: ['string', 'null'],
      },
      vendorId: {
        description:
          "Internal BEES ID that identifies the wholesaler. It's the vendorId from the Accounts Microservice.",
        type: ['string', 'null'],
      },
    },
    required: ['invoice_id', 'invoice_overdue_status', 'invoice_status', 'vendorId'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Invoice Card Details Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'InvoiceCardViewed' track call.
 *
 * @param InvoiceCardViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function invoiceCardViewed(
  props: InvoiceCardViewed,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Invoice_Card_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when the user sees an invoice in the invoices carousel',
    properties: {
      cards_quantity: {
        description: "Total amount of cards. It's a number. Samples: 3, 5, 9",
        type: ['integer', 'null'],
      },
      due_date: {
        description:
          "The due date in the given context. It's a date time prop. Samples: 2020-11-11T19:01:40.032Z",
        format: 'date-time',
        type: ['string', 'null'],
      },
      invoice_id: {
        description: 'Invoice ID - generated from ABI Invoice service. Sample: B0101450319',
        type: ['string', 'null'],
      },
      invoice_overdue_status: {
        description: '\nWhat is the status of the payment. Samples: OVERDUE',
        enum: ['OVERDUE', 'PAID', 'SENT', null],
        type: ['string', 'null'],
      },
      invoice_status: {
        description: 'The status of the invoice, generated from the Zone ERP. Samples: Closed',
        type: ['string', 'null'],
      },
      position: {
        description:
          "The product's ABSOLUTE position in the page. It MUST start at 1, NOT at 0. If it's a pagination context, it won't be position in the given page, it will be ABSOLUTE position across the pages. Samples: 1, 8, 4",
        type: ['integer', 'null'],
      },
      vendorId: {
        description:
          "Internal BEES ID that identifies the wholesaler. It's the vendorId from the Accounts Microservice.",
        type: ['string', 'null'],
      },
    },
    required: [
      'cards_quantity',
      'due_date',
      'invoice_id',
      'invoice_overdue_status',
      'invoice_status',
      'position',
      'vendorId',
    ],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Invoice Card Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'MissingEmailRegistrationCanceled' track call.
 *
 * @param MissingEmailRegistrationCanceled props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function missingEmailRegistrationCanceled(
  props: MissingEmailRegistrationCanceled,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Missing_Email_Registration_Canceled',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      "This event must fire when the user cancels the missing email registration journey by tapping the 'Cancel/back'button",
    properties: {
      button_name: {
        description: 'What is the name of the button. Samples: Place Order',
        type: ['string', 'null'],
      },
      screen_name: {
        description:
          "What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen', 'Invoices', 'My Truck'",
        type: ['string', 'null'],
      },
    },
    required: ['button_name', 'screen_name'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Missing Email Registration Canceled',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'MissingEmailRegistrationStarted' track call.
 *
 * @param MissingEmailRegistrationStarted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function missingEmailRegistrationStarted(
  props: MissingEmailRegistrationStarted,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Missing_Email_Registration_Started',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      "This event must fire when the user starts the missing email registration by tapping the 'Register Email' button",
    properties: {
      button_name: {
        description: 'What is the name of the button. Samples: Place Order',
        type: ['string', 'null'],
      },
      screen_name: {
        description:
          "What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen', 'Invoices', 'My Truck'",
        type: ['string', 'null'],
      },
    },
    required: ['button_name', 'screen_name'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Missing Email Registration Started',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'OfficialProofOfPurchaseDownloaded' track call.
 *
 * @param OfficialProofOfPurchaseDownloaded props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function officialProofOfPurchaseDownloaded(
  props: OfficialProofOfPurchaseDownloaded,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Official_Proof_Of_Purchase_Downloaded',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'This event must be fired when the user clicks to download the Nota Fiscal (Official Proof of Purchase)',
    properties: {
      invoice_id: {
        description: 'Invoice ID - generated from ABI Invoice service. Sample: B0101450319',
        type: ['string', 'null'],
      },
      vendorId: {
        description:
          "Internal BEES ID that identifies the wholesaler. It's the vendorId from the Accounts Microservice.",
        type: ['string', 'null'],
      },
    },
    required: ['invoice_id', 'vendorId'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Official Proof Of Purchase Downloaded',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'PayAllInvoices' track call.
 *
 * @param PayAllInvoices props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function payAllInvoices(
  props: PayAllInvoices,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Pay_All_Invoices',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: "Fires when user taps the 'Pay All Invoices' button",
    properties: {
      bank_name: {
        description: 'The name of the bank in the given context. Samples: Wells Fargo',
        type: ['string', 'null'],
      },
      closed_invoices_quantity: {
        description:
          "The amount of open invoices in the given context. It's an integer. Samples: 1, 3, 8",
        type: ['integer', 'null'],
      },
      open_invoices_quantity: {
        description:
          "The amount of open invoices in the given context. It's an integer. Samples: 1, 3, 8",
        type: ['integer', 'null'],
      },
      screen_name: {
        description:
          "What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen', 'Invoices', 'My Truck'",
        type: ['string', 'null'],
      },
      vendorId: {
        description:
          "Internal BEES ID that identifies the wholesaler. It's the vendorId from the Accounts Microservice.",
        type: ['string', 'null'],
      },
    },
    required: [
      'bank_name',
      'closed_invoices_quantity',
      'open_invoices_quantity',
      'screen_name',
      'vendorId',
    ],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Pay All Invoices',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'PayInvoiceClicked' track call.
 *
 * @param PayInvoiceClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function payInvoiceClicked(
  props: PayInvoiceClicked,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Pay_Invoice_Clicked',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when user clicks on "Pay invoice" button in Invoices List.â€‹',
    properties: {
      button_label: {
        description:
          'The label of the button in which the user clicked. Samples: "Update", "Cancel"',
        type: ['string', 'null'],
      },
      button_name: {
        description: 'What is the name of the button. Samples: Place Order',
        type: ['string', 'null'],
      },
      screen_name: {
        description:
          "What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen', 'Invoices', 'My Truck'",
        type: ['string', 'null'],
      },
      value_stream: {
        description: 'The value stream of the event. It must be an ENUM.',
        enum: [
          'ALGO',
          'CART',
          'CHECKOUT',
          'CUSTOMER_SUPPORT',
          'DEALS',
          'DIGITAL_COUPONS',
          'HOMEPAGE',
          'MEMBERSHIP',
          'MI_NEGOCIO',
          'PAYMENTS',
          'POST_SALES',
          'PRODUCT_PAGES',
          'REWARDS',
          'SEARCH',
          'SOCIAL_MEDIA',
          'LINK_PLANNING',
          'LINK_ORDER_TAKING',
          'LINK_POST_ORDER',
          'LINK_JBP',
        ],
        type: ['string'],
      },
    },
    required: ['button_label', 'button_name', 'screen_name', 'value_stream'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Pay Invoice Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'PaymentGatewayWebviewCanceled' track call.
 *
 * @param PaymentGatewayWebviewCanceled props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function paymentGatewayWebviewCanceled(
  props: PaymentGatewayWebviewCanceled,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Payment_Gateway_Webview_Canceled',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'This event should fire when the user  is in the WebView and clicks on the back button and is redirected to the previous screen, for Checkout Screen ',
    properties: {
      button_label: {
        description:
          'The label of the button in which the user clicked. Samples: "Update", "Cancel"',
        type: ['string', 'null'],
      },
      button_name: {
        description: 'What is the name of the button. Samples: Place Order',
        type: ['string', 'null'],
      },
      referrer: {
        description:
          "Where did the user come from to get to this page/screen. The PREVIOUS screen. Samples: 'Home Screen', 'Invoices', 'My Truck'",
        type: ['string', 'null'],
      },
      screen_name: {
        description:
          "What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen', 'Invoices', 'My Truck'",
        type: ['string', 'null'],
      },
    },
    required: ['button_label', 'button_name', 'referrer', 'screen_name'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Payment Gateway Webview Canceled',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'PaymentGatewayWebviewRequestFailed' track call.
 *
 * @param PaymentGatewayWebviewRequestFailed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function paymentGatewayWebviewRequestFailed(
  props: PaymentGatewayWebviewRequestFailed,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Payment_Gateway_Webview_Request_Failed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Triggered when an error happens after the user try to submit an order inside the WebView and return to the checkout screen',
    properties: {
      failure_reason: {
        description:
          '\nCommunication Service error description for 400 and 500 status. Samples: "EMPTY_FIELDS", "The email request has invalid information."',
        type: ['string', 'null'],
      },
      payment_method: {
        description:
          'The payment method in the given context. Samples: CASH, CHECK, CREDIT, BOLETO, CREDIT_CARD_POS, BANK_SLIP',
        enum: [
          'BANK_DEPOSIT',
          'BANK_SLIP',
          'BANK_SLIP_INSTALLMENT',
          'BANK_TRANSFER',
          'BOLETO',
          'CASH',
          'CHECK',
          'CREDIT',
          'CREDIT_CARD_ONLINE',
          'CREDIT_CARD_POS',
          'DIRECT_PAY_AT_DELIVERY',
          'GPS',
          'PIX_AT_DELIVERY',
          'PIX_ONLINE',
          'RECEIVABLE_AS_CREDIT',
          null,
        ],
        type: ['string', 'null'],
      },
      payment_provider: {
        description:
          'The payment provider in the given context. Samples: Stripe, Ztech, Banco Popular, Banco Leon, PSE, etc',
        type: ['string'],
      },
      referrer: {
        description:
          "Where did the user come from to get to this page/screen. The PREVIOUS screen. Samples: 'Home Screen', 'Invoices', 'My Truck'",
        type: ['string', 'null'],
      },
      screen_name: {
        description:
          "What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen', 'Invoices', 'My Truck'",
        type: ['string', 'null'],
      },
      start_date: {
        description: "The start date in the given context. Sample: '2019-12-31T23:59:59Z'",
        format: 'date-time',
        type: ['string', 'null'],
      },
      total: {
        description: 'Grand total cost after tax and shipping. Samples: 31041.62',
        type: ['number', 'null'],
      },
      url: {
        description:
          'url in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the url the user will be redirected to. Samples: https://tiendaenlinea.bavaria.co/aguila-rn-330cc-x-30-3128.html',
        type: ['string', 'null'],
      },
    },
    required: [
      'failure_reason',
      'payment_method',
      'payment_provider',
      'referrer',
      'screen_name',
      'start_date',
      'total',
      'url',
    ],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Payment Gateway Webview Request Failed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'PaymentGatewayWebviewStarted' track call.
 *
 * @param PaymentGatewayWebviewStarted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function paymentGatewayWebviewStarted(
  props: PaymentGatewayWebviewStarted,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Payment_Gateway_Webview_Started',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Triggered when user clicks on the “Process to Payment” button and opens the WebView',
    properties: {
      payment_method: {
        description:
          'The payment method in the given context. Samples: CASH, CHECK, CREDIT, BOLETO, CREDIT_CARD_POS, BANK_SLIP',
        enum: [
          'BANK_DEPOSIT',
          'BANK_SLIP',
          'BANK_SLIP_INSTALLMENT',
          'BANK_TRANSFER',
          'BOLETO',
          'CASH',
          'CHECK',
          'CREDIT',
          'CREDIT_CARD_ONLINE',
          'CREDIT_CARD_POS',
          'DIRECT_PAY_AT_DELIVERY',
          'GPS',
          'PIX_AT_DELIVERY',
          'PIX_ONLINE',
          'RECEIVABLE_AS_CREDIT',
          null,
        ],
        type: ['string', 'null'],
      },
      payment_provider: {
        description:
          'The payment provider in the given context. Samples: Stripe, Ztech, Banco Popular, Banco Leon, PSE, etc',
        type: ['string'],
      },
      referrer: {
        description:
          "Where did the user come from to get to this page/screen. The PREVIOUS screen. Samples: 'Home Screen', 'Invoices', 'My Truck'",
        type: ['string', 'null'],
      },
      screen_name: {
        description:
          "What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen', 'Invoices', 'My Truck'",
        type: ['string', 'null'],
      },
      start_date: {
        description: "The start date in the given context. Sample: '2019-12-31T23:59:59Z'",
        format: 'date-time',
        type: ['string', 'null'],
      },
      total: {
        description: 'Grand total cost after tax and shipping. Samples: 31041.62',
        type: ['number', 'null'],
      },
      url: {
        description:
          'url in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the url the user will be redirected to. Samples: https://tiendaenlinea.bavaria.co/aguila-rn-330cc-x-30-3128.html',
        type: ['string', 'null'],
      },
    },
    required: [
      'payment_method',
      'payment_provider',
      'referrer',
      'screen_name',
      'start_date',
      'total',
      'url',
    ],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Payment Gateway Webview Started',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'PaymentsButtonClicked' track call.
 *
 * @param PaymentsButtonClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function paymentsButtonClicked(
  props: PaymentsButtonClicked,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Payments_Button_Clicked',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when user click in a button.',
    properties: {
      button_label: {
        description:
          'The label of the button in which the user clicked. Samples: "Update", "Cancel"',
        type: ['string', 'null'],
      },
      button_name: {
        description: 'What is the name of the button. Samples: Place Order',
        type: ['string', 'null'],
      },
      credit_score: { description: 'Score filter in Credit Management', type: ['string', 'null'] },
      fee: { description: 'Fee field from edit recommendation', type: ['string', 'null'] },
      limit: { description: 'Limit field from edit recommendation', type: ['string', 'null'] },
      recommendation: {
        description: 'Recommendation filter in Credit Management',
        type: ['string', 'null'],
      },
      referrer: {
        description:
          "Where did the user come from to get to this page/screen. The PREVIOUS screen. Samples: 'Home Screen', 'Invoices', 'My Truck'",
        type: ['string', 'null'],
      },
      screen_name: {
        description:
          "What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen', 'Invoices', 'My Truck'",
        type: ['string', 'null'],
      },
      statuses_included: { description: 'Statuses included in download', type: ['string', 'null'] },
      store_id: {
        description:
          'Unique id of the store. Randomly generated, UUID formatted. Each store is a group of different vendors in the Customer App experience ',
        type: ['string', 'null'],
      },
      terms: { description: 'Terms field from edit recommendation', type: ['string', 'null'] },
      update: { description: 'Update filter in Credit Management', type: ['string', 'null'] },
      value_stream: {
        description: 'The value stream of the event. It must be an ENUM.',
        enum: [
          'ALGO',
          'CART',
          'CHECKOUT',
          'CUSTOMER_SUPPORT',
          'DEALS',
          'DIGITAL_COUPONS',
          'HOMEPAGE',
          'MEMBERSHIP',
          'MI_NEGOCIO',
          'PAYMENTS',
          'POST_SALES',
          'PRODUCT_PAGES',
          'REWARDS',
          'SEARCH',
          'SOCIAL_MEDIA',
          'LINK_PLANNING',
          'LINK_ORDER_TAKING',
          'LINK_POST_ORDER',
          'LINK_JBP',
        ],
        type: ['string'],
      },
      vendorId: {
        description:
          "Internal BEES ID that identifies the wholesaler. It's the vendorId from the Accounts Microservice.",
        type: ['string', 'null'],
      },
    },
    required: [
      'button_label',
      'button_name',
      'referrer',
      'screen_name',
      'vendorId',
      'store_id',
      'value_stream',
    ],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Payments Button Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'PaymentsPageViewed' track call.
 *
 * @param PaymentsPageViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function paymentsPageViewed(
  props: PaymentsPageViewed,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Payments_Page_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when user view a specific page.',
    properties: {
      available: { description: 'Users credit available', type: ['string', 'null'] },
      available_minus_consumption: {
        description: 'Users credit available minus consumption',
        type: ['string', 'null'],
      },
      balance: { description: 'Users credit balance', type: ['string', 'null'] },
      balance_plus_consumption: {
        description: 'Users credit balance plus consumption',
        type: ['string', 'null'],
      },
      referrer: {
        description:
          "Where did the user come from to get to this page/screen. The PREVIOUS screen. Samples: 'Home Screen', 'Invoices', 'My Truck'",
        type: ['string', 'null'],
      },
      screen_name: {
        description:
          "What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen', 'Invoices', 'My Truck'",
        type: ['string', 'null'],
      },
      store_id: {
        description:
          'Unique id of the store. Randomly generated, UUID formatted. Each store is a group of different vendors in the Customer App experience ',
        type: ['string', 'null'],
      },
      value_stream: {
        description: 'The value stream of the event. It must be an ENUM.',
        enum: [
          'ALGO',
          'CART',
          'CHECKOUT',
          'CUSTOMER_SUPPORT',
          'DEALS',
          'DIGITAL_COUPONS',
          'HOMEPAGE',
          'MEMBERSHIP',
          'MI_NEGOCIO',
          'PAYMENTS',
          'POST_SALES',
          'PRODUCT_PAGES',
          'REWARDS',
          'SEARCH',
          'SOCIAL_MEDIA',
          'LINK_PLANNING',
          'LINK_ORDER_TAKING',
          'LINK_POST_ORDER',
          'LINK_JBP',
        ],
        type: ['string'],
      },
      vendorId: {
        description:
          "Internal BEES ID that identifies the wholesaler. It's the vendorId from the Accounts Microservice.",
        type: ['string', 'null'],
      },
    },
    required: ['referrer', 'screen_name', 'vendorId', 'store_id', 'value_stream'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Payments Page Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'PhoneValidationConfirmed' track call.
 *
 * @param PhoneValidationConfirmed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function phoneValidationConfirmed(
  props: PhoneValidationConfirmed,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Phone_Validation_Confirmed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when user clicks on "Verify code" button inside Phone Code Screen.​',
    properties: {
      button_label: {
        description:
          'The label of the button in which the user clicked. Samples: "Update", "Cancel"',
        type: ['string', 'null'],
      },
      button_name: {
        description: 'What is the name of the button. Samples: Place Order',
        type: ['string', 'null'],
      },
      referrer: {
        description:
          "Where did the user come from to get to this page/screen. The PREVIOUS screen. Samples: 'Home Screen', 'Invoices', 'My Truck'",
        type: ['string', 'null'],
      },
      screen_name: {
        description:
          "What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen', 'Invoices', 'My Truck'",
        type: ['string', 'null'],
      },
      vendorId: {
        description:
          "Internal BEES ID that identifies the wholesaler. It's the vendorId from the Accounts Microservice.",
        type: ['string', 'null'],
      },
    },
    required: ['button_label', 'button_name', 'referrer', 'screen_name', 'vendorId'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Phone Validation Confirmed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'PhoneValidationErrorViewed' track call.
 *
 * @param PhoneValidationErrorViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function phoneValidationErrorViewed(
  props: PhoneValidationErrorViewed,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Phone_Validation_Error_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Triggered when users see the error toast inside Phone Number Screen and Phone Code Screen. ',
    properties: {
      phone_validation_error: {
        description: 'Identification of type of phone validation error.',
        enum: ['duplicated_number', 'error_number', 'invalid_code', 'invalid_number', null],
        type: ['string', 'null'],
      },
      vendorId: {
        description:
          "Internal BEES ID that identifies the wholesaler. It's the vendorId from the Accounts Microservice.",
        type: ['string', 'null'],
      },
    },
    required: ['phone_validation_error', 'vendorId'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Phone Validation Error Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'PhoneValidationNewSMSSentViewed' track call.
 *
 * @param PhoneValidationNewSMSSentViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function phoneValidationNewSMSSentViewed(
  props: PhoneValidationNewSMSSentViewed,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Phone_Validation_New_Sms_Sent_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Triggered when user view confirmation toast when a new SMS is sent for verification code.',
    properties: {
      referrer: {
        description:
          "Where did the user come from to get to this page/screen. The PREVIOUS screen. Samples: 'Home Screen', 'Invoices', 'My Truck'",
        type: ['string', 'null'],
      },
      screen_name: {
        description:
          "What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen', 'Invoices', 'My Truck'",
        type: ['string', 'null'],
      },
      vendorId: {
        description:
          "Internal BEES ID that identifies the wholesaler. It's the vendorId from the Accounts Microservice.",
        type: ['string', 'null'],
      },
    },
    required: ['referrer', 'screen_name', 'vendorId'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Phone Validation New Sms Sent Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'PhoneValidationStarted' track call.
 *
 * @param PhoneValidationStarted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function phoneValidationStarted(
  props: PhoneValidationStarted,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Phone_Validation_Started',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when user clicks on "Send code" button inside Phone Number Screen.​',
    properties: {
      button_label: {
        description:
          'The label of the button in which the user clicked. Samples: "Update", "Cancel"',
        type: ['string', 'null'],
      },
      button_name: {
        description: 'What is the name of the button. Samples: Place Order',
        type: ['string', 'null'],
      },
      referrer: {
        description:
          "Where did the user come from to get to this page/screen. The PREVIOUS screen. Samples: 'Home Screen', 'Invoices', 'My Truck'",
        type: ['string', 'null'],
      },
      screen_name: {
        description:
          "What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen', 'Invoices', 'My Truck'",
        type: ['string', 'null'],
      },
      vendorId: {
        description:
          "Internal BEES ID that identifies the wholesaler. It's the vendorId from the Accounts Microservice.",
        type: ['string', 'null'],
      },
    },
    required: ['button_label', 'button_name', 'referrer', 'screen_name', 'vendorId'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Phone Validation Started',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'RecentTransactionsClicked' track call.
 *
 * @param RecentTransactionsClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function recentTransactionsClicked(
  props: RecentTransactionsClicked,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Recent_Transactions_Clicked',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Triggered when users click on "Show all" button on Credit Web View with Recent Transactions',
    properties: {
      account_id: {
        description: 'The POC identifier to which the order is being placed',
        type: ['string', 'null'],
      },
      credit_available: {
        description: 'The total amount of rotative credit the POC still has to buy from BEES. ',
        type: ['number', 'null'],
      },
      credit_balance: {
        description: 'The credit balance value in the seller screen. Samples: $ 1702',
        type: ['number', 'null'],
      },
      credit_consumption: {
        description: 'Credit value that is pending payment by the account',
        type: ['number', 'null'],
      },
      credit_overdue: {
        description: 'The credit overdue for a given Vendor to the current user',
        type: ['number', 'null'],
      },
      credit_total: {
        description: 'Credit limit value provide by vendor to the account spent in orders',
        type: ['number', 'null'],
      },
    },
    required: ['account_id'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Recent Transactions Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'RecentTransactionsViewed' track call.
 *
 * @param RecentTransactionsViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function recentTransactionsViewed(
  props: RecentTransactionsViewed,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Recent_Transactions_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when Credit Web View opens with Recent Transactions feature',
    properties: {
      account_id: {
        description: 'The POC identifier to which the order is being placed',
        type: ['string', 'null'],
      },
      credit_available: {
        description: 'The total amount of rotative credit the POC still has to buy from BEES. ',
        type: ['number', 'null'],
      },
      credit_balance: {
        description: 'The credit balance value in the seller screen. Samples: $ 1702',
        type: ['number', 'null'],
      },
      credit_consumption: {
        description: 'Credit value that is pending payment by the account',
        type: ['number', 'null'],
      },
      credit_overdue: {
        description: 'The credit overdue for a given Vendor to the current user',
        type: ['number', 'null'],
      },
      credit_total: {
        description: 'Credit limit value provide by vendor to the account spent in orders',
        type: ['number', 'null'],
      },
    },
    required: ['account_id'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Recent Transactions Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'ReferenceNumberCopied' track call.
 *
 * @param ReferenceNumberCopied props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function referenceNumberCopied(
  props: ReferenceNumberCopied,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Reference_Number_Copied',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Triggered when user clicks on "Copy" button in reference number card on Bank Transfer Screen',
    properties: {
      button_label: {
        description:
          'The label of the button in which the user clicked. Samples: "Update", "Cancel"',
        type: ['string', 'null'],
      },
      button_name: {
        description: 'What is the name of the button. Samples: Place Order',
        type: ['string', 'null'],
      },
      referrer: {
        description:
          "Where did the user come from to get to this page/screen. The PREVIOUS screen. Samples: 'Home Screen', 'Invoices', 'My Truck'",
        type: ['string', 'null'],
      },
      screen_name: {
        description:
          "What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen', 'Invoices', 'My Truck'",
        type: ['string', 'null'],
      },
    },
    required: ['button_label', 'button_name', 'referrer', 'screen_name'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Reference Number Copied',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'ShowCreditPINClicked' track call.
 *
 * @param ShowCreditPINClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function showCreditPINClicked(
  props: ShowCreditPINClicked,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Show_Credit_PIN_Clicked',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when user clicks on "PIN button" inside Model Group Credit Page',
    properties: {
      button_label: {
        description:
          'The label of the button in which the user clicked. Samples: "Update", "Cancel"',
        type: ['string', 'null'],
      },
      button_name: {
        description: 'What is the name of the button. Samples: Place Order',
        type: ['string', 'null'],
      },
      referrer: {
        description:
          "Where did the user come from to get to this page/screen. The PREVIOUS screen. Samples: 'Home Screen', 'Invoices', 'My Truck'",
        type: ['string', 'null'],
      },
      screen_name: {
        description:
          "What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen', 'Invoices', 'My Truck'",
        type: ['string', 'null'],
      },
    },
    required: ['button_label', 'button_name', 'referrer', 'screen_name'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Show Credit PIN Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'TermsConditionsConfirmed' track call.
 *
 * @param TermsConditionsConfirmed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function termsConditionsConfirmed(
  props: TermsConditionsConfirmed,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Terms_Conditions_Confirmed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when user clicks on "Confirm" button inside Terms Conditions Screen.',
    properties: {
      button_label: {
        description:
          'The label of the button in which the user clicked. Samples: "Update", "Cancel"',
        type: ['string', 'null'],
      },
      button_name: {
        description: 'What is the name of the button. Samples: Place Order',
        type: ['string', 'null'],
      },
      referrer: {
        description:
          "Where did the user come from to get to this page/screen. The PREVIOUS screen. Samples: 'Home Screen', 'Invoices', 'My Truck'",
        type: ['string', 'null'],
      },
      screen_name: {
        description:
          "What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen', 'Invoices', 'My Truck'",
        type: ['string', 'null'],
      },
      vendorId: {
        description:
          "Internal BEES ID that identifies the wholesaler. It's the vendorId from the Accounts Microservice.",
        type: ['string', 'null'],
      },
    },
    required: ['button_label', 'button_name', 'referrer', 'screen_name', 'vendorId'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Terms Conditions Confirmed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'TermsConditionsStarted' track call.
 *
 * @param TermsConditionsStarted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function termsConditionsStarted(
  props: TermsConditionsStarted,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Terms_Conditions_Started',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when user enters on Terms Conditions Screen.​',
    properties: {
      screen_name: {
        description:
          "What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen', 'Invoices', 'My Truck'",
        type: ['string', 'null'],
      },
      vendorId: {
        description:
          "Internal BEES ID that identifies the wholesaler. It's the vendorId from the Accounts Microservice.",
        type: ['string', 'null'],
      },
    },
    required: ['screen_name', 'vendorId'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Terms Conditions Started',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'TransactionHistoryViewed' track call.
 *
 * @param TransactionHistoryViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function transactionHistoryViewed(
  props: TransactionHistoryViewed,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Transaction_History_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when Transaction History opens after users click on Show all button',
    properties: {
      account_id: {
        description: 'The POC identifier to which the order is being placed',
        type: ['string', 'null'],
      },
      credit_available: {
        description: 'The total amount of rotative credit the POC still has to buy from BEES. ',
        type: ['number', 'null'],
      },
      credit_balance: {
        description: 'The credit balance value in the seller screen. Samples: $ 1702',
        type: ['number', 'null'],
      },
      credit_consumption: {
        description: 'Credit value that is pending payment by the account',
        type: ['number', 'null'],
      },
      credit_overdue: {
        description: 'The credit overdue for a given Vendor to the current user',
        type: ['number', 'null'],
      },
      credit_total: {
        description: 'Credit limit value provide by vendor to the account spent in orders',
        type: ['number', 'null'],
      },
    },
    required: ['account_id'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Transaction History Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}

const clientAPI = {
  /**
   * Updates the run-time configuration of this Typewriter client.
   *
   * @param {TypewriterOptions} options - the options to upsert
   *
   * @typedef {Object} TypewriterOptions
   * @property {AnyAnalytics} [analytics] - Underlying analytics instance where analytics
   * 		calls are forwarded on to. Defaults to window.analytics.
   * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
   * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
   * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
   * 		will be logged to stderr.
   */
  setTypewriterOptions,

  /**
   * Fires a 'BankAccountNumberCopied' track call.
   *
   * @param BankAccountNumberCopied props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  bankAccountNumberCopied,
  /**
   * Fires a 'BankListViewed' track call.
   *
   * @param BankListViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  bankListViewed,
  /**
   * Fires a 'BankSelected' track call.
   *
   * @param BankSelected props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  bankSelected,
  /**
   * Fires a 'BankTransferCodeViewed' track call.
   *
   * @param BankTransferCodeViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  bankTransferCodeViewed,
  /**
   * Fires a 'BankTransferInfoClicked' track call.
   *
   * @param BankTransferInfoClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  bankTransferInfoClicked,
  /**
   * Fires a 'BankWebviewRequestFailed' track call.
   *
   * @param BankWebviewRequestFailed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  bankWebviewRequestFailed,
  /**
   * Fires a 'BeneficiaryNameCopied' track call.
   *
   * @param BeneficiaryNameCopied props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  beneficiaryNameCopied,
  /**
   * Fires a 'BillPaymentPDFDownloaded' track call.
   *
   * @param BillPaymentPDFDownloaded props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  billPaymentPDFDownloaded,
  /**
   * Fires a 'CreditBlockedReasonClicked' track call.
   *
   * @param CreditBlockedReasonClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  creditBlockedReasonClicked,
  /**
   * Fires a 'CreditBlockedVendorClicked' track call.
   *
   * @param CreditBlockedVendorClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  creditBlockedVendorClicked,
  /**
   * Fires a 'CreditEmptyStateViewed' track call.
   *
   * @param CreditEmptyStateViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  creditEmptyStateViewed,
  /**
   * Fires a 'CreditErrorReturnViewed' track call.
   *
   * @param CreditErrorReturnViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  creditErrorReturnViewed,
  /**
   * Fires a 'CreditLineActivationCanceled' track call.
   *
   * @param CreditLineActivationCanceled props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  creditLineActivationCanceled,
  /**
   * Fires a 'CreditLineActivationClicked' track call.
   *
   * @param CreditLineActivationClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  creditLineActivationClicked,
  /**
   * Fires a 'CreditLineActivationConfirmed' track call.
   *
   * @param CreditLineActivationConfirmed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  creditLineActivationConfirmed,
  /**
   * Fires a 'CreditLineActivationPopUpViewed' track call.
   *
   * @param CreditLineActivationPopUpViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  creditLineActivationPopUpViewed,
  /**
   * Fires a 'CreditLineActivationResultHelpClicked' track call.
   *
   * @param CreditLineActivationResultHelpClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  creditLineActivationResultHelpClicked,
  /**
   * Fires a 'CreditLineActivationResultShowed' track call.
   *
   * @param CreditLineActivationResultShowed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  creditLineActivationResultShowed,
  /**
   * Fires a 'CreditLineActivationStarted' track call.
   *
   * @param CreditLineActivationStarted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  creditLineActivationStarted,
  /**
   * Fires a 'CreditLineDocumentsSubmittedClicked' track call.
   *
   * @param CreditLineDocumentsSubmittedClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  creditLineDocumentsSubmittedClicked,
  /**
   * Fires a 'CreditLineDocumentsSubmittedViewed' track call.
   *
   * @param CreditLineDocumentsSubmittedViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  creditLineDocumentsSubmittedViewed,
  /**
   * Fires a 'CreditLineExitModalShowed' track call.
   *
   * @param CreditLineExitModalShowed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  creditLineExitModalShowed,
  /**
   * Fires a 'CreditLinePageErrorShowed' track call.
   *
   * @param CreditLinePageErrorShowed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  creditLinePageErrorShowed,
  /**
   * Fires a 'CreditLoadingViewed' track call.
   *
   * @param CreditLoadingViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  creditLoadingViewed,
  /**
   * Fires a 'CreditPartialInfoExit' track call.
   *
   * @param CreditPartialInfoExit props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  creditPartialInfoExit,
  /**
   * Fires a 'CreditPartialInfoViewed' track call.
   *
   * @param CreditPartialInfoViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  creditPartialInfoViewed,
  /**
   * Fires a 'CreditSSOLoadingViewed' track call.
   *
   * @param CreditSSOLoadingViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  creditSSOLoadingViewed,
  /**
   * Fires a 'CreditSellerExperimentFeatureClicked' track call.
   *
   * @param CreditSellerExperimentFeatureClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  creditSellerExperimentFeatureClicked,
  /**
   * Fires a 'CreditSellerExperimentFeatureCompleted' track call.
   *
   * @param CreditSellerExperimentFeatureCompleted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  creditSellerExperimentFeatureCompleted,
  /**
   * Fires a 'CreditSellerExperimentFeatureInteracted' track call.
   *
   * @param CreditSellerExperimentFeatureInteracted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  creditSellerExperimentFeatureInteracted,
  /**
   * Fires a 'CreditSellerExperimentFeatureViewed' track call.
   *
   * @param CreditSellerExperimentFeatureViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  creditSellerExperimentFeatureViewed,
  /**
   * Fires a 'CreditSellerOptionsChange' track call.
   *
   * @param CreditSellerOptionsChange props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  creditSellerOptionsChange,
  /**
   * Fires a 'CreditSellerOptionsInteraction' track call.
   *
   * @param CreditSellerOptionsInteraction props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  creditSellerOptionsInteraction,
  /**
   * Fires a 'CreditSellerSelectionClicked' track call.
   *
   * @param CreditSellerSelectionClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  creditSellerSelectionClicked,
  /**
   * Fires a 'CreditSellerSelectionViewed' track call.
   *
   * @param CreditSellerSelectionViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  creditSellerSelectionViewed,
  /**
   * Fires a 'CreditSellerSummaryViewed' track call.
   *
   * @param CreditSellerSummaryViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  creditSellerSummaryViewed,
  /**
   * Fires a 'CreditSellerTerms' track call.
   *
   * @param CreditSellerTerms props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  creditSellerTerms,
  /**
   * Fires a 'CreditStatementSelected' track call.
   *
   * @param CreditStatementSelected props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  creditStatementSelected,
  /**
   * Fires a 'CreditSupportButtonClicked' track call.
   *
   * @param CreditSupportButtonClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  creditSupportButtonClicked,
  /**
   * Fires a 'ExportInvoiceFailed' track call.
   *
   * @param ExportInvoiceFailed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  exportInvoiceFailed,
  /**
   * Fires a 'InvoiceCardDetailsViewed' track call.
   *
   * @param InvoiceCardDetailsViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  invoiceCardDetailsViewed,
  /**
   * Fires a 'InvoiceCardViewed' track call.
   *
   * @param InvoiceCardViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  invoiceCardViewed,
  /**
   * Fires a 'MissingEmailRegistrationCanceled' track call.
   *
   * @param MissingEmailRegistrationCanceled props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  missingEmailRegistrationCanceled,
  /**
   * Fires a 'MissingEmailRegistrationStarted' track call.
   *
   * @param MissingEmailRegistrationStarted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  missingEmailRegistrationStarted,
  /**
   * Fires a 'OfficialProofOfPurchaseDownloaded' track call.
   *
   * @param OfficialProofOfPurchaseDownloaded props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  officialProofOfPurchaseDownloaded,
  /**
   * Fires a 'PayAllInvoices' track call.
   *
   * @param PayAllInvoices props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  payAllInvoices,
  /**
   * Fires a 'PayInvoiceClicked' track call.
   *
   * @param PayInvoiceClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  payInvoiceClicked,
  /**
   * Fires a 'PaymentGatewayWebviewCanceled' track call.
   *
   * @param PaymentGatewayWebviewCanceled props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  paymentGatewayWebviewCanceled,
  /**
   * Fires a 'PaymentGatewayWebviewRequestFailed' track call.
   *
   * @param PaymentGatewayWebviewRequestFailed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  paymentGatewayWebviewRequestFailed,
  /**
   * Fires a 'PaymentGatewayWebviewStarted' track call.
   *
   * @param PaymentGatewayWebviewStarted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  paymentGatewayWebviewStarted,
  /**
   * Fires a 'PaymentsButtonClicked' track call.
   *
   * @param PaymentsButtonClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  paymentsButtonClicked,
  /**
   * Fires a 'PaymentsPageViewed' track call.
   *
   * @param PaymentsPageViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  paymentsPageViewed,
  /**
   * Fires a 'PhoneValidationConfirmed' track call.
   *
   * @param PhoneValidationConfirmed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  phoneValidationConfirmed,
  /**
   * Fires a 'PhoneValidationErrorViewed' track call.
   *
   * @param PhoneValidationErrorViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  phoneValidationErrorViewed,
  /**
   * Fires a 'PhoneValidationNewSMSSentViewed' track call.
   *
   * @param PhoneValidationNewSMSSentViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  phoneValidationNewSMSSentViewed,
  /**
   * Fires a 'PhoneValidationStarted' track call.
   *
   * @param PhoneValidationStarted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  phoneValidationStarted,
  /**
   * Fires a 'RecentTransactionsClicked' track call.
   *
   * @param RecentTransactionsClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  recentTransactionsClicked,
  /**
   * Fires a 'RecentTransactionsViewed' track call.
   *
   * @param RecentTransactionsViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  recentTransactionsViewed,
  /**
   * Fires a 'ReferenceNumberCopied' track call.
   *
   * @param ReferenceNumberCopied props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  referenceNumberCopied,
  /**
   * Fires a 'ShowCreditPINClicked' track call.
   *
   * @param ShowCreditPINClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  showCreditPINClicked,
  /**
   * Fires a 'TermsConditionsConfirmed' track call.
   *
   * @param TermsConditionsConfirmed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  termsConditionsConfirmed,
  /**
   * Fires a 'TermsConditionsStarted' track call.
   *
   * @param TermsConditionsStarted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  termsConditionsStarted,
  /**
   * Fires a 'TransactionHistoryViewed' track call.
   *
   * @param TransactionHistoryViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  transactionHistoryViewed,
};

export default new Proxy<typeof clientAPI>(clientAPI, {
  get(target, method) {
    if (typeof method === 'string' && target.hasOwnProperty(method)) {
      return target[method as keyof typeof clientAPI];
    }

    return () => {
      console.warn(`⚠️  You made an analytics call (${String(method)}) that can't be found. Either:
         a) Re-generate your typewriter client: \`npx typewriter\`
         b) Add it to your Tracking Plan: https://app.segment.com/segment-oscb/protocols/tracking-plans/rs_1zTHJU9fd5mt7cndWnd4PgJbMCE`);
      const a = analytics();
      if (a) {
        a.track(
          'Unknown Analytics Call Fired',
          {
            method,
          },
          withTypewriterContext()
        );
      }
    };
  },
});
