import { Heading, Paragraph } from '@hexa-ui/components';
import { FormattedMessage, useIntl } from 'react-intl';

import { useGetSources } from '../../../hooks/useGetSources';
import { Modal } from '../Modal/Modal';
import { RulesAndConditionsProps } from './RulesAndConditions.types';
import { ContainerRules, ModalContent, RulesTitle, TextContainer } from './styles';

export const RulesAndConditions: React.FC<RulesAndConditionsProps> = ({
  trigger,
  open,
  portal,
}) => {
  const { formatMessage } = useIntl();

  const { getValidation } = useGetSources();

  const onClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    if (open?.value) open.setter(false);
  };

  const linesWithStrong = {
    first: 'pages.upload.uploader.rulesAndConditions.modal.files.items.duplicatedPOCLines',
    secound: 'pages.upload.uploader.rulesAndConditions.modal.files.items.newlyIncluded',
    third: 'pages.upload.uploader.rulesAndConditions.modal.files.items.removeLines',
    fourth: 'pages.upload.uploader.rulesAndConditions.modal.files.items.blankFields',
    fifth: 'pages.upload.uploader.rulesAndConditions.modal.files.items.newColumns',
  };

  const linesCredit = [
    {
      id: 'pages.upload.uploader.rulesAndConditions.modal.credit.items.creditLimit',
      value: getValidation('limit'),
    },
    {
      id: 'pages.upload.uploader.rulesAndConditions.modal.credit.items.creditTerm',
      value: getValidation('term'),
    },
    {
      id: 'pages.upload.uploader.rulesAndConditions.modal.credit.items.creditFee',
      value: getValidation('term.Fee'),
    },
  ];

  return (
    <Modal
      open={open}
      portal={portal}
      trigger={trigger}
      onClose={onClose}
      title={
        <Heading size="H2">
          {formatMessage({ id: 'pages.upload.uploader.rulesAndConditions.modal.title' })}
        </Heading>
      }
    >
      <ModalContent data-testid="modal-content">
        <Paragraph size="basis">
          {formatMessage({ id: 'pages.upload.uploader.rulesAndConditions.modal.description' })}
        </Paragraph>

        <Heading size="H5">
          {formatMessage({ id: 'pages.upload.uploader.rulesAndConditions.modal.files.title' })}
        </Heading>

        <TextContainer data-testid="text-container">
          <RulesTitle data-testid="wont-consider-text">
            {formatMessage({
              id: 'pages.upload.uploader.rulesAndConditions.modal.files.wontConsider',
            })}
          </RulesTitle>

          <ContainerRules data-testid="container-rules">
            <ul style={{ color: '#141414', margin: '0 8px' }}>
              {Object.entries(linesWithStrong).map(([key, value]) => (
                <li key={key}>
                  <Paragraph size="small" colortype="primary">
                    <FormattedMessage
                      id={value}
                      values={{
                        strong: (chunks: React.ReactNode) => <strong>{chunks}</strong>,
                      }}
                    />
                  </Paragraph>
                </li>
              ))}
            </ul>
          </ContainerRules>
        </TextContainer>

        <Heading size="H5">
          {formatMessage({
            id: 'pages.upload.uploader.rulesAndConditions.modal.credit.title',
          })}
        </Heading>

        <ContainerRules>
          <ul style={{ color: '#141414', margin: '0 8px' }}>
            {Object.entries(linesCredit).map(([key, value]) => (
              <li key={key}>
                <Paragraph size="small" colortype="primary">
                  <FormattedMessage
                    id={value.id}
                    values={{
                      strong: (chunks: React.ReactNode) => <strong>{chunks}</strong>,
                    }}
                  />

                  {value.value.message}
                </Paragraph>
              </li>
            ))}
          </ul>
        </ContainerRules>
      </ModalContent>
    </Modal>
  );
};
