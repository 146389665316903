import React from 'react';

export const Processing = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(
  (props, ref) => (
    <svg
      ref={ref}
      {...props}
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="processing-icon"
    >
      <g clipPath="url(#clip0_6193_139622)">
        <path
          d="M28 52.5C41.531 52.5 52.5 41.531 52.5 28C52.5 14.469 41.531 3.5 28 3.5C14.469 3.5 3.5 14.469 3.5 28C3.5 41.531 14.469 52.5 28 52.5Z"
          fill="url(#paint0_linear_6193_139622)"
        />
        <g clipPath="url(#clip1_6193_139622)">
          <path
            opacity="0.16"
            d="M42.7175 51.3975L19.5387 47.5125C14.3238 46.6375 10.5 42.1225 10.5 36.8288V15.2425L10.5262 7.8225C10.5262 6.88625 11.2875 6.125 12.2325 6.125H42.9975C43.9425 6.125 44.7038 6.88625 44.7038 7.83125V49.7175C44.7038 50.7675 43.7588 51.5725 42.7175 51.3975Z"
            fill="black"
          />
          <path
            d="M42.7175 49.6475L19.5387 45.7625C14.3238 44.8875 10.5 40.3725 10.5 35.0788V13.4925L10.5262 6.0725C10.5262 5.13625 11.2875 4.375 12.2325 4.375H42.9975C43.9425 4.375 44.7038 5.13625 44.7038 6.08125V47.9675C44.7038 49.0175 43.7588 49.8225 42.7175 49.6475Z"
            fill="url(#paint1_linear_6193_139622)"
          />
          <path
            d="M15.0938 12.2064C15.0938 11.8914 15.3475 11.6377 15.6625 11.6377H39.6025C39.9175 11.6377 40.1713 11.8914 40.1713 12.2064C40.1713 12.5214 39.9175 12.7752 39.6025 12.7752H15.6625C15.3475 12.7752 15.0938 12.5214 15.0938 12.2064ZM15.6625 17.0802H39.6025C39.9175 17.0802 40.1713 16.8264 40.1713 16.5114C40.1713 16.1964 39.9175 15.9427 39.6025 15.9427H15.6625C15.3475 15.9427 15.0938 16.1964 15.0938 16.5114C15.0938 16.8264 15.3475 17.0802 15.6625 17.0802ZM15.6625 21.3852H39.6025C39.9175 21.3852 40.1713 21.1314 40.1713 20.8164C40.1713 20.5014 39.9175 20.2477 39.6025 20.2477H15.6625C15.3475 20.2477 15.0938 20.5014 15.0938 20.8164C15.0938 21.1314 15.3475 21.3852 15.6625 21.3852ZM15.6625 25.6902H39.6025C39.9175 25.6902 40.1713 25.4364 40.1713 25.1214C40.1713 24.8064 39.9175 24.5527 39.6025 24.5527H15.6625C15.3475 24.5527 15.0938 24.8064 15.0938 25.1214C15.0938 25.4364 15.3475 25.6902 15.6625 25.6902ZM15.6625 29.9952H32.6025C32.9175 29.9952 33.1713 29.7414 33.1713 29.4264C33.1713 29.1114 32.9175 28.8577 32.6025 28.8577H15.6625C15.3475 28.8577 15.0938 29.1114 15.0938 29.4264C15.0938 29.7414 15.3475 29.9952 15.6625 29.9952Z"
            fill="#4D4D4D"
          />
          <path
            opacity="0.24"
            d="M43.4436 49.0962L25.6548 37.6687C24.7448 37.1175 23.5461 37.6337 23.3711 38.675C22.9598 41.0725 21.0698 44.9837 17.0273 44.9837L17.9723 45.3162C18.5761 45.5262 19.2061 45.6837 19.8448 45.7712L43.2511 49.6125C43.5573 49.665 43.7061 49.2537 43.4436 49.0962Z"
            fill="black"
          />
          <path
            d="M43.4436 49.0962L26.0486 38.5525C25.1299 38.0012 23.9399 38.5262 23.7561 39.585C23.3361 41.9825 22.2599 45.4387 18.2349 45.4387L19.6349 45.78C19.9149 45.85 20.1949 45.9025 20.4836 45.955L43.2599 49.63C43.5661 49.6825 43.7149 49.2712 43.4524 49.1137L43.4436 49.0962Z"
            fill="url(#paint2_linear_6193_139622)"
          />
        </g>
        <path
          opacity="0.16"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.25 46.375C19.25 51.208 15.335 55.125 10.5044 55.125C5.67377 55.125 1.75 51.2167 1.75 46.375C1.75 41.5333 5.66505 37.625 10.4956 37.625C15.3262 37.625 19.2413 41.542 19.2413 46.375H19.25Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.25 45.5C19.25 50.333 15.335 54.25 10.5044 54.25C5.67377 54.25 1.75 50.3417 1.75 45.5C1.75 40.6583 5.66505 36.75 10.4956 36.75C15.3262 36.75 19.2413 40.667 19.2413 45.5H19.25Z"
          fill="#9A8ADE"
        />
        <path
          d="M14.271 47.9066C14.2713 47.9056 14.2719 47.9047 14.2723 47.9038L14.2097 47.8803C14.2797 47.7053 14.411 47.5828 14.5685 47.5128C14.6472 47.4778 14.7435 47.4603 14.831 47.4603C14.9097 47.4603 14.9972 47.4691 15.076 47.5041C15.251 47.5741 15.3735 47.7053 15.4435 47.8628C15.4785 47.9416 15.496 48.0378 15.496 48.1253C15.496 48.2041 15.4872 48.2916 15.4522 48.3703C15.251 48.8603 14.9797 49.3153 14.656 49.7178C14.3235 50.1291 13.9385 50.4878 13.501 50.7766C13.0722 51.0653 12.5997 51.2928 12.0922 51.4503C11.6022 51.5991 11.086 51.6778 10.5522 51.6778C9.83471 51.6778 9.15221 51.5291 8.53096 51.2753C7.88346 51.0041 7.30596 50.6191 6.81596 50.1291C6.45721 49.7703 6.15971 49.3678 5.91471 48.9303C5.76295 48.6496 5.63093 48.3491 5.53007 48.0403L5.17096 48.5191C5.06596 48.6678 4.89971 48.7553 4.73346 48.7816H4.63721C4.49721 48.7816 4.35721 48.7378 4.23471 48.6503C4.09471 48.5453 4.00721 48.3791 3.98096 48.2128V48.1166C3.98096 47.9766 4.02471 47.8366 4.11221 47.7141L5.38971 46.0166C5.45096 45.9291 5.52971 45.8591 5.62596 45.8066H5.63471C5.73096 45.7628 5.83596 45.7366 5.94096 45.7366C6.01971 45.7366 6.09846 45.7541 6.16846 45.7803C6.23846 45.8066 6.29971 45.8503 6.36096 45.8941L8.06721 47.1803C8.21596 47.2853 8.30346 47.4516 8.32971 47.6178V47.7141C8.32971 47.8541 8.28596 47.9941 8.19846 48.1166L8.19424 48.113C8.08084 48.25 7.92516 48.3356 7.76096 48.3616H7.66471C7.52471 48.3616 7.38471 48.3178 7.26221 48.2303L6.94234 47.9891C6.98967 48.0963 7.04141 48.2011 7.09596 48.3003C7.27971 48.6241 7.50721 48.9303 7.76971 49.1928C8.12846 49.5603 8.56596 49.8491 9.04721 50.0503C9.51096 50.2428 10.0185 50.3478 10.5522 50.3478C10.9547 50.3478 11.3485 50.2953 11.7072 50.1816C12.0835 50.0678 12.4335 49.9016 12.7572 49.6828C13.0897 49.4641 13.3785 49.2016 13.6235 48.8953C13.8685 48.5891 14.0697 48.2566 14.2185 47.8891L14.2719 47.9091L14.271 47.9066C14.271 47.9066 14.271 47.9066 14.271 47.9066Z"
          fill="black"
          fillOpacity="0.16"
        />
        <path
          d="M14.1271 44.4553L13.8072 44.2141C13.6847 44.1266 13.5447 44.0828 13.4047 44.0828H13.3085C13.1422 44.1091 12.976 44.1966 12.871 44.3366C12.7835 44.4591 12.7397 44.5991 12.7397 44.7391V44.8353C12.766 45.0016 12.8535 45.1678 13.0022 45.2728L14.7085 46.5591C14.7697 46.6028 14.831 46.6466 14.901 46.6728C14.971 46.6991 15.0497 46.7166 15.1285 46.7166C15.2335 46.7166 15.3385 46.6903 15.4347 46.6466H15.4435C15.5397 46.5941 15.6185 46.5241 15.6797 46.4366L16.9572 44.7391C17.0447 44.6166 17.0885 44.4766 17.0885 44.3366V44.2403C17.0622 44.0741 16.9747 43.9078 16.826 43.8028C16.7035 43.7153 16.5635 43.6716 16.4235 43.6716H16.3272C16.1653 43.6971 16.0034 43.7808 15.8981 43.9225L15.8897 43.9166L15.5306 44.3954C15.4297 44.0866 15.2977 43.7861 15.146 43.5053C14.901 43.0678 14.6035 42.6653 14.2447 42.3066C13.7547 41.8166 13.1772 41.4316 12.5297 41.1603C11.9085 40.9066 11.226 40.7578 10.5085 40.7578C9.97471 40.7578 9.45846 40.8366 8.96846 40.9853C8.46096 41.1428 7.98846 41.3703 7.55971 41.6591C7.12221 41.9478 6.73721 42.3066 6.40471 42.7178C6.08096 43.1203 5.80971 43.5753 5.60846 44.0653C5.57346 44.1441 5.56471 44.2316 5.56471 44.3103C5.56471 44.3978 5.58221 44.4941 5.61721 44.5728C5.68721 44.7303 5.80971 44.8616 5.98471 44.9316C6.06346 44.9666 6.15096 44.9753 6.22971 44.9753C6.31721 44.9753 6.41346 44.9578 6.49221 44.9228C6.64971 44.8528 6.78096 44.7303 6.85096 44.5553C6.99971 44.1878 7.20096 43.8553 7.44596 43.5491C7.69096 43.2428 7.97971 42.9803 8.31221 42.7616C8.63596 42.5428 8.98596 42.3766 9.36221 42.2628C9.72096 42.1491 10.1147 42.0966 10.5172 42.0966C11.051 42.0966 11.5585 42.2016 12.0222 42.3941C12.5035 42.5953 12.941 42.8841 13.2997 43.2516C13.5622 43.5141 13.7897 43.8116 13.9735 44.1441C14.028 44.2432 14.0797 44.348 14.1271 44.4553Z"
          fill="black"
          fillOpacity="0.16"
        />
        <path
          d="M14.271 47.0316C14.2713 47.0306 14.2719 47.0297 14.2723 47.0288L14.2097 47.0053C14.2797 46.8303 14.411 46.7078 14.5685 46.6378C14.6472 46.6028 14.7435 46.5853 14.831 46.5853C14.9097 46.5853 14.9972 46.5941 15.076 46.6291C15.251 46.6991 15.3735 46.8303 15.4435 46.9878C15.4785 47.0666 15.496 47.1628 15.496 47.2503C15.496 47.3291 15.4872 47.4166 15.4522 47.4953C15.251 47.9853 14.9797 48.4403 14.656 48.8428C14.3235 49.2541 13.9385 49.6128 13.501 49.9016C13.0722 50.1903 12.5997 50.4178 12.0922 50.5753C11.6022 50.7241 11.086 50.8028 10.5522 50.8028C9.83471 50.8028 9.15221 50.6541 8.53096 50.4003C7.88346 50.1291 7.30596 49.7441 6.81596 49.2541C6.45721 48.8953 6.15971 48.4928 5.91471 48.0553C5.76295 47.7746 5.63093 47.4741 5.53007 47.1652L5.17096 47.6441C5.06596 47.7928 4.89971 47.8803 4.73346 47.9066H4.63721C4.49721 47.9066 4.35721 47.8628 4.23471 47.7753C4.09471 47.6703 4.00721 47.5041 3.98096 47.3378V47.2416C3.98096 47.1016 4.02471 46.9616 4.11221 46.8391L5.38971 45.1416C5.45096 45.0541 5.52971 44.9841 5.62596 44.9316H5.63471C5.73096 44.8878 5.83596 44.8616 5.94096 44.8616C6.01971 44.8616 6.09846 44.8791 6.16846 44.9053C6.23846 44.9316 6.29971 44.9753 6.36096 45.0191L8.06721 46.3053C8.21596 46.4103 8.30346 46.5766 8.32971 46.7428V46.8391C8.32971 46.9791 8.28596 47.1191 8.19846 47.2416L8.19424 47.238C8.08084 47.375 7.92516 47.4606 7.76096 47.4866H7.66471C7.52471 47.4866 7.38471 47.4428 7.26221 47.3553L6.94234 47.1141C6.98967 47.2213 7.04141 47.3261 7.09596 47.4253C7.27971 47.7491 7.50721 48.0553 7.76971 48.3178C8.12846 48.6853 8.56596 48.9741 9.04721 49.1753C9.51096 49.3678 10.0185 49.4728 10.5522 49.4728C10.9547 49.4728 11.3485 49.4203 11.7072 49.3066C12.0835 49.1928 12.4335 49.0266 12.7572 48.8078C13.0897 48.5891 13.3785 48.3266 13.6235 48.0203C13.8685 47.7141 14.0697 47.3816 14.2185 47.0141L14.2719 47.0341L14.271 47.0316C14.271 47.0316 14.271 47.0316 14.271 47.0316Z"
          fill="white"
        />
        <path
          d="M14.1271 43.5803L13.8072 43.3391C13.6847 43.2516 13.5447 43.2078 13.4047 43.2078H13.3085C13.1422 43.2341 12.976 43.3216 12.871 43.4616C12.7835 43.5841 12.7397 43.7241 12.7397 43.8641V43.9603C12.766 44.1266 12.8535 44.2928 13.0022 44.3978L14.7085 45.6841C14.7697 45.7278 14.831 45.7716 14.901 45.7978C14.971 45.8241 15.0497 45.8416 15.1285 45.8416C15.2335 45.8416 15.3385 45.8153 15.4347 45.7716H15.4435C15.5397 45.7191 15.6185 45.6491 15.6797 45.5616L16.9572 43.8641C17.0447 43.7416 17.0885 43.6016 17.0885 43.4616V43.3653C17.0622 43.1991 16.9747 43.0328 16.826 42.9278C16.7035 42.8403 16.5635 42.7966 16.4235 42.7966H16.3272C16.1653 42.8221 16.0034 42.9058 15.8981 43.0475L15.8897 43.0416L15.5306 43.5204C15.4297 43.2116 15.2977 42.9111 15.146 42.6303C14.901 42.1928 14.6035 41.7903 14.2447 41.4316C13.7547 40.9416 13.1772 40.5566 12.5297 40.2853C11.9085 40.0316 11.226 39.8828 10.5085 39.8828C9.97471 39.8828 9.45846 39.9616 8.96846 40.1103C8.46096 40.2678 7.98846 40.4953 7.55971 40.7841C7.12221 41.0728 6.73721 41.4316 6.40471 41.8428C6.08096 42.2453 5.80971 42.7003 5.60846 43.1903C5.57346 43.2691 5.56471 43.3566 5.56471 43.4353C5.56471 43.5228 5.58221 43.6191 5.61721 43.6978C5.68721 43.8553 5.80971 43.9866 5.98471 44.0566C6.06346 44.0916 6.15096 44.1003 6.22971 44.1003C6.31721 44.1003 6.41346 44.0828 6.49221 44.0478C6.64971 43.9778 6.78096 43.8553 6.85096 43.6803C6.99971 43.3128 7.20096 42.9803 7.44596 42.6741C7.69096 42.3678 7.97971 42.1053 8.31221 41.8866C8.63596 41.6678 8.98596 41.5016 9.36221 41.3878C9.72096 41.2741 10.1147 41.2216 10.5172 41.2216C11.051 41.2216 11.5585 41.3266 12.0222 41.5191C12.5035 41.7203 12.941 42.0091 13.2997 42.3766C13.5622 42.6391 13.7897 42.9366 13.9735 43.2691C14.028 43.3682 14.0797 43.473 14.1271 43.5803Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_6193_139622"
          x1="3.5"
          y1="28"
          x2="52.5"
          y2="28"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFE0E4" />
          <stop offset="1" stopColor="#EEEFF1" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_6193_139622"
          x1="10.5"
          y1="27.02"
          x2="44.7038"
          y2="27.02"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D3E1F6" />
          <stop offset="1" stopColor="#EFF4FC" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_6193_139622"
          x1="19.4686"
          y1="41.09"
          x2="43.2686"
          y2="49.0175"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D4E1FF" />
          <stop offset="1" stopColor="#4F93DB" />
        </linearGradient>
        <clipPath id="clip0_6193_139622">
          <rect width="56" height="56" fill="white" />
        </clipPath>
        <clipPath id="clip1_6193_139622">
          <rect width="34.2038" height="47.04" fill="white" transform="translate(10.5 4.375)" />
        </clipPath>
      </defs>
    </svg>
  )
);
